import { useState } from "react";
// icon
import { FaCheck } from "react-icons/fa6";

const NoticeCheckbox = ({
  label,
  name,
  disabled = false,
  checked,
  onChange,
}) => {
  return (
    <label
      className={`flex items-center cursor-pointer mb-2 relative transition-colors duration-300 ${
        disabled ? "cursor-not-allowed text-gray-gray" : ""
      } ${checked ? "text-main-red" : "text-gray-dGray"}`}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="hidden"
        disabled={disabled}
      />
      <span
        className={`w-5 h-5 inline-block mr-1 ${
          disabled
            ? "text-gray-dGray"
            : checked
            ? "text-main-red"
            : "text-gray-dGray"
        } relative transition-colors duration-300`}
      >
        <FaCheck className="w-3 h-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </span>
      {label}
    </label>
  );
};

export default NoticeCheckbox;
