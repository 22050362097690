// icons
import { LuPencilLine } from "react-icons/lu";
// Behance Modals

import HandleImageModal from "pages/BehanceEditor/components/Modals/HandleImageModal";
const ImageMediaCard = ({
  idx,
  data,
  setData,
  imageSrc,
  setCurFocusCreatedIdx,
  imageName,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    setCurFocusCreatedIdx(idx);
  };
  const imageContentDelete = () => {
    const newData = [...data];
    newData.splice(idx, 1);

    setData(newData);
  };

  const handleEditMedia = (content) => {
    const newData = [...data];
    newData.splice(idx, 1, { type: "image", contents: content });
    setData(newData);
  };
  return (
    <div className="relative group">
      <div
        onClick={(e) => handleClick(e)}
        className="focus:outline-main-pink group-hover:border h-fit group-hover:border-main-pink"
      >
        <img src={imageSrc} alt={imageName} className="h-full max-h-80" />
      </div>
      <HandleImageModal
        handleDelete={imageContentDelete}
        handleEditMedia={handleEditMedia}
      />
    </div>
  );
};

export default ImageMediaCard;
