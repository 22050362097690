// hooks
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRequestPost } from "hooks/useRequest";
// image
import dream_card from "assets/home/dream_card.png";
import loadingImg from "assets/images/loadingImg.gif";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
// common components
import CustomButton from "components/Buttons/CustomButton";
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
import RelativeTimeLoadingModal from "pages/BehanceEditor/components/Modals/RelativeTimeLoadingModal";

const ColorCard = ({ title, description, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const [isResultShow, setIsResultShow] = useState(false);
  const [result, setResult] = useState(false);
  const postDreamCreate = useRequestPost();
  const createPost = () => {
    const data = {
      templateCode: "C2101",
      firstVariable: inputValue,
    };
    if (inputValue) {
      // 1. 블로그인 경우 타이틀 먼저 생성 한 후, 콘텐츠 생성해야 함
      postDreamCreate.mutate({
        requestUrl: "/api/free-chat-completion/run",
        postData: data,
      });
    }
  };
  // post TemplateCreate Handler
  useEffect(() => {
    if (postDreamCreate.status === "success") {
      if (postDreamCreate?.data) {
        setIsResultShow(true);
        setResult(postDreamCreate?.data.data.value);
      } else {
        toast.error("요청은 하루에 한번 가능합니다.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  }, [postDreamCreate.status]);
  return (
    <>
      <div
        className="w-[49%] mr-2 min-h-full flex justify-center items-center bg-cover rounded-2xl absolute z-10"
        style={{ backgroundImage: `url(${dream_card})` }}
        onClick={() => setIsResultShow(!isResultShow)}
      >
        {postDreamCreate.isPending && <RelativeTimeLoadingModal second={15} />}
        <div className="w-full px-6 py-4 select-none">
          <div className="mb-3 text-text-white">
            <div className="mb-1 text-lg font-bold">{title}</div>
            <div className="mb-4 text-sm">{description}</div>
          </div>

          {/* input bar */}
          <div>
            <div className="relative">
              <input
                id="inputValue"
                type="text"
                maxLength={100}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={placeholder}
                className="bg-transparent border bg-white border-text-white text-xs pr-20 focus:font-semibold focus:outline-none px-2 py-[10px] w-full rounded-lg placeholder-text-gray "
              />
              <button
                onClick={() => createPost()}
                type="button"
                className={
                  "bg-text-black text-white font-bold py-2 px-3 top-[3px] rounded-lg absolute right-[3px] text-xs"
                }
              >
                결과보기
              </button>
            </div>
          </div>
          {/* result */}
          <div>
            {result && isResultShow && (
              <div className="p-4 mt-3 text-xs rounded-md bg-text-white">
                <div className="mb-2 text-sm font-bold">해몽결과 🌠</div>
                <div className="whitespace-pre-wrap">{result}</div>
                <div className="text-center text-[10px] mt-2">
                  <div className="inline m-2 text-main-pink">
                    이미지카드와 함께 기록하고 싶다면?
                  </div>
                  <Link to="/templates">
                    <CustomButton
                      content="꿈 일기 쓰러가기"
                      customStyle={
                        "bg-main-pink font-bold text-text-white px-4 py-2 m-2 rounded-md bg-gradient-to-r from-[#9218D4] to-[#D22585]"
                      }
                    />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorCard;
