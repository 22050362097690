import React from "react";

const TabList = ({ itemList, selectValue, setSelectValue, onItemClick }) => {
  const handleClick = (el) => {
    setSelectValue(el.label);
    if (onItemClick) {
      onItemClick(el); // 클릭 콜백 호출
    }
  };

  return (
    <div className="border p-1 border-main-pink rounded-lg z-40 bg-white">
      {itemList.map((el, idx) => (
        <div
          key={idx}
          onClick={() => handleClick(el)}
          className="group flex items-center justify-between py-2 px-3 rounded-lg cursor-pointer my-1 
            bg-white text-gray-600 hover:bg-[#F1F3F5] text-xs"
        >
          <span>{el.label}</span>
          <span>{el.icon}</span>
        </div>
      ))}
    </div>
  );
};

export default TabList;
