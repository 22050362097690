import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SettingInput from "components/Inputs/SettingInput";
import CategoryDropdown from "../components/CategoryDropdown";
import SettingTextarea from "pages/Setting/components/SettingTextarea";
import PrimaryBtn from "components/Buttons/PrimaryBtn";
import DefaultBtnSm from "components/Buttons/DefaultBtn";
import {
  useRequestPost,
  useRequestGet,
  useRequestPatch,
} from "hooks/useRequest";
import { toast } from "react-toastify"; // Toastify import
function FeedBackPost() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  const postMutation = useRequestPost();
  const patchMutation = useRequestPatch();
  const feedbackData = useRequestGet(
    id ? `/api/feedback/${id}` : null,
    id ? `feedback-${id}` : null
  );
  const itemList = [
    { label: "서비스제안", value: "SERVICE_SUGGESTION" },
    { label: "오류접수", value: "BUG_REPORT" },
    { label: "자유의견", value: "FREE_OPINION" },
  ];
  useEffect(() => {
    if (id && feedbackData?.status === "success" && feedbackData?.data) {
      setIsEditMode(true);
      const { feedbackType, feedbackTitle, feedbackHtml } =
        feedbackData.data.data;
      setCategory(feedbackType);
      setTitle(feedbackTitle);
      setContent(feedbackHtml);
    }
  }, [id, feedbackData?.status, feedbackData?.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!category) {
      toast.error("카테고리를 선택해주세요");
      return;
    }
    if (!title) {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (!content) {
      toast.error("내용을 입력해주세요.");
      return;
    }
    const data = {
      feedbackType: category,
      feedbackTitle: title,
      feedbackHtml: content,
      s3Urls: [],
    };

    try {
      if (isEditMode) {
        await patchMutation.mutate({
          requestUrl: `/api/feedback/${id}`,
          patchData: data,
        });
      } else {
        await postMutation.mutate({
          requestUrl: "/api/feedback",
          postData: data,
        });
      }
    } catch (error) {
      console.error("Error submitting feedback form:", error);
    }
  };

  useEffect(() => {
    if (
      postMutation.status === "success" ||
      patchMutation.status === "success"
    ) {
      navigate("/feedback", {
        state: { newFeedbackAdded: true },
      });
    } else if (
      postMutation.status === "pending" ||
      patchMutation.status === "pending"
    ) {
      console.log("게시글 작성 중입니다.");
    }
  }, [patchMutation.status, navigate, postMutation.status]);

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper ">
        <div className="title">
          <h3 className="text-lg font-semibold">문의사항</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex mt-10 gap-10 w-full">
            <div className="w-[334px]">
              <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
                카테고리
              </p>
              <CategoryDropdown
                category={category}
                setCategory={setCategory}
                background={"#fff"}
                itemList={itemList}
              />
            </div>
            <div className="w-96">
              <div className="flex justify-between">
                <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
                  제목
                </p>
              </div>
              <SettingInput
                type={"text"}
                value={title}
                setValue={setTitle}
                placeholder={""}
              />
            </div>
          </div>
          <div className="mt-10">
            <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
              내용
            </p>
            <div>
              <SettingTextarea
                setValue={setContent}
                value={content}
                rows="10"
                type="default"
              />
            </div>
          </div>

          <div className="flex gap-2 mt-5 justify-center">
            <PrimaryBtn
              type="button"
              content="작성취소"
              btnClickFunc={() => navigate(-1)}
            />
            <DefaultBtnSm
              type="submit"
              content="작성 완료"
              btnClickFunc={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedBackPost;
