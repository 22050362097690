import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import ByteBadge from "components/Badge/ByteBadge";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useState } from "react";

const PlanCard = ({
  price,
  byte,
  checkList,
  title,
  btnTitle,
  customerKey,
  username,
  email,
  eng,
  userCurrentPlan,
  createdAt,
}) => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // 현재 날짜와 createdAt 날짜의 차이를 계산
    const createdDate = new Date(createdAt);
    const currentDate = new Date();

    const diffTime = Math.abs(currentDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 차이를 일 단위로 계산

    // 30일 이내이면 비활성화 처리
    if (diffDays <= 30) {
      setIsDisabled(true);
    }
  }, [createdAt]);

  const isCurrentPlan = ["NANO", "BASIC", "CLASSIC", "PRIME"].includes(
    userCurrentPlan
  );

  const handlePayment = () => {
    navigate("/plan/payment", {
      state: {
        price,
        title,
        customerKey,
        username,
        checkList,
        byte,
        btnTitle,
        email,
        eng,
      },
    });
  };

  return (
    <div className="bg-text-white py-10 px-6 w-[275px] rounded-2xl flex flex-col gap-3 border border-text-white hover:border-main-red group">
      <div className="flex flex-col justify-center items-center gap-2">
        <div className="text-text-black text-center text-lg">{title}</div>
        <div className="flex items-end text-main-red text-center text-3xl font-semibold">
          {Number(price).toLocaleString("ko-KR")}원
          <p className="text-base text-text-gray font-normal">/월</p>
        </div>
        <ByteBadge byte={byte} />
      </div>

      <ul>
        {checkList.map((list, idx) => (
          <li key={idx} className="flex text-sm items-center mt-4">
            <FaCheck className="text-main-red w-3 h-3 mr-1" />
            {list}
          </li>
        ))}
      </ul>

      {/* 결제 버튼 */}
      <CustomButton
        content={btnTitle}
        customStyle={`border ${
          isDisabled || isCurrentPlan
            ? "bg-gray-sGray border-gray-gray text-gray-gray cursor-not-allowed"
            : "text-text-black bg-text-white border-gray-lGray group-hover:bg-main-red group-hover:text-text-white group-hover:border-main-red"
        }  py-2 px-2 rounded-lg text-sm w-full mt-24`}
        func={handlePayment}
        disabled={isDisabled || isCurrentPlan} // 버튼 비활성화 여부 결정
      />
    </div>
  );
};

export default PlanCard;
