// hooks
import { useState, useEffect } from "react";
import { useRequestGet, useRequestPost } from "hooks/useRequest";
import { useNavigate, useSearchParams } from "react-router-dom";
// commons
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import KeywordTagInput from "components/Inputs/KeywordTagInput";
import TextArea from "components/TextArea";
import AITypeDropdown from "components/Dropdowns/AITypeDropdown";
import TimeLoadingModal from "pages/BehanceEditor/components/Modals/TimeLoadingModal";
import ShortageByteModal from "components/Modals/ShortageByteModal";

// utils
import {
  objectiveCode,
  templateCode,
  toneCode,
  languageCode,
} from "utils/TemplateQuestions";
// icons
import { FaSquarePlus, FaSquareMinus } from "react-icons/fa6";
//images
import plum_byte_icon_logo from "assets/logo/plum_byte_icon_logo.png";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
import loadingImg from "assets/images/loadingImg.gif";

const ImageStudio = ({ templateInfo }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const getUserInfo = useRequestGet("/api/user", "userInfo");

  const getBrand = useRequestGet("/api/brand", "brand"); //getBrand.data.data.data
  const getTarget = useRequestGet("/api/target", "target"); //getBrand.data.data.data
  const AiTypeList = [
    { type: "gpt", title: "GPT", describe: "창의적인 텍스트 생성" },
    { type: "claude", title: "Claude", describe: "자연스러운 텍스트 생성" },
  ];
  const [isShortageModal, setIsShortageModal] = useState(false);
  const [aiType, setAiType] = useState(AiTypeList[1].type);
  const [inputDatas, setInputDatas] = useState([]);

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [target, setTarget] = useState("");
  const [objective, setObjective] = useState("");
  const [tone, setTone] = useState("");
  const [language, setLanguage] = useState("KR"); //언어 선택은 필수로 넣어야 하더라

  const [firstVariable, setFirstVariable] = useState("");
  const [secondVariable, setSecondVariable] = useState([]);
  const [thirdVariable, setThirdVariable] = useState("");
  const [fourthVariable, setFourthVariable] = useState("");
  const [fifthVariable, setFifthVariable] = useState("");

  const [imageRequestDescription, setImageRequestDescription] = useState("");
  // 추가설정
  const [addSetting, setAddSeting] = useState(false);
  // 필수 입력 progress
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const values = [imageRequestDescription];
    const trueCount = values.filter(Boolean).length;
    const result = trueCount * 100;
    setProgress(result);
  }, [imageRequestDescription]);

  // 요청보내기
  const postContentCreate = useRequestPost();
  const postImageCreate = useRequestPost();

  const handleSubmit = () => {
    const needPlum = 2;
    if (needPlum > getUserInfo?.data?.data?.remainingPlum) {
      setIsShortageModal(true);
      return;
    }
    if (progress === 100) {
      const data = {
        model: aiType,
        templateCode: templateCode.editor[0].template_code,

        ...(brand && { brandSeq: brand }),
        ...(target && { targetSeq: target }),
        ...(tone && { toneCode: tone }),
        ...(language && { countryCode: language }),
        ...(objective && { objectiveCode: objective }),

        ...(firstVariable && { firstVariable: firstVariable }),
        ...(secondVariable && { secondVariable: secondVariable?.join(",") }),
        ...(thirdVariable && { thirdVariable: thirdVariable }),
        ...(fourthVariable && { fourthVariable: fourthVariable }),
        ...(fifthVariable && { fifthVariable: fifthVariable }),
      };
      setInputDatas(data);

      postContentCreate.mutate({
        // 콘텐츠 항목 생성 요청
        requestUrl: `/api/project/${projectSeq}/content`,
        postData: data,
      });
    }
  };

  // 이미지 요청
  useEffect(() => {
    if (postContentCreate.status === "success") {
      if (imageRequestDescription) {
        const { seq } = postContentCreate?.data?.data;
        const data = {
          templateCode: "C03",
          prompt: imageRequestDescription,
          imageRatio: "16:9",
        };

        postImageCreate.mutate({
          requestUrl: `/api/project/${projectSeq}/content/${seq}/images/create/v2`,
          postData: data,
        });
      } else return;
    }
  }, [postContentCreate.status]);

  // 요청 완료 컨트롤러
  useEffect(() => {
    if (imageRequestDescription) {
      if (
        postContentCreate.status === "success" &&
        postImageCreate.status === "success"
      ) {
        const { seq } = postContentCreate?.data?.data;
        // 이미지 생성하기도 필요
        const { imageS3Url } = postImageCreate?.data?.data;

        navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
          state: {
            post: "",
            contentSeq: seq,
            projectSeq: projectSeq,
            inputDatas: inputDatas,
            imageData: imageS3Url,
          },
        });
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postContentCreate.status, postImageCreate.status]);
  return (
    <div className="mt-10">
      {(postContentCreate.isPending || postImageCreate.isPending) && (
        <TimeLoadingModal second={30} />
      )}
      <ShortageByteModal
        remainingPlum={getUserInfo?.data?.data?.remainingPlum}
        isShow={isShortageModal}
        setIsShow={setIsShortageModal}
      />
      <div>
        <AITypeDropdown
          selectValue={aiType}
          setSelectValue={setAiType}
          itemList={AiTypeList}
        />
      </div>
      <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div>
      {/* 기본 input*/}
      <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
        <div>콘텐츠의 정확성 일치율</div>
        <div className="ml-2 font-bold text-main-pink">{progress}%</div>
      </div>
      <div className="mt-2 rounded-full bg-gray-lGray">
        <div
          className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
          style={{ width: `${progress}%`, transition: "width 0.1s linear" }}
        ></div>
      </div>
      {/* 변수 input*/}
      <div className="pt-8">
        <div className="flex justify-between font-semibold">
          <div className="font-semibold">
            <span className="m-1 text-main-pink"> *</span>
            원하는 이미지
          </div>
        </div>
        <TextArea
          id="text"
          type="white"
          placeholder="테이블 위에 놓여진 비빔밥 한 그릇"
          value={imageRequestDescription}
          setValue={setImageRequestDescription}
          rows={6}
        />
      </div>
      {/* 추가 설정 */}

      <div className="flex justify-center w-full mt-8 text-sm">
        <button
          onClick={handleSubmit}
          className={
            progress === 100
              ? "m-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]"
              : "m-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray"
          }
        >
          (-2Byte) 생성하기
        </button>
      </div>
    </div>
  );
};
export default ImageStudio;
