import React, { useEffect, useState, useRef, useCallback } from "react";
import { PiTextTBold } from "react-icons/pi";
import {
  FaBold,
  FaUnderline,
  FaItalic,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaList,
  FaListOl,
} from "react-icons/fa6";
import { SketchPicker } from "react-color";
const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

const PopoverMenu = ({
  setContentsDragoverAble,
  lastFocusedIdx,
  contents,
  setContents,
}) => {
  const popoverMenuRef = useRef(null);
  const [selectionConfig, setSelectionConfig] = useState({
    popOverMenu: null,
    pos: { x: 0, y: 0 },
    event: null,
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedTextColor, setSelectedTextColor] = useState("#000000");
  const [selectedBgColor, setSelectedBgColor] = useState("#ffffff");
  const [selectedColorFunc, setSelectedColorFunc] = useState(null);
  const [textColorStyle, setTextColorStyle] = useState("text-black");
  const [bgColorStyle, setBgColorStyle] = useState("text-black");
  const [savedRange, setSavedRange] = useState(null);

  const [fontSize, setFontSize] = useState("16px"); // 초기 글꼴 크기

  useEffect(() => {
    if (popoverMenuRef.current) {
      setSelectionConfig((prev) => ({
        ...prev,
        popOverMenu: popoverMenuRef.current,
      }));
    }
  }, []);

  useEffect(() => {
    const handleSelectionChange = debounce(onSelectionChange);
    document.addEventListener("selectionchange", handleSelectionChange);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [selectionConfig]);

  const onSelectionChange = () => {
    if (!selectionConfig.popOverMenu) return;
    const selection = document.getSelection();
    setSelectionConfig((prev) => ({
      ...prev,
      event: selection,
    }));
  };

  useEffect(() => {
    setContentsDragoverAble(false);

    if (!selectionConfig.popOverMenu) return;
    const selection = document.getSelection();
    const { type, rangeCount } = selection;

    if ((type !== "Range" || rangeCount === 0) && !showColorPicker) {
      setContentsDragoverAble(true);
      selectionConfig.popOverMenu.style.display = "none";
      return;
    }
    selectionConfig.popOverMenu.style.display = "block";
    selectionConfig.popOverMenu.style.left = `${selectionConfig.pos.x - 70}px`;
    selectionConfig.popOverMenu.style.top = `${selectionConfig.pos.y - 70}px`;
  }, [selectionConfig, showColorPicker]);

  const handleMouseUp = useCallback((e) => {
    if (popoverMenuRef.current && popoverMenuRef.current.contains(e.target)) {
      return;
    }
    setSelectionConfig((prev) => ({
      ...prev,
      pos: { x: e.clientX, y: e.clientY },
    }));
  }, []);

  const setHtmlStyle = (func, range, value) => {
    if (!range) return;

    const fragment = range.cloneContents();
    let newNode;

    switch (func) {
      case "bold":
      case "italic":
      case "underline":
        document.execCommand(func);
        break;
      case "left":
      case "center":
      case "right":
      case "justify":
        document.execCommand("justify" + func);
        break;
      case "list":
        newNode = document.createElement("ul");
        newNode.classList.add("list-disc", "pl-5");
        newNode.innerHTML = `<li>${fragment.textContent}</li>`;
        range.deleteContents();
        range.insertNode(newNode);
        range.setEndAfter(newNode);
        break;
      case "listOl":
        newNode = document.createElement("ol");
        newNode.classList.add("list-decimal", "pl-5");
        const orderedListItems = fragment.textContent
          .split("\n")
          .map((item) => `<li>${item.trim()}</li>`)
          .join("");
        newNode.innerHTML = orderedListItems;
        range.deleteContents();
        range.insertNode(newNode);
        range.setEndAfter(newNode);
        break;
      case "textColor":
      case "bgColor":
        newNode = document.createElement("span");
        newNode.style.color = func === "textColor" ? value : null;
        newNode.style.backgroundColor = func === "bgColor" ? value : null;

        const spans = fragment.querySelectorAll("span");
        spans.forEach((span) => {
          if (func === "textColor") {
            span.style.color = "";
          } else if (func === "bgColor") {
            span.style.backgroundColor = "";
          }
        });

        newNode.appendChild(fragment);
        range.deleteContents();
        range.insertNode(newNode);
        range.setEndAfter(newNode);
        break;
      case "fontSize":
        newNode = document.createElement("span");
        newNode.style.fontSize = value;

        const fontSizeSpans = fragment.querySelectorAll("span");
        fontSizeSpans.forEach((span) => {
          span.style.fontSize = "";
        });

        newNode.appendChild(fragment);
        range.deleteContents();
        range.insertNode(newNode);
        range.setEndAfter(newNode);
        break;
      default:
        return;
    }
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const setElStyle = (e) => {
    const button = e.target.closest("button");
    if (!button) return;
    const { func } = button.dataset;
    const selection = document.getSelection();

    if (func === "textColor" || func === "bgColor") {
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        setSavedRange(range);
        setShowColorPicker(true);
        setSelectedColorFunc(func);
      }
    } else {
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        setSavedRange(range);
        setHtmlStyle(func, range, fontSize);
      }
    }
  };

  const handleColorChange = (color) => {
    const func = selectedColorFunc;
    if (func === "textColor") {
      setSelectedTextColor(color.hex);
    } else if (func === "bgColor") {
      setSelectedBgColor(color.hex);
    }

    const range = savedRange;
    if (range) {
      setHtmlStyle(func, range, color.hex);
    }
    setShowColorPicker(false);
  };

  const handleFontSizeChange = (e) => {
    e.stopPropagation();
    const newSize = e.target.value;
    setFontSize(newSize);

    const selection = document.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSavedRange(range);
      setHtmlStyle("fontSize", range, newSize);
    }
  };

  const isLightColor = (color) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
  };
  useEffect(() => {
    setTextColorStyle(
      isLightColor(selectedTextColor) ? "text-black" : "text-white"
    );
  }, [selectedTextColor]);

  useEffect(() => {
    setBgColorStyle(
      isLightColor(selectedBgColor) ? "text-black" : "text-white"
    );
  }, [selectedBgColor]);
  return (
    <div>
      {showColorPicker && (
        <div className="fixed z-40">
          <div
            className="fixed top-0 left-0 z-0 w-full h-full"
            onClick={() => setShowColorPicker(false)}
          ></div>
          <div className="absolute">
            <SketchPicker
              color={
                selectedColorFunc === "textColor"
                  ? selectedTextColor
                  : selectedBgColor
              }
              onChange={handleColorChange}
              onClose={() => setShowColorPicker(false)}
            />
          </div>
        </div>
      )}
      <div
        className="fixed z-30 bg-text-white border pl-2 pr-3 py-1 rounded-xl border-main-pink shadow-md shadow-[#eb228330]"
        ref={popoverMenuRef}
      >
        <div className="flex items-center">
          <span className="mr-3">
            <select
              value={fontSize}
              onChange={handleFontSizeChange}
              onClick={(e) => e.stopPropagation()}
              className="p-2 my-[2px] rounded-lg bg-gray-sGray"
            >
              {[
                "8px",
                "9px",
                "10px",
                "11px",
                "12px",
                "13px",
                "14px",
                "15px",
                "16px",
                "17px",
                "18px",
                "20px",
                "22px",
                "24px",
                "26px",
                "27px",
                "28px",
                "30px",
                "32px",
                "34px",
                "36px",
                "38px",
                "40px",
                "44px",
              ].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </span>
          <button
            className={`mx-1 border rounded-full w-7 h-7 border-text-lGray ${textColorStyle} hover:border-main-pink`}
            style={{ background: selectedTextColor }}
            type="button"
            data-func="textColor"
            onClick={setElStyle}
          >
            <PiTextTBold className="w-5 h-5 m-auto" />
          </button>
          <button
            className={`mx-1 border w-7 h-7 border-text-lGray ${bgColorStyle} hover:border-main-pink`}
            style={{ background: selectedBgColor }}
            type="button"
            data-func="bgColor"
            onClick={setElStyle}
          >
            <PiTextTBold className="w-5 h-5 m-auto" />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="bold"
            onClick={setElStyle}
          >
            <FaBold className="w-5 h-5" />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="italic"
            onClick={setElStyle}
          >
            <FaItalic className="w-5 h-5 " />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="underline"
            onClick={setElStyle}
          >
            <FaUnderline className="w-5 h-5 mt-[2px]" />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="justify"
            onClick={setElStyle}
          >
            <FaAlignJustify className="w-5 h-5 " />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="left"
            onClick={setElStyle}
          >
            <FaAlignLeft className="w-5 h-5 " />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="center"
            onClick={setElStyle}
          >
            <FaAlignCenter className="w-5 h-5 " />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="right"
            onClick={setElStyle}
          >
            <FaAlignRight className="w-5 h-5" />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="list"
            onClick={setElStyle}
          >
            <FaList className="w-5 h-5" />
          </button>
          <button
            className="mx-2 text-text-lGray hover:text-main-pink"
            type="button"
            data-func="listOl"
            onClick={setElStyle}
          >
            <FaListOl className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopoverMenu;
