// hooks
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useRequestPost } from 'hooks/useAuth';
// toast
import { toast } from 'react-toastify'; // Toastify import
import 'react-toastify/dist/ReactToastify.css'; // Toastify 스타일 import

// images
import login_main from 'assets/login/login_main.webp';
import login_typo from 'assets/login/login_typo.png';
// icons
import { FaArrowLeft } from 'react-icons/fa';
// Mateiral UI

const LoginEmail = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        id: '이메일',
        password: '패스워드',
    });

    // input handler
    const handleChange = (e) => {
        const { id, value } = e.target;
        setValues((prevValues) => ({
            ...prevValues,
            [id]: value,
        }));
    };

    // POST 요청
    const postLogin = useRequestPost();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!values.id) {
            toast.error('id를 입력하세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (!values.password) {
            toast.error('pw를 입력하세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            postLogin.mutate({
                requestUrl: '/api/login',
                postData: {
                    userId: values.id,
                    password: values.password,
                },
            });
        }
    };
    // POST handler
    useEffect(() => {
        if (postLogin.status === 'success') {
            localStorage.setItem('TOKEN', postLogin.data.headers.authorization);
            window.setTimeout(() => {
                navigate('/', { replace: true });
                // window.location.reload();
            }, 200); // 새로고침 전에 약간의 지연을 추가
            // },
            // });
        } else if (postLogin.status === 'error') {
            toast.error('id, password 를 확인해주세요.', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postLogin.status]);

    return (
        <div className="mt-4">
            <form
                onChange={handleChange}
                onSubmit={handleSubmit}
            >
                <input
                    id="id"
                    type="id"
                    maxLength={100}
                    placeholder="이메일"
                    className="w-full p-2 mb-4 placeholder-black rounded-lg inputTextMinSize bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red"
                />

                <input
                    id="password"
                    type="password"
                    maxLength={30}
                    placeholder="비밀번호"
                    className="w-full p-2 mb-2 placeholder-black rounded-md inputTextMinSize bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red"
                />
                {/* <div className="flex justify-end mb-4 text-xs">
          <div className="mr-2 text-gray-dGray">비밀번호를 잃어버리셨나요?</div>
          <Link to="/signup" className="font-bold text-gray-dGray">
            비밀번호 찾기
          </Link>
        </div> */}
                <div className="flex justify-end">
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="w-full p-2 rounded-md bg-back-dGray hover:bg-main-pink text-text-white"
                    >
                        로그인
                    </button>
                </div>
            </form>
        </div>
    );
};
export default LoginEmail;
