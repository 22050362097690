// Common Components
import AIModal from 'pages/BehanceEditor/components/Modals/AIModal';

// hooks
import { useEffect, useState, useRef } from 'react';
// icons
import { LuImage } from 'react-icons/lu';

const RecommandMediaCard = ({
    idx,
    // data
    // setData=
    contents,
    handleReplaceMedia,
    AIModalTab,
    setAIModalTab,
}) => {
    const [isAIModal, setIsAIModal] = useState(false);
    const [AIModalTabState, setAIModalTabState] = useState(AIModalTab);
    const handleButtonClick = (e) => {
        e.stopPropagation();
        setAIModalTab('AIImage');
        setIsAIModal(true);
    };
    // console.log('AIModalTab', AIModalTab);
    return (
        <div>
            <div
                className="text-sm group w-fit"
                onClick={handleButtonClick}
            >
                <button className="flex items-center px-3 py-2 border rounded-lg border-gray-gray text-gray-gray group group-hover:border-main-red">
                    <LuImage className="mr-1" />
                    <div>이런 이미지 어때요?</div>
                    <div className="ml-2 text-text-black group-hover:text-main-red">{contents}</div>
                </button>
            </div>
            {isAIModal && (
                <AIModal
                    isShow={isAIModal}
                    setIsShow={setIsAIModal}
                    insertionIdx={idx}
                    AIModalTab={AIModalTabState}
                    setAIModalTab={setAIModalTabState}
                    handleCreateMedia={handleReplaceMedia} // 여기 create가 아니라 replace로 넣었음
                    deafultAIModalInput={contents}
                />
            )}
        </div>
    );
};

export default RecommandMediaCard;
