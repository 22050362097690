import { LuPencilLine } from "react-icons/lu";
import { AiOutlineMore } from "react-icons/ai";
import React, { useState, useEffect, useRef } from "react";
import TabList from "components/Tap/TapList";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/Modals/DeleteModal";
import { dateMatcher } from "utils/DateMatcher";

const ContentsCard = ({
  title,
  date,
  content,
  btnTit,
  itemList,
  id,
  type,
  age,
  gender,
  interest,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (el) => {
    setValue(el.label);
    setIsOpen(false);
    if (el.onClick) {
      el.onClick(id); // 클릭된 항목의 onClick 콜백 호출
    }
  };

  const handleEdit = () => {
    if (type === "target") {
      navigate(`/setting/target/${id}`);
    } else if (type === "brand") {
      navigate(`/setting/brand/${id}`);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteModalOpen(false);
    if (deleteItemId !== null) {
      onDelete(deleteItemId);
      setDeleteItemId(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setDeleteItemId(null);
  };

  // 삭제 메시지를 type에 따라 다르게 설정
  const deleteMessage =
    type === "brand"
      ? "브랜드를 삭제하시겠습니까?"
      : "타겟을 삭제하시겠습니까?";

  const updatedItemList = itemList.map((item) => ({
    ...item,
    onClick:
      item.label === "브랜드 삭제" || item.label === "타겟 삭제"
        ? () => handleDeleteClick(id) // 삭제 버튼 클릭 시 삭제 모달 띄우기
        : item.onClick,
  }));

  return (
    <div className="p-[25px] border font-bold border-gray-lGray rounded-2xl w-[215px] h-[215px] bg-white relative">
      <div className="flex justify-between">
        <p className="items-end mt-2 text-xs font-normal text-gray-lGray">
          {date && dateMatcher(date)}
        </p>
        <div className="justify-end">
          <AiOutlineMore
            onClick={toggleDropdown}
            className={
              isOpen
                ? `w-6 h-6 rounded-md cursor-pointer text-text-white bg-main-red`
                : `w-6 h-6 rounded-md cursor-pointer text-main-red hover:bg-gray-lGray`
            }
          />
        </div>
      </div>
      <div className="mt-3">
        <div className="flex">
          <p className="flex-grow overflow-hidden text-base font-semibold text-ellipsis w-fit line-clamp-1">
            {title.length > 8 ? `${title.substring(0, 8)}...` : title}
          </p>
          <button className="ml-1.5" onClick={handleEdit}>
            <LuPencilLine className="w-6 h-6 text-main-red" />
          </button>
        </div>
        {content && (
          <p className="flex-grow h-10 mt-5 overflow-hidden text-sm font-normal text-gray-gray text-ellipsis whitespace-wrap max-w-40 line-clamp-2">
            {content}
          </p>
        )}
        {age && gender && interest && (
          <p className="flex-grow h-10 mt-5 overflow-hidden text-sm font-normal text-gray-gray text-ellipsis whitespace-wrap max-w-40 line-clamp-2">
            {age} / {gender} / {interest}
          </p>
        )}
      </div>
      {btnTit && (
        <button
          className="w-full h-8 mt-3 text-sm font-normal border rounded-md border-gray-gray text-gray-dGray"
          onClick={handleEdit}
        >
          {btnTit}
        </button>
      )}
      {isOpen && (
        <div className="absolute z-10 top-6 right-[-95px]" ref={menuRef}>
          <TabList
            selectValue={value}
            setSelectValue={setValue}
            itemList={updatedItemList}
            onItemClick={handleItemClick}
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message={deleteMessage}
        />
      )}
    </div>
  );
};

export default ContentsCard;
