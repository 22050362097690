// hooks
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useReqDelete, useRequestPost } from 'hooks/useRequest';
// icons
import { AiOutlineMore } from 'react-icons/ai';
import { CiImageOn } from 'react-icons/ci';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { LuImage } from 'react-icons/lu';
// project components
import DeleteConfirmModal from 'pages/Projects/DeleteConfirmModal';
const ContentHandleModal = ({ seq, refetchFunc }) => {
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');
    const [isShow, setShow] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [previewImage, setPreviewImage] = useState([]);
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    const handleShow = () => {
        setShow(!isShow);
    };

    const deleteContent = useReqDelete();
    const handleDeleteContent = () => {
        if (seq && projectSeq && deleteContent) {
            deleteContent.mutate({
                requestUrl: `/api/project/${projectSeq}/content/${seq}`,
            });
        }
    };
    // Delete State Handler
    useEffect(() => {
        if (deleteContent.status === 'success') {
            refetchFunc();
            setShow(false);
            setIsConfirmModal(false);
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteContent.status]);

    // Post Image Handler
    // const postImage = useReqDelete();
    // const handlePostImage = () => {
    //     if (seq && imageFile) {
    //         postImage.mutate({
    //             requestUrl: `/api/project/${projectSeq}/content/${seq}/images`,
    //             postData: imageFile,
    //         });
    //     }
    // };
    // Image Upload Handler
    // const imageUploader = (e) => {
    //     let fileArr = e.target.files;
    //     setImageFile(Array.from(fileArr));

    //     let fileRead = new FileReader();
    //     fileRead.onload = function () {
    //         setPreviewImage(fileRead.result);
    //         console.log(fileRead.result);
    //     };

    //     fileRead.readAsDataURL(fileArr[0]);
    // };
    return (
        <>
            <div className="relative">
                <button
                    onClick={handleShow}
                    className=""
                >
                    <AiOutlineMore
                        className={
                            isShow
                                ? `w-5 h-5 text-text-white bg-main-red border-2 border-main-red rounded-md`
                                : `w-5 h-5 text-main-red bg-white  hover:bg-gray-lGray rounded-md cursor-pointer`
                        }
                    />
                </button>
                {isShow && (
                    <>
                        <div
                            className="absolute top-[-5px] left-6 border-2 p-1 bg-text-white rounded-lg border-main-pink flex justify-center items-center z-30"
                            onClick={handleShow}
                        >
                            <div
                                className="w-32 text-sm rounded-lg font-base"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {/* <button
                                    type="button"
                                    className="w-full rounded-md hover:bg-gray-sGray"
                                >
                                    <label
                                        htmlFor="photo"
                                        className="flex items-center justify-between p-2"
                                    >
                                        <div>이미지 등록</div>
                                        <LuImage className="text-main-pink " />
                                    </label>
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept=".png, .jpeg, .jpg"
                                        className="hidden"
                                        onChange={(e) => imageUploader(e)}
                                    />
                                </button> */}

                                <button
                                    onClick={() => setIsConfirmModal(true)}
                                    className="flex items-center justify-between w-full p-2 rounded-md hover:bg-gray-sGray"
                                >
                                    <div>콘텐츠 삭제</div>
                                    <RiDeleteBin6Line className="text-main-pink" />
                                </button>
                            </div>
                        </div>
                        {imageFile && (
                            <img
                                src={previewImage}
                                alt={'previewImage'}
                            />
                        )}

                        <div
                            className="fixed top-0 left-0 z-20 w-full h-full"
                            onClick={() => setShow(false)}
                        ></div>
                    </>
                )}
            </div>
            {isConfirmModal && (
                <DeleteConfirmModal
                    tab={'content'}
                    setIsConfirmModal={setIsConfirmModal}
                    deleteFunc={handleDeleteContent}
                />
            )}
        </>
    );
};
export default ContentHandleModal;
