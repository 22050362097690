// hooks
import { useState } from "react";
// icons
import { LuPencilLine } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
// Modals
import ReWriteModal from "pages/BehanceEditor/components/Modals/ReWriteModal";

const HandleTextModal = ({
  textContent,
  textContentReWrite,
  textContentDelete,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isReWriteModal, setIsReWriteModal] = useState(false);

  const [hoverState, setHoverState] = useState(null);

  const handleClose = (e) => {
    e.stopPropagation();
    setIsShow(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    textContentDelete();
    setIsShow(false);
  };

  return (
    <div>
      <span
        onClick={(e) => {
          e.stopPropagation();
          setIsShow(!isShow);
        }}
        className="absolute opacity-0 group-hover:opacity-100 bg-main-pink text-white left-[-1rem] top-[-1rem] rounded-full w-7 h-7 flex justify-center items-center cursor-pointer"
      >
        <LuPencilLine />
      </span>
      {isShow && (
        <div className="absolute z-30 top-[0.8rem] left-[-7.3rem]">
          <div
            className="fixed top-0 left-0 z-10 w-full h-full"
            onClick={(e) => handleClose(e)}
          ></div>
          <div
            onMouseLeave={() => setHoverState(null)}
            className="absolute z-10 p-1 text-xs border rounded-md w-28 bg-text-white border-main-pink"
          >
            <ReWriteModal
              textContent={textContent}
              isShow={isReWriteModal}
              setIsShow={setIsReWriteModal}
              textContentReWrite={textContentReWrite}
              modalBtn={
                <div
                  onMouseOver={() => setHoverState("rewrite")}
                  className="flex items-center justify-between px-2 py-1 text-gray-gray hover:text-black hover:bg-back-lGray"
                  onClick={() => setIsReWriteModal(true)}
                >
                  <div className="">다시 쓰기</div>
                  <LuPencilLine
                    className={hoverState === "rewrite" && "text-main-pink"}
                  />
                </div>
              }
            />
            <div
              onMouseOver={() => setHoverState("delete")}
              onClick={handleDelete}
              className="flex items-center justify-between px-2 py-1 text-gray-gray group hover:text-black hover:bg-back-lGray"
            >
              <div className="">텍스트 삭제</div>
              <span>
                <RiDeleteBin6Line
                  className={hoverState === "delete" && "text-main-pink"}
                />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HandleTextModal;
