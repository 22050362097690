import React from "react";
import Title from "../../components/Title";
import SettingInput from "components/Inputs/SettingInput";
import { targetInterestCode } from "utils/SettingValue";
import BasicRadio from "components/Radio/BasicRadio";
import AgeRangeSlider from "./AgeRangeSlider";
import GenderRadio from "./GenderRadio";

const TargetForm = ({
  targetName,
  setTargetName,
  targetAgeGroup,
  setTargetAgeGroup,
  targetInterestsCode,
  setTargetInterestsCode,
  targetGender,
  setTargetGender,
  onSubmit,
}) => {
  const options = targetInterestCode.map((item) => ({
    label: item.target_interests_name_kor,
    value: item.target_interests_code,
  }));

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="flex gap-2.5 w-full">
        <div className="w-full">
          <Title title={"타겟 이름"} />
          <div className="mt-2">
            <SettingInput
              type={"text"}
              value={targetName}
              setValue={setTargetName}
              placeholder={"타겟명"}
            />
          </div>
        </div>
      </div>
      <div>
        <Title title={"연령대"} />
        <div className="mt-2">
          <AgeRangeSlider
            ageRange={targetAgeGroup}
            setAgeRange={setTargetAgeGroup}
          />
        </div>
      </div>
      <div>
        <Title title={"성별 선택"} />
        <div className="mt-2">
          <GenderRadio
            targetGender={targetGender}
            setTargetGender={setTargetGender}
          />
        </div>
      </div>
      <div>
        <Title title={"관심사"} />
        <div className="mt-2 flex flex-wrap">
          <BasicRadio
            name="interests"
            options={options}
            defaultCheckOption={targetInterestsCode}
            className="w-[180px]"
            onChange={(value) => setTargetInterestsCode(value)}
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={onSubmit}
          className="bg-gradient-to-bl from-main-red to-main-purple to-55% text-white py-3 px-6 rounded-xl text-base"
        >
          설정 완료
        </button>
      </div>
    </div>
  );
};

export default TargetForm;
