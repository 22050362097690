export const NavbarMatcherToEng = (nav) => {
    // eslint-disable-next-line default-case
    switch (nav) {
        case '프로젝트':
            return 'project';
        case '템플릿':
            return 'templates';
        case '키워드픽':
            return 'keyword-pick';
        case '사전설정':
            return 'setting';
        case '요금제':
            return 'plan';
        case '가이드':
            return 'help';
        case '문의사항':
            return 'feedBack';
        case '공지사항':
            return 'notification';
    }
};

export const NavbarMatcherToKor = (nav) => {
    // eslint-disable-next-line default-case
    switch (nav) {
        case 'project':
            return '프로젝트';
        case 'templates':
            return '템플릿';
        case 'keyword-pick':
            return '키워드픽';
        case 'setting':
            return '사전설정';
        case 'plan':
            return '요금제';
        case 'help':
            return '가이드';
        case 'feedBack':
            return '문의사항';
        case 'notification':
            return '공지사항';
    }
};
