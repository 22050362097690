import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reCreateAIText: '', // 초기 상태 설정
};

const aiTextSlice = createSlice({
    name: 'text',
    initialState,
    reducers: {
        setReCreateAIText: (state, action) => {
            // 상태 업데이트
            state.reCreateAIText = action.payload;
        },
        clearReCreateAIText: (state) => {
            // 상태 초기화
            state.reCreateAIText = '';
        },
    },
});

export const { setReCreateAIText, clearReCreateAIText } = aiTextSlice.actions;

export default aiTextSlice.reducer;
