import { IoClose } from "react-icons/io5";

const BackConfirmModal = ({ setIsConfirmModal, bacIdx, backFunc }) => {
  return (
    <div className="select-none text-text-black">
      <div
        onClick={() => setIsConfirmModal(false)}
        className="fixed top-0 left-0 w-full h-full bg-[#00000030] z-30 flex justify-center items-center"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="p-4 bg-gradient-modal rounded-lg w-[450px]"
        >
          <div className="z-30 flex items-center justify-between mb-4 text-base font-bold">
            <div> 뒤로가기</div>
            <IoClose onClick={() => setIsConfirmModal(false)} />
          </div>
          <div
            className={` m-auto text-base font-normal text-center  bg-white py-3 rounded-md`}
          >
            <div>입력 도중 뒤로 가게 되면 입력한 값이</div>
            <div>초기화되어 복구할 수 없습니다.</div>
            <div className="font-bold">정말 돌아가시겠습니까?</div>
          </div>
          <div className="w-full mt-4 text-center">
            <button
              onClick={() => backFunc(bacIdx)}
              className="bg-main-red font-normal rounded-md text-white p-1 text-base w-[120px]"
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BackConfirmModal;
