import React, { useState } from "react";
import ModalTitle from "./ModalTitle";
import { toast } from "react-toastify"; // 토스트 사용을 위해 import

export default function CorporateModal({ onSubmit }) {
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [inquiry, setInquiry] = useState("");

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 이메일 형식 확인용 정규식

    if (!companyName) {
      toast.error("회사명을 입력해주세요.");
      return;
    }
    if (!industry) {
      toast.error("업종을 입력해주세요.");
      return;
    }
    if (!email) {
      toast.error("이메일을 입력해주세요.");
      return;
    }
    if (!emailRegex.test(email.trim())) {
      toast.error("올바른 이메일 형식이 아닙니다.");
      return;
    }
    if (!contact) {
      toast.error("연락처를 입력해주세요.");
      return;
    }
    if (!inquiry) {
      toast.error("문의 내용을 입력해주세요.");
      return;
    }

    onSubmit(companyName, industry, email, contact, inquiry);
  };

  const handleContactChange = (e) => {
    const input = e.target.value;
    // 숫자만 남기기 ( - 제거 포함)
    const sanitizedInput = input.replace(/[^0-9]/g, ""); // 숫자 이외의 모든 문자를 제거

    if (input !== sanitizedInput) {
      toast.error("숫자만 입력해주세요!"); // 숫자가 아닌 입력 시 토스트 메시지 표시
    }

    setContact(sanitizedInput);
  };

  return (
    <div className="mt-2">
      <div className="space-y-3 text-sm w-[400px]">
        <ModalTitle title={"회사명"} />
        <input
          type="text"
          placeholder="내용 입력"
          className="focus:outline-main-red focus:font-semibold placeholder:text-text-gray rounded-lg text-sm w-full bg-white p-2.5"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <ModalTitle title={"업종"} />
        <input
          type="text"
          placeholder="내용 입력"
          className="focus:outline-main-red focus:font-semibold placeholder:text-text-gray rounded-lg text-sm w-full bg-white p-2.5"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
        />
        <ModalTitle title={"이메일"} />
        <input
          type="email"
          placeholder="gildong@gmail.com"
          className="focus:outline-main-red focus:font-semibold placeholder:text-text-gray rounded-lg text-sm w-full bg-white p-2.5"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ModalTitle title={"연락처"} />
        <input
          type="text"
          placeholder="숫자만 입력 (-없이)"
          className="text-sm bg-white focus:outline-1 focus:outline-main-pink w-full py-2.5 px-4 rounded-lg resize-none placeholder:text-text-gray"
          value={contact}
          onChange={handleContactChange} // 연락처 입력 시 처리
        />

        <div className="flex justify-between ">
          <ModalTitle title={"문의내용"} />
          <p className="text-sm text-text-gray">
            현재 글자수
            <span className="ml-2 text-main-pink">{inquiry.length}</span>
          </p>
        </div>
        <textarea
          placeholder="내용 입력"
          className="w-full py-2.5 px-4 rounded-lg resize-none focus:outline-1 focus:outline-main-pink"
          value={inquiry}
          onChange={(e) => {
            const inputValue = e.target.value;
            // 글자 수가 250자를 넘지 않도록 제한
            if (inputValue.length <= 250) {
              setInquiry(inputValue);
            } else {
              toast.error("최대 250자까지 입력 가능합니다."); // 토스트 메시지로 알림
            }
          }}
          rows={5}
        />
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="text-white bg-main-red p-2 rounded-md text-sm text-center w-[250px]"
          onClick={handleSubmit}
        >
          문의하기
        </button>
      </div>
    </div>
  );
}
