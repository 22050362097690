import { useState, useEffect } from "react";

const BasicRadio = ({
  name,
  options,
  defaultCheckOption = "",
  className = "",
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultCheckOption);

  useEffect(() => {
    if (defaultCheckOption !== undefined && defaultCheckOption !== null) {
      setSelectedOption(defaultCheckOption);
    }
  }, [defaultCheckOption]);

  const handleChange = (value) => {
    setSelectedOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      {options.map((option, index) => (
        <label
          key={index}
          className={`${className} ${
            selectedOption === option.value
              ? "flex items-center cursor-pointer relative mr-2 font-semibold"
              : "flex items-center cursor-pointer relative mr-2"
          }`}
        >
          <input
            type="radio"
            name={name}
            value={option.value}
            disabled={option.disabled}
            checked={selectedOption === option.value}
            onChange={() => handleChange(option.value)}
            className="hidden"
          />
          <span
            className={`w-5 h-5 inline-block mr-2 border-2 rounded-full ${
              selectedOption === option.value
                ? "border-main-red"
                : "border-gray-gray"
            } ${
              !option.disabled && "hover:border-main-red"
            } relative transition-colors duration-300`}
          >
            {selectedOption === option.value && (
              <span className="w-2.5 h-2.5 bg-main-red rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-transform duration-300"></span>
            )}
            {option.disabled && (
              <span className="w-2.5 h-2.5 bg-gray-gray rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-transform duration-300"></span>
            )}
          </span>
          {option.label}
        </label>
      ))}
    </>
  );
};

export default BasicRadio;
