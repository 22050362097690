import React from "react";

const BrandInfo = ({ brandDescription, brandName, tags, value }) => {
  // 텍스트 길이를 제한하는 함수
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="flex justify-between bg-white p-5 rounded-r-xl border-l-[3px] border-main-red drop-shadow-md">
      <div className="flex gap-10">
        <div>
          <p className="text-sm text-gray-dGray ">{value || "카테고리"}</p>
          <div className="flex">
            <p className="flex-grow overflow-hidden text-base font-semibold text-ellipsis whitespace-wrap max-w-32 line-clamp-2">
              {/* 브랜드명 길이 제한 적용 */}
              {truncateText(brandName || "브랜드명", 20)}
            </p>
          </div>
        </div>

        <div>
          <p className="w-[450px] text-text-gray text-sm">
            {brandDescription || "브랜드 설명"}
          </p>
          {tags.length !== 0 ? (
            <ul className="flex gap-2 mt-2">
              {tags.map((tag, index) => (
                <li
                  className="w-fit bg-gray-sGray text-sm rounded-md px-3 py-1 flex text-back-gray items-center"
                  key={index}
                >
                  {tag}
                </li>
              ))}
            </ul>
          ) : (
            <p className="mt-2 w-fit bg-gray-sGray text-sm rounded-md px-3 py-1 flex text-back-gray items-center">
              키워드
            </p>
          )}
        </div>
      </div>
      <div className="text-text-lGray">2주전</div>
    </div>
  );
};

export default BrandInfo;
