import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useRequestGet, useRequestDelete } from "hooks/useRequest";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { formatDate } from "utils/DateUtils";
import Content from "../components/Content";
import Pagination from "components/Pagination/Pagination";
import CommentList from "../components/CommentList";
import DefaultBtn from "components/Buttons/DefaultBtn";
import CommentForm from "../components/CommentForm";
import { useQueryClient } from "@tanstack/react-query";

function FeedBackContent() {
  let { feedseq } = useParams();
  const navigate = useNavigate();
  const [contentData, setContentData] = useState({});
  const [commentData, setCommentData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const queryClient = useQueryClient();

  // Fetch content data
  const getFeedContent = useRequestGet(`/api/feedback/${feedseq}`, [
    "FeedBackContent",
    feedseq,
  ]);

  // Fetch comments data
  const getFeedComment = useRequestGet(
    `/api/feedback/${feedseq}/comment?page=${page}&size=10`,
    ["getFeedComment", feedseq, page]
  );

  // useEffect for fetching data when feedseq or page changes
  useEffect(() => {
    if (getFeedContent.status === "success" && getFeedContent.data) {
      setContentData(getFeedContent.data.data);
    } else if (getFeedContent.status === "error") {
      console.log("피드 내용 요청 실패");
    }

    if (getFeedComment.status === "success" && getFeedComment.data) {
      setCommentData(getFeedComment.data.data.data);
      setTotalPages(getFeedComment.data.data.pageInfo.totalPages);
    } else if (getFeedComment.status === "error") {
      console.log("댓글 요청 실패");
    }
  }, [getFeedContent.data, getFeedComment.data]);

  // Delete feed content
  const deleteFeedMutation = useRequestDelete(["FeedBackContent", feedseq]);

  const handleDelete = () => {
    deleteFeedMutation.mutate(
      { url: `/api/feedback/${feedseq}` },
      {
        onSuccess: () => {
          navigate("/feedback"); // 삭제 후 피드백 목록으로 이동
        },
      }
    );
  };

  const handleNavigation = (seq) => {
    queryClient.invalidateQueries(["FeedBackContent", seq]);
    navigate(`/feedback/${seq}`);
  };

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper pb-10">
        <h3 className="text-lg font-semibold mb-7">문의사항</h3>
        <div>
          <Content
            feedbackType={contentData.feedbackType}
            title={contentData.feedbackTitle}
            userName={contentData.userName}
            createdAt={contentData.createdAt}
            count={contentData.feedbackViewCount}
            feedbackHtml={contentData.feedbackHtml}
            feedseq={contentData.seq}
            handleDelete={handleDelete}
            userSeq={contentData.userSeq}
          />
          <div className="">
            <div className="p-4 border-b">
              <div className="text-md mb-1">댓글</div>
              <CommentForm feedseq={feedseq} page={page} />
            </div>
            {commentData.map((comment) => (
              <CommentList
                key={comment.seq}
                commentId={comment.seq}
                userName={comment.userName}
                feedbackCommentBody={comment.feedbackCommentBody}
                userSeq={comment.userSeq}
                modifiedAt={formatDate(comment.modifiedAt)}
              />
            ))}
            <Pagination totalPages={totalPages} page={page} setPage={setPage} />
          </div>
        </div>
        <div className="flex items-center justify-center gap-6 pt-3">
          <button
            className={`flex items-center gap-2 ${
              contentData.previousSeq ? "" : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() =>
              contentData.previousSeq &&
              handleNavigation(contentData.previousSeq)
            }
            disabled={!contentData.previousSeq}
          >
            <FaAngleLeft />
            이전글
          </button>

          <Link to="/feedback">
            <button className="w-[129px] text-sm bg-main-red text-text-white h-8 rounded-md">
              목록
            </button>
          </Link>

          <button
            className={`flex items-center gap-2 ${
              contentData.nextSeq ? "" : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() =>
              contentData.nextSeq && handleNavigation(contentData.nextSeq)
            }
            disabled={!contentData.nextSeq}
          >
            다음글
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedBackContent;
