import { IoClose } from 'react-icons/io5';

const DeleteConfirmModal = ({ tab = 'project', setIsConfirmModal, deleteFunc }) => {
    return (
        <div className="select-none">
            <div
                onClick={() => setIsConfirmModal(false)}
                className="fixed top-0 left-0 w-full h-full bg-[#00000030] z-30 flex justify-center items-center"
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="p-4 bg-gradient-modal rounded-lg w-[450px]"
                >
                    <div className="z-30 flex items-center justify-between mb-4 text-base font-bold">
                        <div>{tab === 'project' ? '프로젝트' : '콘텐츠'} 삭제</div>
                        <IoClose onClick={() => setIsConfirmModal(false)} />
                    </div>
                    <div
                        className={`m-auto text-base font-normal text-center ${
                            tab === 'content' && 'bg-white py-3 rounded-md'
                        }`}
                    >
                        <div>{tab === 'project' ? '프로젝트' : '콘텐츠'}를 삭제하시면 관련된</div>
                        <div>모든 {tab === 'project' ? '콘텐츠가' : '템플릿이'} 삭제되며 복구할 수 없습니다.</div>
                        <div>정말 삭제하시겠습니까?</div>
                    </div>
                    <div className="w-full mt-4 text-center">
                        <button
                            onClick={() => deleteFunc()}
                            className="bg-main-red font-normal rounded-md text-white p-1 text-base w-[120px]"
                        >
                            삭제
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DeleteConfirmModal;
