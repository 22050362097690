export default function paymentsFunc(userId, name, reason) {
  const formUrl =
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdX83IM82q7oO04Cc0CjehImd7wEN49Ae3_fHOyO4cUivyBLA/formResponse";
  const formData = {
    "entry.392244380": userId,
    "entry.310532092": name,
    "entry.1736553566": reason,
  };

  const queryString = new URLSearchParams(formData).toString();
  const fullUrl = `${formUrl}?${queryString}`;

  fetch(fullUrl)
    .then((response) => {
      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("Form submission failed.");
      }
    })
    .catch((error) => console.error("Error:", error));
}
