// hooks
import { useState, useEffect } from "react";
import { useRequestGet, useRequestPost } from "hooks/useRequest";
import { useNavigate, useSearchParams } from "react-router-dom";
// commons
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import KeywordTagInput from "components/Inputs/KeywordTagInput";
import TextArea from "components/TextArea";
import AITypeDropdown from "components/Dropdowns/AITypeDropdown";
import RecommandKeywordModal from "pages/Template/components/Modals/RecommandKeywordModal";
import TimeLoadingModal from "pages/BehanceEditor/components/Modals/TimeLoadingModal";
import ShortageByteModal from "components/Modals/ShortageByteModal";
// utils
import {
  objectiveCode,
  templateCode,
  toneCode,
  languageCode,
} from "utils/TemplateQuestions";
// icons
import { FaSquarePlus, FaSquareMinus } from "react-icons/fa6";
//images
import plum_byte_icon_logo from "assets/logo/plum_byte_icon_logo.png";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
import loadingImg from "assets/images/loadingImg.gif";

const GoogleAD = ({ templateInfo }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const getUserInfo = useRequestGet("/api/user", "userInfo");

  const getBrand = useRequestGet("/api/brand", "brand"); //getBrand.data.data.data
  const getTarget = useRequestGet("/api/target", "target"); //getBrand.data.data.data
  const AiTypeList = [
    { type: "gpt", title: "GPT", describe: "창의적인 텍스트 생성" },
    { type: "claude", title: "Claude", describe: "자연스러운 텍스트 생성" },
  ];
  const [isShortageModal, setIsShortageModal] = useState(false);

  const [aiType, setAiType] = useState(AiTypeList[1].type);
  const [inputDatas, setInputDatas] = useState([]);

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [target, setTarget] = useState("");
  const [objective, setObjective] = useState("");
  const [tone, setTone] = useState("");
  const [language, setLanguage] = useState("KR"); //언어 선택은 필수로 넣어야 하더라

  const [firstVariable, setFirstVariable] = useState("");
  const [secondVariable, setSecondVariable] = useState("");
  const [thirdVariable, setThirdVariable] = useState("");
  const [fourthVariable, setFourthVariable] = useState([]);
  const [fifthVariable, setFifthVariable] = useState("");

  const [imageRequestDescription, setImageRequestDescription] = useState("");
  // 추가설정
  const [addSetting, setAddSeting] = useState(false);
  // 필수 입력 progress
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const values = [
      brand,
      target,
      firstVariable,
      secondVariable,
      fourthVariable.length > 0,
    ];
    const trueCount = values.filter(Boolean).length;
    const result = trueCount * 20;
    setProgress(result);
  }, [
    brand,
    target,
    firstVariable,
    secondVariable,
    thirdVariable,
    fourthVariable,
  ]);

  // 요청보내기
  const postTemplateCreate = useRequestPost();
  const postContentCreate = useRequestPost();
  const postImageCreate = useRequestPost();

  const handleSubmit = () => {
    const needPlum = 2;
    if (needPlum > getUserInfo?.data?.data?.remainingPlum) {
      setIsShortageModal(true);
      return;
    }
    if (progress === 100) {
      const data = {
        model: aiType,
        templateCode: templateCode.googleAD[0].template_code,

        ...(brand && { brandSeq: brand }),
        ...(target && { targetSeq: target }),
        ...(tone && { toneCode: tone }),
        ...(language && { countryCode: language }),
        ...(objective && { objectiveCode: objective }),

        ...(firstVariable && { firstVariable: firstVariable }),
        ...(secondVariable && { secondVariable: secondVariable }),
        ...(thirdVariable && { thirdVariable: thirdVariable }),
        ...(fourthVariable && { fourthVariable: fourthVariable?.join(",") }),
        ...(fifthVariable && { fifthVariable: fifthVariable }),
      };
      setInputDatas(data);
      postTemplateCreate.mutate({
        // 템플릿 생성 요청
        requestUrl: "/api/chat-completion/run",
        postData: data,
      });
      postContentCreate.mutate({
        // 콘텐츠 항목 생성 요청
        requestUrl: `/api/project/${projectSeq}/content`,
        postData: data,
      });
    }
  };

  // 이미지 요청
  useEffect(() => {
    if (postContentCreate.status === "success") {
      if (imageRequestDescription) {
        const { seq } = postContentCreate?.data?.data;
        const data = {
          templateCode: "C04",
          prompt: imageRequestDescription,
          imageRatio: "16:9",
        };

        postImageCreate.mutate({
          requestUrl: `/api/project/${projectSeq}/content/${seq}/images/create/v2`,
          postData: data,
        });
      } else return;
    }
  }, [postContentCreate.status]);

  // 요청 완료 컨트롤러
  useEffect(() => {
    if (imageRequestDescription) {
      if (
        postTemplateCreate.status === "success" &&
        postContentCreate.status === "success" &&
        postImageCreate.status === "success"
      ) {
        const { value } = postTemplateCreate?.data?.data;
        const { seq } = postContentCreate?.data?.data;
        // 이미지 생성하기도 필요
        const { imageS3Url } = postImageCreate?.data?.data;

        navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
          state: {
            post: value,
            contentSeq: seq,
            projectSeq: projectSeq,
            inputDatas: inputDatas,
            imageData: imageS3Url,
          },
        });
      } else {
        return;
      }
    } else if (!imageRequestDescription) {
      if (
        postTemplateCreate.status === "success" &&
        postContentCreate.status === "success"
      ) {
        const { value } = postTemplateCreate?.data?.data;
        const { seq } = postContentCreate?.data?.data;
        // 이미지 생성하기도 필요
        //   const { imageS3Url } = postImageCreate?.data;
        navigate(`/behance-inital?projectSeq=${projectSeq}&contentSeq=${seq}`, {
          state: {
            post: value,
            contentSeq: seq,
            projectSeq: projectSeq,
            inputDatas: inputDatas,
            imageData: "",
          },
        });
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postTemplateCreate.status,
    postContentCreate.status,
    postImageCreate.status,
  ]);
  return (
    <div className="mt-10">
      {(postTemplateCreate.isPending ||
        postContentCreate.isPending ||
        postImageCreate.isPending) && <TimeLoadingModal second={30} />}
      <ShortageByteModal
        remainingPlum={getUserInfo?.data?.data?.remainingPlum}
        isShow={isShortageModal}
        setIsShow={setIsShortageModal}
      />
      <div>
        <AITypeDropdown
          selectValue={aiType}
          setSelectValue={setAiType}
          itemList={AiTypeList}
        />
      </div>
      <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div>
      {/* 기본 input*/}
      <div className="flex items-center justify-center text-sm text-center text-gray-dGray">
        <div>콘텐츠의 정확성 일치율</div>
        <div className="ml-2 font-bold text-main-pink">{progress}%</div>
      </div>
      <div className="mt-2 rounded-full bg-gray-lGray">
        <div
          className="w-full bg-stripes-pink h-3 rounded-full bg-[#F33956]"
          style={{ width: `${progress}%`, transition: "width 0.1s linear" }}
        ></div>
      </div>
      {/* 변수 input*/}
      <div className="pt-8">
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <div className="flex justify-between">
              <div className="font-semibold ">
                <span className="m-1 text-main-pink"> *</span>
                브랜드 설정
              </div>
              <div
                className="text-xs text-main-pink"
                onClick={() => navigate("/setting?tab=brand")}
              >
                추가하기
              </div>
            </div>
            <TemplateDropdown
              selectValue={brand}
              setSelectValue={setBrand}
              itemList={
                getBrand?.data?.data?.data ? getBrand.data.data.data : []
              }
              mainCode={"seq"}
              viewValueCode={"brandName"}
            />
          </div>

          <div>
            <div className="flex justify-between">
              <div className="font-semibold ">
                <span className="m-1 text-main-pink"> *</span>
                타겟 설정
              </div>
              <div
                className="text-xs text-main-pink"
                onClick={() => navigate("/setting?tab=target")}
              >
                추가하기
              </div>
            </div>
            <TemplateDropdown
              selectValue={target}
              setSelectValue={setTarget}
              itemList={
                getTarget?.data?.data?.data ? getTarget.data.data.data : []
              }
              mainCode={"seq"}
              viewValueCode={"targetName"}
            />
          </div>

          <div>
            <div className="font-semibold">
              <span className="m-1 text-main-pink">*</span>
              광고 목적
            </div>
            <input
              type="text"
              className="w-full p-2 rounded-xl focus:outline-none "
              value={firstVariable}
              onChange={(e) => setFirstVariable(e.target.value)}
              placeholder="이벤트 및 프로모션 홍보"
            />
          </div>

          <div>
            <div className="font-semibold">
              <span className="m-1 text-main-pink"> *</span>
              광고 내용
            </div>
            <input
              type="text"
              className="w-full p-2 rounded-xl focus:outline-none"
              onChange={(e) => setSecondVariable(e.target.value)}
              placeholder="신제품 구매시 30%할인"
            />
          </div>

          <div className="col-span-2">
            <div className="flex justify-between">
              <div className="font-semibold">
                <span className="m-1 text-main-pink">*</span>
                핵심 키워드
              </div>
              <RecommandKeywordModal
                variable={fourthVariable}
                setVariable={setFourthVariable}
                modalBtn={
                  <button className="text-xs text-main-pink">
                    키워드 추천
                  </button>
                }
              />
            </div>
            <KeywordTagInput
              tags={fourthVariable ? fourthVariable : []}
              setTags={setFourthVariable}
              bgStyle="white"
            />
          </div>
        </div>
      </div>
      {/* 추가 설정 */}
      <div
        className={
          addSetting
            ? "w-full border-2 border-main-pink rounded-lg my-8 bg-text-white"
            : "w-full border-2 border-gray-gray border-dashed rounded-lg my-8"
        }
      >
        <div
          className={
            addSetting
              ? "flex justify-center items-center text-sm text-main-pink py-2 bg-[#eb228310] rounded-lg font-semibold"
              : "flex justify-center items-center text-sm text-gray-gray py-2 rounded-lg font-semibold"
          }
        >
          {addSetting ? (
            <FaSquareMinus className="w-5 h-5 mr-2" />
          ) : (
            <FaSquarePlus className="w-5 h-5 mr-2" />
          )}
          <button type="button" onClick={() => setAddSeting(!addSetting)}>
            추가 설정
          </button>
        </div>

        {addSetting && (
          <div className="grid grid-cols-2 gap-4 p-4">
            <div>
              <div className="font-semibold">셀링 포인트</div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                onChange={(e) => setThirdVariable(e.target.value)}
                placeholder="자연 친화적, 빠른 보습 효과"
              />
            </div>

            <div>
              <div className="font-semibold">언어</div>
              <TemplateDropdown
                backgroundStyle={"#F8F9FA"}
                selectValue={language}
                setSelectValue={setLanguage}
                itemList={languageCode}
                mainCode={"language_code"}
                viewValueCode={"language_name_kor"}
              />
            </div>

            <div className="col-span-2">
              <div className="font-semibold">웹사이트 주소</div>
              <input
                type="text"
                className="w-full p-2 rounded-xl focus:outline-none bg-[#F8F9FA]"
                onChange={(e) => setFifthVariable(e.target.value)}
                placeholder="www."
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center w-full mt-8 text-sm">
        <button
          onClick={handleSubmit}
          className={
            progress === 100
              ? "m-auto font-semibold px-3 py-2 rounded-md text-text-white bg-gradient-to-r from-[#9218D4] to-[#D22585]"
              : "m-auto border border-gray-gray px-3 py-2 rounded-md text-gray-gray"
          }
        >
          (-2Byte) 생성하기
        </button>
      </div>
    </div>
  );
};
export default GoogleAD;
