import React, { useState } from "react";
import KeywordBadge from "components/Buttons/KeywordBadge";

const TagInput = ({ tags, setTags }) => {
  const [isFocused, setFocused] = useState(false);
  const [tagValue, setTagValue] = useState("");

  const addTag = () => {
    // Trim and remove comma from the tag value
    const trimmedValue = tagValue.trim().replace(/,/g, "");

    if (trimmedValue !== "" && !tags.includes(trimmedValue)) {
      setTags([...tags, trimmedValue]);
      setTagValue(""); // Clear the input field after adding the tag
    }
  };

  const removeTag = (index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  return (
    <div
      className={`flex flex-wrap gap-2 items-center bg-white p-1.5 rounded-lg ${
        isFocused ? "border-main-pink border-2" : "border-white border-2"
      }`}
    >
      {tags.map((tag, index) => (
        <KeywordBadge
          keyword={tag}
          key={index}
          onClick={() => removeTag(index)}
        />
      ))}
      <input
        className="flex-grow px-2 py-1 focus:outline-none text-sm"
        type="text"
        value={tagValue}
        onChange={(e) => setTagValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter" || e.key === ",") {
            addTag();
          }
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder="키워드 입력"
      />
    </div>
  );
};

export default TagInput;
