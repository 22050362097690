import React from "react";
import Title from "../../components/Title";
import SettingInput from "components/Inputs/SettingInput";
import SettingTextarea from "../../components/SettingTextarea";
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import TagInput from "components/Inputs/TagInput";

const BrandForm = ({
  brandName,
  setBrandName,
  setBrandDescription,
  value,
  setValue,
  tags,
  setTags,
  onSubmit,
  brandDescription,
  categoryConfig,
}) => (
  <div className="flex flex-col gap-5">
    <div className="flex gap-2.5">
      <div className="w-9/12">
        <Title title={"브랜드 이름"} />
        <div className="mt-2">
          <SettingInput
            type={"text"}
            value={brandName}
            setValue={setBrandName}
            placeholder={"브랜드 명"}
          />
        </div>
      </div>
      <div className="w-[300px]">
        <Title title={"카테고리"} />

        <div className="mt-2">
          <TemplateDropdown
            backgroundStyle="#ffffff"
            selectValue={value}
            setSelectValue={setValue}
            itemList={categoryConfig}
            mainCode="product_category_code"
            viewValueCode="product_category_name_ko"
            placeholderValue="카테고리"
          />
        </div>
      </div>
    </div>
    <div>
      <Title title={"브랜드 설명"} />
      <div className="mt-2">
        <SettingTextarea
          placeholder={"브랜드 설명을 기입해주세요"}
          setValue={setBrandDescription}
          value={brandDescription}
          rows="10"
          type="default"
        />
      </div>
    </div>
    <div>
      <Title title={"연관 키워드"} />
      <div className="mt-2">
        <TagInput tags={tags} setTags={setTags} />
      </div>
    </div>
    <div className="flex justify-center mt-4">
      <button
        onClick={onSubmit}
        className="bg-gradient-to-bl from-main-red to-main-purple to-55% text-white py-3 px-6 rounded-xl text-base"
      >
        설정 완료
      </button>
    </div>
  </div>
);

export default BrandForm;
