import { useNavigate } from "react-router";
// hooks
import { useEffect, useState } from "react";
import { useRequestGet } from "hooks/useRequest";
// icons
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
// image
import plum_byte_icon_logo from "assets/logo/plum_byte_icon_logo.png";
// Header Components
import RealTimeTrends from "layout/Header/components/RealTimeTrends";
// utility
import { PlanMatcherToKor } from "utils/PlanMatcher";
import MyInfoModal from "layout/Header/components/MyInfoModal";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsMobile } from "../../redux/isMobileSlice";
import { setIsRefetchPlum } from "../../redux/isRefetchPlum";
const Header = ({ bannerHeight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useSelector((state) => state.mobile.isMobile);
  const refetchPlum = useSelector((state) => state.refetchPlum.isRefetchPlum);
  const [myInfoModalShow, setMyInfoModalShow] = useState(false);

  const getUserInfo = useRequestGet("/api/user", "userInfo");
  const planColor = {
    FREE: "bg-[#51CF66]",
    NANO: "bg-[#EB2283]",
    BASIC: "bg-[#906AFF]",
    CLASSIC: "bg-[#9C1AD4]",
    PRIME: "bg-[#2E0053]",
  };
  // 모바일 사이즈설정
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1024;
      dispatch(setIsMobile(isMobile));
    };
    handleResize();
    // 윈도우 리사이즈 이벤트에 핸들러를 연결
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  // 유저 정보 에러 시
  useEffect(() => {
    if (getUserInfo.isError) {
      navigate("landing");
      console.log("getUserInfo.isError", getUserInfo.isError);
    }
  }, [getUserInfo.isError]);

  // 플럼 정보 새로고침
  useEffect(() => {
    if (refetchPlum) {
      getUserInfo.refetch();
      dispatch(setIsRefetchPlum(false));
    }
  }, [refetchPlum]);

  // 내 정보
  useEffect(() => {
    if (refetchPlum) {
      getUserInfo.refetch();

      dispatch(setIsRefetchPlum(false));
    }
  }, [refetchPlum]);
  return (
    <>
      <header
        className="fixed z-20 w-screen py-4 select-none bg-back-lGray transition-all duration-300"
        style={{ top: `${bannerHeight}px` }} // Corrected this line
      >
        {getUserInfo?.data?.data && (
          <div className="flex items-center justify-between m-auto cherry-header-wrapper">
            <div className="flex items-center justify-center">
              <div
                className={`rounded-md text-back-lGray px-2 py-1 font-semibold text-xs ${
                  planColor[getUserInfo?.data?.data?.planName]
                }`}
              >
                {getUserInfo?.data?.data?.planName &&
                  PlanMatcherToKor(getUserInfo?.data?.data?.planName)}
              </div>
              <div
                className="relative flex items-center mx-2 cursor-pointer"
                onClick={() => setMyInfoModalShow(!myInfoModalShow)}
              >
                <span className="font-semibold">
                  {getUserInfo?.data?.data?.name &&
                    getUserInfo?.data?.data?.name}
                </span>
                <span>님</span>
                {myInfoModalShow ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              {myInfoModalShow && (
                <>
                  <MyInfoModal
                    myInfo={getUserInfo.data.data}
                    myInfoModalShow={myInfoModalShow}
                    setMyInfoModalShow={setMyInfoModalShow}
                  />
                  <div
                    className="fixed inset-0 z-0"
                    onClick={() => setMyInfoModalShow(false)}
                  ></div>
                </>
              )}
              <div className="rounded-md flex text-main-purple px-2 py-1 font-semibold text-sm items-center bg-[#9C1AD410]">
                <img
                  alt="plum_byte_icon_logo"
                  src={plum_byte_icon_logo}
                  className="w-4"
                />
                <div className="mx-[2px] font-bold">
                  {getUserInfo?.data?.data?.remainingPlum &&
                    getUserInfo?.data?.data?.remainingPlum}
                </div>
                <div className="">Byte</div>
              </div>
            </div>
            <RealTimeTrends />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
