import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import TabButton from "components/Tap/TapButton";

import Information from "./Information";
import Payment from "./Payment";

const MyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("information");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`?tab=${tab}`); // URL 쿼리 스트링을 업데이트
  };

  return (
    <div className="wrapper">
      <div className="space-y-2 cherry-wrapper ">
        <div className="flex mt-8 items-center">
          <div className="text-2xl font-bold">마이페이지</div>
          <div className="bg-gray-sGray p-1 rounded-xl w-fit mt-1 m-auto">
            <TabButton
              name="내정보"
              isActive={activeTab === "information"}
              onClick={() => handleTabChange("information")}
            />
            <TabButton
              name="결제정보"
              isActive={activeTab === "payment"}
              onClick={() => handleTabChange("payment")}
            />
          </div>
        </div>

        <div>
          {activeTab === "information" && <Information />}
          {activeTab === "payment" && <Payment />}
        </div>
      </div>
    </div>
  );
};

export default MyPage;
