import { useState } from "react";
import { useRequestPost } from "hooks/useRequest";
import DefaultBtn from "components/Buttons/DefaultBtn";
import { useQueryClient } from "@tanstack/react-query";

function CommentForm({ feedseq, page }) {
  const [commentText, setCommentText] = useState("");
  const postComment = useRequestPost();
  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault(); // 기본 form 제출 동작 막기
    if (commentText.trim() === "") return; // 빈 문자열일 경우 처리 방지

    postComment.mutate(
      {
        requestUrl: `/api/feedback/${feedseq}/comment`,
        postData: { feedbackCommentBody: commentText },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getFeedComment");
          setCommentText("");
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full gap-2">
      <input
        type="text"
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder="댓글을 입력해주세요"
        className="w-full py-1 px-2 rounded-md focus:outline-main-red "
      />
      <div className="w-[72px]">
        <DefaultBtn
          type="submit"
          content="작성하기"
          btnClickFunc={handleSubmit}
        />
      </div>
    </form>
  );
}

export default CommentForm;
