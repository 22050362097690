import { LuPencilLine } from "react-icons/lu";

const EditBtn = ({ editFunc }) => {
  return (
    <div className="flex gap-2 ml-2">
      <div className="relative group">
        <button className="p-1 hover:text-main-pink">
          <LuPencilLine className="w-4.5 h-4.5" onClick={editFunc} />
        </button>
        <span className="tooltip group-hover:opacity-100 ">편집하기</span>
      </div>
    </div>
  );
};

export default EditBtn;
