const TabButton = ({ name, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-xl font-medium focus:outline-none ${
        isActive ? "text-main-red drop-shadow-md bg-white " : "text-text-gray"
      }`}
    >
      {name}
    </button>
  );
};

export default TabButton;
