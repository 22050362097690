// hooks
import { useState } from "react";
// icons
import { FaRegTrashAlt } from "react-icons/fa";

const KeywordTagInput = ({ tags, setTags, bgStyle }) => {
  const [newTag, setNewTag] = useState("");
  const handleDelete = (idx) => {
    const copyTags = [...tags];
    copyTags.splice(idx, 1);
    setTags(copyTags);
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      // Remove trailing ',' if present and trim whitespace
      const trimTag = newTag.trim().replace(/,$/, "");

      if (trimTag !== "" && !tags.includes(trimTag)) {
        setTags([...tags, trimTag]); // Add the tag
      }
      setNewTag(""); // Reset the input field
    }
  };

  return (
    <div
      className={
        bgStyle === "white"
          ? "bg-text-white rounded-lg flex items-center p-2 flex-wrap"
          : "bg-[#F8F9FA] rounded-lg flex items-center p-2 flex-wrap"
      }
    >
      {tags.map((el, idx) => (
        <div
          key={idx}
          className={
            bgStyle === "white"
              ? "flex items-center justify-center p-1 mb-1 mr-2 text-xs font-bold rounded-md text-text-gray bg-gray-sGray"
              : "flex items-center justify-center p-1 mb-1 mr-2 text-xs font-bold rounded-md text-text-gray bg-gray-lGray"
          }
          onClick={() => handleDelete(idx)}
        >
          <span className="mr-1">{el}</span> <FaRegTrashAlt />
        </div>
      ))}
      <input
        className={
          bgStyle === "white"
            ? "flex-grow w-24 max-w-full min-w-0 focus:outline-none bg-none bg-text-white"
            : "flex-grow w-24 max-w-full min-w-0 focus:outline-none bg-none bg-[#F8F9FA]"
        }
        placeholder={tags.length <= 0 ? "남성패션, 여성패션, 힙합패션" : ""}
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyUp={(e) => handleKeyUp(e)}
      />
    </div>
  );
};

export default KeywordTagInput;
