// hooks
import { useState, useEffect } from "react";
import {
  useRequestGet,
  useRequestPost,
  useRequestPatch,
} from "hooks/useRequest";
import { useNavigate, useSearchParams } from "react-router-dom";
// commons
import TemplateDropdown from "components/Dropdowns/TemplateDropdown";
import KeywordTagInputBottom from "components/Inputs/KeywordTagInputBottom";

import TextArea from "components/TextArea";
import AITypeDropdown from "components/Dropdowns/AITypeDropdown";
import RecommandKeywordModal from "pages/Template/components/Modals/RecommandKeywordModal";
import TimeLoadingModal from "pages/BehanceEditor/components/Modals/TimeLoadingModal";
import ShortageByteModal from "components/Modals/ShortageByteModal";

// utils
import {
  objectiveCode,
  templateCode,
  toneCode,
  languageCode,
  templateQuestions,
} from "utils/TemplateQuestions";
import { ReCreateContentsChanger } from "utils/ReCreateContentsChanger";
// icons
import { FiFilePlus } from "react-icons/fi";
//images
import plum_byte_icon_logo from "assets/logo/plum_byte_icon_logo.png";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
import loadingImg from "assets/images/loadingImg.gif";
import RecommandTitleModal from "pages/Template/components/Modals/RecommandTitleModal";
// redux
import { useDispatch } from "react-redux";
import { setIsRefetchPlum } from "../../../../../redux/isRefetchPlum";
const RewriteArticle = ({
  refetchFunc,
  initTemplateCode,
  initFirstVariable,
  initSecondVariable,
  initThirdVariable,
  initFourthVariable,
  initFifthVariable,
  initBrand,
  initTarget,
  initTone,
  initLanguage,
  initObjective,
  initModel,
}) => {
  const dispatch = useDispatch();
  // 문제점: 현재 정보들로 업데이트 요청 보내는 것..
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const contentSeq = searchParams.get("contentSeq");
  const getUserInfo = useRequestGet("/api/user", "userInfo");

  const getBrand = useRequestGet("/api/brand", "brand"); //getBrand.data.data.data
  const getTarget = useRequestGet("/api/target", "target"); //getBrand.data.data.data
  const AiTypeList = [
    { type: "gpt", title: "GPT", describe: "창의적인 텍스트 생성" },
    { type: "claude", title: "Claude", describe: "자연스러운 텍스트 생성" },
  ];
  const [isShortageModal, setIsShortageModal] = useState(false);
  const [aiType, setAiType] = useState(initModel);

  const [category, setCategory] = useState(initTemplateCode);
  const [brand, setBrand] = useState(initBrand);
  const [target, setTarget] = useState(initTarget);
  const [objective, setObjective] = useState(initObjective);
  const [tone, setTone] = useState(initTone);
  const [language, setLanguage] = useState(initLanguage); //언어 선택은 필수로 넣어야 하더라
  const [firstVariable, setFirstVariable] = useState(initFirstVariable);
  const [secondVariable, setSecondVariable] = useState(initSecondVariable);
  const [thirdVariable, setThirdVariable] = useState(initThirdVariable);
  const [fourthVariable, setFourthVariable] = useState(initFourthVariable);
  const [fifthVariable, setFifthVariable] = useState(initFifthVariable);
  const [imageRequestDescription, setImageRequestDescription] = useState("");
  // 추가설정
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const values = [firstVariable, tone];
    const trueCount = values.filter(Boolean).length;
    const result = trueCount * 50;
    setProgress(result);
  }, [firstVariable, tone]);

  // 요청보내기
  const postTemplateCreate = useRequestPost();
  const postImageCreate = useRequestPost();
  const handleSubmit = () => {
    const needPlum = imageRequestDescription ? 4 : 3;
    if (needPlum > getUserInfo?.data?.data?.remainingPlum) {
      setIsShortageModal(true);
      return;
    }
    if (progress === 100) {
      const data = {
        model: aiType,
        templateCode: templateCode.rewriteArticle[0].template_code,

        ...(brand && { brandSeq: brand }),
        ...(target && { targetSeq: target }),
        ...(tone && { toneCode: tone }),
        ...(language && { countryCode: language }),
        ...(objective && { objectiveCode: objective }),

        ...(firstVariable && { firstVariable: firstVariable }),
        ...(secondVariable && { secondVariable: secondVariable }),
        ...(thirdVariable && { thirdVariable: thirdVariable }),
        ...(fourthVariable && { fourthVariable: fourthVariable }),
        ...(fifthVariable && { fifthVariable: fifthVariable }),
      };
      // 템플릿&이미지 생성 요청
      postTemplateCreate.mutate({
        requestUrl: "/api/chat-completion/run",
        postData: data,
      });
      const imageData = {
        templateCode: "C04",
        prompt: imageRequestDescription,
        imageRatio: "16:9",
      };
      if (imageRequestDescription) {
        postImageCreate.mutate({
          requestUrl: `/api/project/${projectSeq}/content/${contentSeq}/images/create/v2`,
          postData: imageData,
        });
      }
    }
  };

  // 요청 완료 컨트롤러
  useEffect(() => {
    if (
      imageRequestDescription &&
      postTemplateCreate.status === "success" &&
      postImageCreate.status === "success"
    ) {
      // 이미지 생성하기도 필요
      const { value } = postTemplateCreate?.data?.data;
      const { imageS3Url } = postImageCreate?.data?.data;
      const contentHtml = ReCreateContentsChanger(category, value, imageS3Url);
      handleAutoSave(contentHtml, imageS3Url);
      dispatch(setIsRefetchPlum(true));
    } else if (
      !imageRequestDescription &&
      postTemplateCreate.status === "success"
    ) {
      const { value } = postTemplateCreate?.data?.data;
      const contentHtml = ReCreateContentsChanger(category, value);
      handleAutoSave(contentHtml);
      dispatch(setIsRefetchPlum(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTemplateCreate.status, postImageCreate.status]);

  //   저장 요청
  const patchSave = useRequestPatch();
  const handleAutoSave = (contentHtml, imageS3Url) => {
    // 자동 저장 요청을 보내는 함수
    const patchBody = {
      contentHtml: JSON.stringify(contentHtml),
      projectSeq: projectSeq,
      contentSeq: contentSeq,
      ...(imageS3Url && { s3Urls: [imageS3Url] }),

      templateCode: templateCode.rewriteArticle[0].template_code,
      ...(aiType && { model: aiType }),
      ...(firstVariable && { firstVariable: firstVariable }),
      ...(secondVariable && { secondVariable: secondVariable }),
      ...(thirdVariable && { thirdVariable: thirdVariable }),
      ...(fourthVariable && { fourthVariable: fourthVariable }),
      ...(fifthVariable && { fifthVariable: fifthVariable }),

      ...(brand && { brandSeq: brand }),
      ...(target && { targetSeq: target }),
      ...(tone && { toneCode: tone }),
      ...(language && { countryCode: language }),
      ...(objective && { objectiveCode: objective }),
    };
    patchSave.mutate({
      requestUrl: `/api/project/${projectSeq}/content/${contentSeq}`,
      patchData: patchBody,
    });
  };
  useEffect(() => {
    if (patchSave.status === "success") {
      refetchFunc();
    }
  }, [patchSave.status]);
  return (
    <div className="overflow-y-scroll pb-14 p-3 max-h-[35rem]">
      {(postTemplateCreate.isPending || postImageCreate.isPending) && (
        <TimeLoadingModal second={30} />
      )}
      <ShortageByteModal
        remainingPlum={getUserInfo?.data?.data?.remainingPlum}
        isShow={isShortageModal}
        setIsShow={setIsShortageModal}
      />
      <div>
        <div className="font-semibold">
          <span className="text-main-pink"> *</span>
          적용 모델
        </div>
        <AITypeDropdown
          minWidthPx={"100%"}
          selectValue={aiType}
          setSelectValue={setAiType}
          itemList={AiTypeList}
        />
      </div>
      {/* <div className="mb-4 font-bold text-center">{`${templateInfo.templateKR}, ${templateInfo.info}`}</div> */}

      {/* 변수 input*/}
      <div>
        <div className="mt-3">
          <div className="font-semibold">
            <span className="m-1 text-main-pink"> *</span>
            내용
          </div>
          <TextArea
            id="text"
            placeholder="내용입력"
            value={firstVariable}
            setValue={setFirstVariable}
            rows={3}
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="font-semibold">
          <span className="m-1 text-main-pink"> *</span>
          톤앤매너
        </div>
        <TemplateDropdown
          selectValue={tone}
          setSelectValue={setTone}
          itemList={toneCode.article}
          mainCode={"tone_code"}
          viewValueCode={"tone_name_kor"}
          backgroundStyle={"#F8F9FA"}
        />
      </div>

      <div className="mt-3">
        <div className="flex justify-between">
          <div className="font-semibold ">타겟 설정</div>
          <div
            className="text-xs text-main-pink"
            onClick={() => navigate("/setting?tab=target")}
          >
            추가하기
          </div>
        </div>
        <TemplateDropdown
          selectValue={target}
          setSelectValue={setTarget}
          itemList={getTarget?.data?.data?.data ? getTarget.data.data.data : []}
          mainCode={"seq"}
          viewValueCode={"targetName"}
          backgroundStyle={"#F8F9FA"}
        />
      </div>

      <div className="mt-3">
        <div className="font-semibold">언어</div>
        <TemplateDropdown
          backgroundStyle={"#F8F9FA"}
          selectValue={language}
          setSelectValue={setLanguage}
          itemList={languageCode}
          mainCode={"language_code"}
          viewValueCode={"language_name_kor"}
        />
      </div>

      <div className="flex justify-center w-full mt-8 text-sm bg-white">
        <button
          onClick={handleSubmit}
          className={`${
            progress === 100
              ? "bg-gradient-to-tr from-[#9218D4] to-[#D22585]"
              : "bg-gray-gray"
          } pl-1 pr-10 m-3 justify-between rounded-lg text-white flex items-center fixed bottom-5`}
        >
          <span
            className={`${
              progress === 100 ? "bg-[#8217AA]" : "bg-gray-dGray"
            } my-1 mr-6 p-2 rounded-lg`}
          >
            <FiFilePlus className="w-6 h-6" />
          </span>
          <span>(-3Byte) 재생성하기</span>
        </button>
      </div>
    </div>
  );
};
export default RewriteArticle;
