import React from "react";

const DeleteModal = ({ onClose, onConfirm, message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gradient-modal p-5 rounded-lg shadow-lg w-[300px]">
        <p className="text-sm text-center text-gray-600 mb-6">{message}</p>
        <div className="flex justify-center gap-4">
          <button
            className="w-24 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600"
            onClick={onConfirm}
          >
            삭제
          </button>
          <button
            className="w-24 py-2 text-sm text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
            onClick={onClose}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
