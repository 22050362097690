// layout
import { Link } from "react-router-dom";
import { useState } from "react";
import ContentsListBox from "./components/ContentsListBox";
import SearchInput from "./components/SearchInput";
import { LuPencilLine } from "react-icons/lu";

function FeedBack() {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper ">
        <div className="flex justify-between">
          <h3 className="text-xl font-bold  mt-[5px]">문의사항</h3>
          <div className="flex justify-end gap-8">
            <SearchInput setSearchTerm={setSearchTerm} />
            <Link to={"/feedBack/post"}>
              <button className="flex gap-2 items-center bg-back-dGray hover:bg-main-red text-text-white py-2 px-3 rounded-lg text-sm h-[38px] w-[88px]">
                <LuPencilLine />
                글쓰기
              </button>
            </Link>
          </div>
        </div>

        <ContentsListBox searchTerm={searchTerm} />
      </div>
    </div>
  );
}

export default FeedBack;
