import React from "react";
import CustomButton from "components/Buttons/CustomButton";
import { FaCheck } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const PaymentInfoBox = ({
  price,
  paymentDate,
  nextPaymentDate,
  paymentMethod,
  onRegisterCard,
  cardNumber,
  postPayment,
  handleDeleteClick,
}) => {
  const navigate = useNavigate();
  return (
    <div className="payment-info-box bg-white p-4 rounded-xl flex flex-col gap-4 ">
      <div>
        <h3 className="text-lg font-semibold mb-2">결제 정보</h3>
        <div className="flex flex-col gap-4">
          <div className="text-sm text-text-gray flex justify-between">
            <p>결제 금액</p>
            <p className="text-main-red">
              ₩ {Number(price).toLocaleString("ko-KR")}원
            </p>
          </div>
          <div className="text-sm text-text-gray flex justify-between">
            <p>결제일</p>
            <p> {paymentDate}</p>
          </div>
          <div className="text-sm text-text-gray flex justify-between">
            <p className="text-sm text-text-gray ">다음 결제일</p>
            <p>{nextPaymentDate}</p>
          </div>
          <div className="text-sm text-text-gray flex justify-between">
            <p className="text-sm text-text-gray ">결제 방법</p>
            <p> {paymentMethod}</p>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold ">결제 카드</h3>
        {cardNumber ? (
          <div className="">
            <div className="flex gap-2 items-center mt-4 ">
              <div className="bg-white w-full text-text-gray py-1 px-4 rounded-lg  border-text-lGray border text-center">
                {cardNumber}
              </div>
              <button onClick={() => handleDeleteClick()}>
                <FiTrash2 className="w-6 h-6 text-text-lGray" />
              </button>
            </div>

            <p className="flex gap-1 items-center text-gray-dGray text-sm">
              <FaCheck className="text-main-red w-3 h-3 mr-1" />
              결제 카드는 한개만 등록할 수 있으며, 변경시 삭제한 다음
              추가해주세요
            </p>
            {/* <CustomButton
              content=" 결제 카드 변경"
              customStyle={"text-main-red text-sm font-normal p-1 justify-end "}
              func={onRegisterCard}
            /> */}
          </div>
        ) : (
          <CustomButton
            content="결제 카드를 추가해주세요"
            customStyle={
              "bg-white text-text-gray py-1 px-4 rounded-lg border-text-lGray border w-full mt-2"
            }
            func={onRegisterCard} // 결제 카드 등록 핸들러 추가
          />
        )}
      </div>
      <div>
        <h3>최종 결제 금액</h3>
        <div className="text-sm text-text-gray flex justify-between py-3">
          <p>상품 금액</p>
          <p className="text-main-red">
            ₩ {Number(price).toLocaleString("ko-KR")}원
          </p>
        </div>
        <div className="border-t-text-gray border-t ext-text-gray flex justify-between py-3 ">
          <p>최종 결제 금액</p>
          <p className="text-lg">
            ₩{Number(price).toLocaleString("ko-KR")}
            <span className="text-sm text-text-lGray">VAT포함</span>
          </p>
        </div>
      </div>
      <div className="flex gap-3 justify-end">
        <CustomButton
          content="취소"
          customStyle={
            "bg-text-white border border-gray-lGray text-text-gray py-2 px-5 rounded-lg text-sm w-fit"
          }
          func={() => navigate("/plan")}
        />
        <CustomButton
          content="결제하기"
          customStyle={
            "bg-main-red text-text-white py-2 px-2 rounded-lg text-sm w-[200px]"
          }
          func={postPayment}
        />
      </div>
    </div>
  );
};

export default PaymentInfoBox;
