// hooks
import { useEffect, useState } from 'react';
import { useRequestGet } from 'hooks/useRequest';
import { useNavigate } from 'react-router';
// icons
import { TiArrowSortedDown } from 'react-icons/ti';
import { TiArrowSortedUp } from 'react-icons/ti';
import { TiPlus } from 'react-icons/ti';
import { TiMinus } from 'react-icons/ti';
import { SiNaver } from 'react-icons/si';
const RealTimeTrends = () => {
    const navigate = useNavigate();
    const iconBgColor = {
        'fi-rr-caret-up': 'bg-[#F3395610] text-[#F33956] px-2 py-1',
        'fi-rr-caret-down': 'bg-[#9C1AD410] text-[#9C1AD4] px-2 py-1',
        'fi-rr-minus-small': 'bg-[#F1F3F5] text-[#495057 px-2 py-1',
        'fi-rr-plus-small': 'bg-[#F33956] text-white px-[6px] text-sm py-[2px]',
    };
    const getRealTimeTrends = useRequestGet('/api/crawling/real-time-trends', 'real-time-trends');
    const [curIdx, setCurIdx] = useState(0);
    const { realTimeTrendsResponses } = getRealTimeTrends?.data?.data || [];
    useEffect(() => {
        if (realTimeTrendsResponses && realTimeTrendsResponses.length > 0) {
            const interval = setInterval(() => {
                setCurIdx((preIdx) => (preIdx + 1) % realTimeTrendsResponses.length);
            }, 5000); // 5초마다 변경
            return () => clearInterval(interval);
        }
    }, [realTimeTrendsResponses]);

    const iconMatcher = {
        'fi-rr-caret-up': <TiArrowSortedUp />,
        'fi-rr-caret-down': <TiArrowSortedDown />,
        'fi-rr-minus-small': <TiMinus />,
        'fi-rr-plus-small': <span className="px-1 font-bold">N</span>,
    };
    return (
        <div className="relative w-48 h-10 overflow-hidden">
            {realTimeTrendsResponses && realTimeTrendsResponses.length > 0 && (
                <div
                    className={`absolute top-0 left-0 w-full transition-transform duration-500`}
                    style={{ transform: `translateY(-${curIdx * 10}%)` }}
                >
                    {realTimeTrendsResponses.map((el, idx) => (
                        <div
                            key={idx}
                            className={`flex items-center h-10 justify-between`}
                            onClick={() => navigate(`/keyword-pick?subject=${el.subject}`)}
                        >
                            <div className="mr-2 font-bold text-main-pink">{String(el.ranking).padStart(2, '0')}</div>

                            <div className="w-full text-sm font-bold truncate text-start">{el.subject}</div>

                            <div>
                                {iconMatcher[el.icon] ? (
                                    <div className={`flex items-center ${iconBgColor[el.icon]} rounded-md`}>
                                        {iconMatcher[el.icon]}
                                    </div>
                                ) : (
                                    <div className={`flex items-center ${iconBgColor[el.icon]} rounded-md`}>
                                        <TiMinus />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default RealTimeTrends;
