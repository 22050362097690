import React from "react";
import { useNavigate } from "react-router";
import { IoClose } from "react-icons/io5";
// image
import plum_byte_icon_logo from "assets/logo/plum_byte_icon_logo.png";

const ShortageByteModal = ({ remainingPlum, isShow, setIsShow }) => {
  const navigate = useNavigate();
  return (
    <>
      {isShow && (
        <div
          onClick={() => setIsShow(false)}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-gradient-modal p-[20px] rounded-[15px] shadow-lg w-[440px]">
            <div className="flex mb-[10px] justify-between">
              <div className="font-bold text-[14px] bold">바이트 부족!</div>
              <IoClose />
            </div>
            <div className="w-[400px] h-[112px] rounded-[15px] bg-white py-[15px]">
              <div className="flex gap-[3px] w-[72px] h-[24px] rounded-[5px] m-auto items-center justify-center bg-[#9C1AD410] font-bold text-[14px] text-main-purple">
                <img
                  alt="plum_byte_icon_logo"
                  src={plum_byte_icon_logo}
                  className="w-[12px] h-[13px]"
                />
                {`${remainingPlum} Byte`}
              </div>
              <div className="mt-[10px] text-[14px] text-center text-text-dGray">
                <p className="">
                  바이트가 부족해서 콘텐츠 생성이 안되고 있습니다.
                </p>
                <p className="font-bold">충전하시면 바로 이용 가능하십니다.</p>
              </div>
            </div>

            <div className="mt-[10px] flex justify-center">
              <button
                className="w-[150px] h-[32px] text-white bg-main-red  text-[14px] font-bold  rounded-[8px]"
                onClick={() => navigate("/plan")}
              >
                충전하러가기
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShortageByteModal;
