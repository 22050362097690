export default function withdrawLogic(userId, name, reason) {
    // const formUrl =
    //     'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfSdlE6h0hHTJcamIpeOQvSp4-UBPK6F1OavhT3H1kf8sbGrg/formResponse';
    // const formData = {
    //     'entry.1838781822': '아니오',
    //     'entry.1082793929': '서비스 품질에 만족하지 않음',
    // };
    //위는 테스트, 아래가 진짜
    const formUrl =
        'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfxbXxxfN4WxYzGjNzvVgDlwhx2P2FUI3ybtsTTaUrXZEu5hw/formResponse';
    const formData = {
        'entry.1534688206': userId,
        'entry.207042377': name,
        'entry.770774945': reason,
    };

    const queryString = new URLSearchParams(formData).toString();
    const fullUrl = `${formUrl}?${queryString}`;

    fetch(fullUrl)
        .then((response) => {
            if (response.ok) {
                console.log('Form submitted successfully!');
            } else {
                console.error('Form submission failed.');
            }
        })
        .catch((error) => console.error('Error:', error));
}
