// hooks
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useRequestPatch } from "hooks/useRequest";
// Common Components
import BasicModal from "components/Modals/BasicModal";
import ImageUploadModal from "components/Modals/ImageUploadModal";
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
// utils
import { dateMatcher } from "utils/DateMatcher";
// project components
import ProjectHandleModal from "pages/Projects/ProjectHandleModal";
// Content Components
import ContentHandleModal from "pages/Contents/ContentHandleModal";
// icons
import { LuPencilLine } from "react-icons/lu";

const ProjectCard = ({
  tab,
  seq,
  title,
  date,
  content,
  btnTit,
  hasContents,
  refetchFunc,
  navigateUrl,
}) => {
  const nativate = useNavigate();
  const location = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const navRecord = JSON.parse(localStorage.getItem("navRecord"));

  const [edittedContentName, setEdditedContentName] = useState("");
  const [isImageUploadModal, setImageUploadModal] = useState(false);

  // Patch ContentName Handler
  const patchContentName = useRequestPatch();
  const handlePatchContentName = async () => {
    if (edittedContentName) {
      try {
        const selectedRequestUrl =
          tab === "project"
            ? `/api/project/${seq}`
            : `/api/project/${projectSeq}/content/${seq}/name`;
        const selectedPatchData =
          tab === "project"
            ? { projectName: edittedContentName }
            : { contentName: edittedContentName };
        await patchContentName.mutateAsync({
          requestUrl: selectedRequestUrl,
          patchData: selectedPatchData,
        });
        await refetchFunc();
        // setEdditedContentName("");

        // setProjectsChanged(true);
      } catch (error) {
        console.error("Error creating content:", error);

        toast.error("콘텐츠를 생성하는 데 문제가 발생했습니다.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      toast.error("콘텐츠명을 입력하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      {hasContents && (
        <div className=" w-[215px] h-[215px] rotate-6 top-0 left-0 absolute rounded-2xl border border-gray-lGray bg-text-white"></div>
      )}
      <div className="p-[23px] border font-bold border-gray-lGray rounded-2xl w-[215px] h-[215px] bg-white relative flex justify-center flex-col ">
        {/* border-gray-lGray => 이거 어떨지에 대한 제안 shadow-md */}
        <div className="">
          <div className="flex justify-between ">
            <p className="items-end text-sm font-normal text-gray-gray">
              {date && dateMatcher(date)}
            </p>

            <div className="flex-end right-5">
              {tab === "project" ? (
                <ProjectHandleModal
                  seq={seq}
                  refetchFunc={refetchFunc}
                  setImageUploadModal={setImageUploadModal}
                />
              ) : (
                <ContentHandleModal seq={seq} refetchFunc={refetchFunc} />
              )}
            </div>
          </div>
          <div className="flex justify-between mt-3 ">
            <div className="flex items-start">
              <div className="overflow-hidden text-base font-semibold h-18 w-fit max-w-48 line-clamp-3 text-ellipsis whitespace-wrap">
                {title}
              </div>

              <BasicModal
                title={
                  tab === "project" ? "프로젝트 이름 수정" : "콘텐츠 이름 수정"
                }
                modalBtn={
                  <button
                    onClick={() => setEdditedContentName(title)}
                    className="ml-1.5 "
                  >
                    <LuPencilLine className="w-6 h-6 text-main-red " />
                  </button>
                }
                content={
                  <div>
                    <input
                      value={edittedContentName}
                      onChange={(e) => setEdditedContentName(e.target.value)}
                      type="text"
                      className="w-[400px] h-[40px] border border-gray-gray text-text-dGray rounded-lg my-3 p-2"
                      placeholder={
                        tab === "project" ? "프로젝트 이름" : "콘텐츠 이름"
                      }
                      maxLength={20}
                    />
                  </div>
                }
                activeBtnText={"완료"}
                activeFunc={handlePatchContentName}
                backgroundStyle={"gradient"}
              />
            </div>

            <p className="flex-grow h-10 mt-5 overflow-hidden text-sm font-normal text-gray-gray text-ellipsis whitespace-wrap max-w-40 line-clamp-2">
              {content}
            </p>
          </div>
        </div>
        {btnTit && (
          <button
            onClick={() => {
              navigateUrl && nativate(navigateUrl);
              location === "/project"
                ? localStorage.setItem(
                    "navRecord",
                    JSON.stringify({
                      project: title,
                    })
                  )
                : tab === "project"
                ? localStorage.setItem(
                    "navRecord",
                    JSON.stringify({
                      ...(navRecord && navRecord),
                      project: title,
                    })
                  )
                : localStorage.setItem(
                    "navRecord",
                    JSON.stringify({
                      ...(navRecord && navRecord),
                      content: title,
                      templates: title,
                    })
                  );
            }}
            className="w-full h-8 mt-5 text-xs font-normal border rounded-md border-gray-lGray hover:bg-gray-sGray hover:text-main-pink "
          >
            {btnTit}
          </button>
        )}
      </div>
      {isImageUploadModal && (
        <ImageUploadModal seq={seq} setImageUploadModal={setImageUploadModal} />
      )}
    </>
  );
};

export default ProjectCard;
