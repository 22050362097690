import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IoIosArrowDown } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa6';
export default function LandingDropdown({ selectValue = '', setSelectValue, itemList }) {
    const handleChange = (e) => {
        setSelectValue(e.target.value);
    };
    return (
        <Box
            sx={
                {
                    // minWidth: 120,
                }
            }
        >
            <FormControl>
                <Select
                    sx={{
                        fontSize: '15px',
                        color: '#858e96 !important',
                        background: '#F7F7F7',
                        borderRadius: '10px',
                        '& .MuiSelect-select': {
                            padding: 1.9,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '.MuiSelect-select>svg': {
                            display: 'none',
                        },
                    }}
                    labelId="demo-simple-select-label"
                    value={selectValue}
                    IconComponent={IoIosArrowDown}
                    onChange={(e) => handleChange(e)}
                    displayEmpty // 기본 텍스트 표시
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                height: '12rem',
                                borderRadius: '15px',
                                margin: '10px',
                                // marginRight: '-10px',
                                overflowY: 'scroll', // 메뉴의 내부에서만 스크롤이 나타나도록 설정
                            },
                        },
                    }} // 이 부분에서 MenuItem의 너비를 20px로 제한합니다.
                >
                    <MenuItem
                        value=""
                        disabled
                        sx={{
                            display: 'none',
                            fontSize: '13px',
                        }}
                    >
                        카테고리
                    </MenuItem>
                    {itemList.map((el, idx) => (
                        <MenuItem
                            key={idx}
                            value={el}
                            sx={
                                el === selectValue
                                    ? {
                                          fontSize: '13px',
                                          borderRadius: '10px',
                                          color: '#000000',
                                          background: '#F1F3F5 !important',
                                          marginY: '3px',
                                      }
                                    : {
                                          fontSize: '13px',
                                          borderRadius: '10px',
                                          color: '#858e96',
                                          background: '#ffffff !important',
                                          '&:hover': {
                                              background: '#F1F3F5 !important',
                                          },
                                          marginY: '3px',
                                      }
                            }
                        >
                            {el}
                            {el === selectValue && <FaCheck className="ml-2 text-main-pink" />}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
