// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// Home Components
import ContentCard from "pages/Home/components/ContentCard";
import ColorCard from "pages/Home/components/CreateCard/ColorCard";
import DreamCard from "pages/Home/components/CreateCard/DreamCard";
import BrandCard from "pages/Home/components/BrandCard";
// image
import advertise_box from "assets/home/advertise_box.png";
import ad_card from "assets/home/ad_card.png";
import brand from "assets/home/brand.png";
import brandTarget from "assets/home/brandTarget.png";

// utils
import { templateQuestions } from "utils/TemplateQuestions";
// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    const TOKEN = localStorage.getItem("TOKEN");
    if (!TOKEN) navigate("/landing");
    else return;
  }, []);

  const introTexts = [
    "오늘의 영감을 찾아보세요",
    "어떤 콘텐츠를 구상 중이신가요?",
    "좋은 꿈 꾸셨나요?",
    "오늘의 아이디어를 실현해보세요",
    "오늘의 트렌드, 당신의 콘텐츠로!",
  ];
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(templateQuestions.length / itemsPerPage);
  const handlePrevPage = () => {
    setCurrentPage((prevPage) =>
      prevPage > 0 ? prevPage - 1 : totalPages - 1
    );
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages - 1 ? prevPage + 1 : 0
    );
  };

  const [randomText, setRandomText] = useState("");
  const handleClick = () => {
    const randomIndex = Math.floor(Math.random() * introTexts.length);
    setRandomText(introTexts[randomIndex]);
  };
  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div className="h-screen all-bg-color">
      {/* `fixed left-0 z-30 h-screen select-none w-44 */}
      <div className="h-full cherry-wrapper">
        {/* .cherry-wrapper {  @apply m-auto pt-[5rem] w-[1144px] z-10;} */}
        <div className="mb-3 font-bold text-md">{randomText}</div>

        <div className="max-h-[90%] h-full gap-3 ">
          {/* create card */}
          <div className="max-h-[27%] h-full relative">
            <DreamCard
              title="어제 본 꿈 이야기"
              description="플럼바이트가 해석해드려요"
              placeholder="은하수가 떠있는 배경으로 잃어버린 사랑을 찾는 꿈"
            />
            <ColorCard
              title="감정 팔레트"
              description="컬러 카드를 나눠드려요"
              placeholder="잡을 수 없는 꿈을 따라가는 기분"
            />
          </div>

          {/* bramd card */}
          <div className="my-2 h-full max-h-[9rem] grid grid-cols-2 gap-4 row-span-1">
            <BrandCard
              image={brand}
              title="나의 브랜드(제품)"
              description="미리 등록하고 콘텐츠 제작때마다 불러오기"
              navigateURL="/setting?tab=brand"
            />
            <BrandCard
              image={brandTarget}
              title="나의 브랜드 타겟은?"
              description="누구를 위한 브랜드인가"
              navigateURL="/setting?tab=target"
            />
          </div>

          {/* advertise */}
          <div
            className="select-none w-full h-full max-h-[42%] flex justify-center items-center bg-cover rounded-2xl row-span-3"
            style={{ backgroundImage: `url(${ad_card})` }}
          >
            <div className="w-full px-6 pb-3">
              <div className="relative mt-6 mb-8 text-text-white pl-60">
                <div className="mb-2 text-lg font-bold">
                  플럼바이트, 어떻게 사용하나요?
                </div>
                <button className="px-2 py-1 text-xs border-2 rounded-md border-back-lGrey">
                  가이드 바로가기
                </button>

                {/* 좌우 아이콘 */}
                <div className="absolute right-0 bottom-[-10px] text-text-white flex">
                  <IoIosArrowBack
                    className="mr-2 cursor-pointer"
                    onClick={handlePrevPage}
                  />
                  <IoIosArrowForward
                    className="cursor-pointer"
                    onClick={handleNextPage}
                  />
                </div>
              </div>
              {/* <div className="grid grid-cols-4 gap-2">
                                {getCurrentItems().map((el, idx) => (
                                    <ContentCard
                                        key={idx}
                                        title={el.templateKR}
                                        tag={el.tag}
                                        description={el.description}
                                    />
                                ))}
                            </div> */}

              <div className="relative carousel-container">
                <div
                  className="carousel-wrapper"
                  style={{ transform: `translateX(-${currentPage * 100}%)` }}
                >
                  {templateQuestions.map((el, idx) => (
                    <div key={idx} className="flex-shrink-0 w-1/4 p-1">
                      <ContentCard
                        key={idx}
                        title={el.templateKR}
                        tag={el.tag}
                        description={el.description}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
