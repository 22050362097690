export const PlanMatcherToKor = (plan) => {
  // eslint-disable-next-line default-case
  switch (plan) {
    case "FREE":
      return "무료";
    case "NANO":
      return "나노";
    case "BASIC":
      return "베이직";
    case "CLASSIC":
      return "클래식";
    case "PRIME":
      return "프라임";
  }
};

export const PlanStatus = (status) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case "DONE":
      return "결제 완료";
    default:
      return "결제 전";
  }
};
