import React from 'react';
import plum_byte_icon_logo from 'assets/logo/plum_byte_icon_logo.png';
const ByteBadge = ({ byte, date }) => {
    return (
        <div className="rounded-[10px] flex text-main-purple px-2 py-1 font-semibold text-sm items-center bg-[#9C1AD410] w-fit h-fit">
            <img
                alt="plum_byte_icon_logo"
                src={plum_byte_icon_logo}
                className="w-4"
            />
            <div className="mx-[2px]">{byte}</div>
            <div className="">Byte</div>
            {date && <div className="ml-2">({date})</div>}
        </div>
    );
};
export default ByteBadge;
