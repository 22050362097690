import { FiTrash2 } from "react-icons/fi";

const DeleteBtn = ({ deletFunc }) => {
  return (
    <div className="relative group">
      <button className="p-1 hover:text-main-pink" onClick={deletFunc}>
        <FiTrash2 className="w-4.5 h-4.5" />
      </button>
      <span className="tooltip group-hover:opacity-100">삭제하기</span>
    </div>
  );
};

export default DeleteBtn;
