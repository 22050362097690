import * as React from "react";
import Tooltip from "@mui/material/Tooltip";

export default function BasicTooltip({
  title = "툴팁",
  placement = "top", //top top-end top-start bottom ...
  contents = <button>툴팁</button>,
}) {
  return (
    <Tooltip title={title} placement={placement}>
      <div>{contents}</div>
    </Tooltip>
  );
}
