// hooks
import { useState } from "react";
// icons
import { LuPencilLine } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
// modals
import AIModal from "pages/BehanceEditor/components/Modals/AIModal";

const HandleImageModal = ({ handleDelete, handleEditMedia }) => {
  const [isImageModalShow, setIsImageModalShowShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [hoverState, setHoverState] = useState(null);
  const handleClose = (e) => {
    e.stopPropagation();
    setIsShow(false);
  };
  const handleOpenAIModal = (e) => {
    e.stopPropagation();
    setIsImageModalShowShow(true);
    // setIsShow(false)
  };
  return (
    <div className="">
      <span
        onClick={(e) => {
          e.stopPropagation();
          setIsShow(true);
        }}
        className="absolute opacity-0 group-hover:opacity-100 bg-main-pink text-white left-[-1rem] top-[-1rem] rounded-full w-7 h-7 flex justify-center items-center cursor-pointer"
      >
        <LuPencilLine />
      </span>
      {isShow && (
        <div className="absolute top-[0.8rem] left-[-7.3rem]">
          <div
            className="fixed top-0 left-0 z-10 w-full h-full"
            onClick={(e) => handleClose(e)}
          ></div>
          <div
            onMouseLeave={() => setHoverState(null)}
            className="absolute z-30 p-1 text-xs border rounded-md w-28 bg-text-white border-main-pink"
          >
            <AIModal
              isShow={isImageModalShow}
              setIsShow={setIsImageModalShowShow}
              modalBtn={
                <button
                  onMouseOver={() => setHoverState("rewrite")}
                  onClick={handleOpenAIModal}
                  className="flex items-center justify-between px-2 py-1 text-gray-gray hover:text-black hover:bg-back-lGray"
                >
                  <div className="mr-1">이미지 편집</div>
                  <LuPencilLine
                    className={hoverState === "rewrite" && "text-main-pink"}
                  />
                </button>
              }
              handleEditMedia={handleEditMedia}
            />

            <button
              onMouseOver={() => setHoverState("delete")}
              onClick={handleDelete}
              className="flex items-center justify-between px-2 py-1 text-gray-gray group hover:text-black hover:bg-back-lGray"
            >
              <div className="mr-1">이미지 삭제</div>
              <span>
                <RiDeleteBin6Line
                  className={hoverState === "delete" && "text-main-pink"}
                />
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HandleImageModal;
