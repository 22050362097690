import EditBtn from "components/Buttons/EditBtn";
import DeleteBtn from "components/Buttons/DeleteBtn";
import { useState } from "react";
import {
  useRequestPatch,
  useRequestGet,
  useRequestDelete,
} from "hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import PrimaryBtn from "components/Buttons/PrimaryBtn";
import DefaultBtnSm from "components/Buttons/DefaultBtn";
import DeleteModal from "components/Modals/DeleteModal";

const CommentList = ({
  userName,
  feedbackCommentBody,
  commentId,
  userSeq,
  modifiedAt,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(feedbackCommentBody);
  const patchComment = useRequestPatch();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // 각 댓글별로 상태 유지
  let getUserInfo = useRequestGet("/api/user", "userInfo");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedComment(feedbackCommentBody);
  };

  const handleSaveEdit = () => {
    patchComment.mutate(
      {
        requestUrl: `/api/feedback/comment/${commentId}`,
        patchData: { feedbackCommentBody: editedComment },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getFeedComment");
          setIsEditing(false);
        },
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSaveEdit(e);
    }
  };

  const deleteFeedCommentMutation = useRequestDelete([
    "getFeedComment",
    commentId,
  ]);

  const handleDelete = () => {
    deleteFeedCommentMutation.mutate(
      {
        url: `/api/feedback/comment/${commentId}`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getFeedComment");
          setIsDeleteModalOpen(false); // 삭제 성공 시 모달 닫기
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true); // 삭제 모달 열기
  };

  const handleDeleteConfirm = () => {
    handleDelete(); // 삭제 확인 시 실제 삭제 실행
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // 모달 닫기
  };

  return (
    <div className="flex border-b p-4">
      <div className=" w-full">
        <div>
          {isEditing ? (
            <input
              type="text"
              value={editedComment}
              onKeyDown={handleKeyDown}
              onChange={(e) => setEditedComment(e.target.value)}
              className="w-full py-1 px-2 rounded-md focus:outline-main-red "
            />
          ) : (
            <>
              <div className="mb-2 text-md">{userName}</div>
              <div className="mb-2 text-base text-text-dGray">
                {feedbackCommentBody}
              </div>
              <div className="text-sm text-text-gray">{modifiedAt}</div>
            </>
          )}
        </div>
      </div>
      {userSeq === getUserInfo?.data?.data?.seq ? (
        <div className="flex gap-1 ml-2">
          {isEditing ? (
            <div className="flex gap-2 w-[147px]">
              <PrimaryBtn
                type="button"
                content="수정취소"
                btnClickFunc={handleCancelEdit}
              />
              <DefaultBtnSm
                type="submit"
                content="수정하기"
                btnClickFunc={handleSaveEdit}
              />
            </div>
          ) : (
            <div className="flex text-gray-gray items-center">
              <EditBtn editFunc={handleEditClick} />
              <DeleteBtn deletFunc={handleDeleteClick} />
            </div>
          )}
        </div>
      ) : null}
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="댓글을 삭제하시겠습니까?"
        />
      )}
    </div>
  );
};

export default CommentList;
