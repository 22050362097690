import { templateQuestions } from 'utils/TemplateQuestions';
import { templateIconMatcher } from 'utils/TemplateIconMatcher';
import { useNavigate } from 'react-router';
const LandingRecommand = () => {
    const navigate = useNavigate();
    return (
        <div className="mb-10 slide-container">
            <div className="slide-track">
                {/* <div className="grid grid-cols-6 gap-4"> */}
                {templateQuestions.map((el, idx) => (
                    <div
                        key={idx}
                        className="flex-shrink-0 flex-grow-0 w-[16rem] drop-shadow-sm m-2 bg-white items-center flex rounded-xl p-3 text-sm"
                    >
                        <div className="w-10 h-10  bg-[#F3395615] rounded-lg mr-2">
                            <img
                                alt={el.templateEN}
                                src={templateIconMatcher[el.templateEN]}
                            />
                        </div>
                        <div>
                            <div className="font-semibold ">{el.templateKR}</div>
                            <div className="text-xs">{el.description}</div>
                        </div>
                    </div>
                ))}
                {templateQuestions.map((el, idx) => (
                    <div
                        key={idx}
                        className="flex-shrink-0 flex-grow-0 w-[16rem] drop-shadow-sm m-2 bg-white items-center flex rounded-xl p-3 text-sm"
                        onClick={() => navigate('/login')}
                    >
                        <div className="w-10 h-10 bg-[#F3395615] rounded-lg mr-2">
                            <img
                                alt={el.templateEN}
                                src={templateIconMatcher[el.templateEN]}
                            />
                        </div>
                        <div>
                            <div className="font-semibold ">{el.templateKR}</div>
                            <div className="text-xs">{el.description}</div>
                        </div>
                    </div>
                ))}

                {/* </div> */}
            </div>
        </div>
    );
};

export default LandingRecommand;
