import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import TabButton from "components/Tap/TapButton";
import BrandSettings from "./components/BrandSettings";
import TargetSettings from "./components/TargetSettings";

const Setting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("brand");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`?tab=${tab}`); // URL 쿼리 스트링을 업데이트
  };

  return (
    <div className="pb-10 wrapper">
      <div className="cherry-wrapper">
        <div className="flex items-center">
          <div className="text-xl font-bold">사전설정</div>
          <div className="bg-gray-sGray p-1 rounded-xl w-fit mt-1 m-auto">
            <TabButton
              name="브랜드 설정"
              isActive={activeTab === "brand"}
              onClick={() => handleTabChange("brand")}
            />
            <TabButton
              name="타겟 설정"
              isActive={activeTab === "target"}
              onClick={() => handleTabChange("target")}
            />
          </div>
        </div>

        <div className="max-w-[1143px] m-auto">
          {activeTab === "brand" && <BrandSettings />}
          {activeTab === "target" && <TargetSettings />}
        </div>
      </div>
    </div>
  );
};

export default Setting;
