import React, { useState } from "react";
import BasicRadio from "components/Radio/BasicRadio";

const PaymentHelpModal = ({ onSubmit }) => {
  const [customReason, setCustomReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const reasons = [
    {
      label: "환불 문의",
      value: "환불 문의",
    },
    { label: "정기 결제 문의", value: "정기 결제 문의" },
    { label: "기타문의", value: "기타문의" },
  ];

  const handleRadioChange = (value) => {
    setSelectedReason(value);
  };

  const handleCustomReasonChange = (e) => {
    setCustomReason(e.target.value);
  };

  const handleSubmit = () => {
    const reason =
      selectedReason === "기타문의" ? customReason : selectedReason;
    onSubmit(reason);
  };

  return (
    <div className="mt-2 ">
      <div className="space-y-2 text-sm ">
        <BasicRadio
          name="deletionReason"
          options={reasons}
          defaultCheckOption={selectedReason}
          onChange={handleRadioChange}
        />
        {selectedReason === "기타문의" && (
          <textarea
            className="bg-white focus:outline-1  focus:outline-main-pink w-full py-2.5 px-4 rounded-lg resize-none placeholder:text-text-dGray"
            placeholder="내용 입력"
            value={customReason}
            onChange={handleCustomReasonChange}
            rows={3}
          />
        )}
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="text-white bg-main-red p-1 rounded-md text-sm text-center w-[250px]"
          onClick={handleSubmit}
        >
          결제 문의
        </button>
      </div>
    </div>
  );
};

export default PaymentHelpModal;
