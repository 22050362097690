/******************** 템플릿 생성 ******************/

export const templateQuestions = [
  {
    templateEN: "instagram",
    templateKR: "인스타그램",
    tag: "SNS",
    description: "제품도 일상도 매력적으로",
    info: "제품도 일상도 매력적으로",

    firstVariable: "게시글 주제",
    secondVariable: "캡션의 핵심 키워드",
    thirdVariable: "반드시 들어가야 할 내용",
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: true,
    toneCode: true,
    languageCode: true,
    objectiveCode: true,
    brandSeq: true,
  },
  {
    templateEN: "naverBlog",
    templateKR: "N블로그",
    description: "기록하고 공유하세요",
    info: "기록하고 공유하세요",
    tag: "SNS",

    firstVariable: "게시글 주제",
    secondVariable: "블로그 이름",
    thirdVariable: "게시글의 핵심 키워드",
    fourthVariable: "게시글의 제목",
    fifthVariable: null,

    targetSeq: true,
    productCategory: true,
    toneCode: true,
    languageCode: true,
    objectiveCode: true,
    brandSeq: false,
  },
  {
    templateEN: "tStory",
    templateKR: "T블로그",
    description: "세상과 소통하세요",
    info: "세상과 소통하세요",
    tag: "SNS",

    firstVariable: "게시글 주제",
    secondVariable: "블로그 이름",
    thirdVariable: "게시글의 핵심 키워드",
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: true,
    toneCode: true,
    languageCode: true,
    objectiveCode: true,
    brandSeq: false,
  },
  {
    templateEN: "linkedinProfile",
    templateKR: "링크드인 프로필",
    description: "선명한 나의 프로필",
    info: "선명한 나의 프로필",
    tag: "SNS",

    firstVariable: "소개",
    secondVariable: "키워드",
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "ImageStudio",
    templateKR: "이미지 스튜디오",
    description: "원하는 이미지를 자유롭게",
    info: "원하는 이미지를 자유롭게",
    tag: "SNS",

    firstVariable: "이미지 설명",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: false,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "diary",
    templateKR: "일기",
    description: "오늘의 나",
    info: "오늘의 나",
    tag: "글쓰기",

    firstVariable: "오늘 있었던 일",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "dreamDiary",
    templateKR: "꿈일기",
    description: "어젯밤의 나",
    info: "어젯밤의 나",
    tag: "글쓰기",

    firstVariable: "꿈의 내용",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  // 반성문
  {
    templateEN: "reflectionLetter",
    templateKR: "반성문",
    description: "성찰에서 성장으로",
    info: "성찰에서 성장으로",
    tag: "글쓰기",

    firstVariable: "잘못을 저지른 주제",
    secondVariable: "잘못된 행동",
    thirdVariable: "잘못된 날짜",
    fourthVariable: "개선 방향",
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "dreamInterpretation",
    templateKR: "어제 본 꿈 이야기",
    description: "플럼바이트가 해석해드려요",
    info: "플럼바이트가 해석해드려요",
    tag: "무료",

    firstVariable: "꿈의 내용",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: false,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "toast",
    templateKR: "건배사",
    description: "회식자리에서 빛나는 당신을 위해",
    info: "회식자리에서 빛나는 당신을 위해",
    tag: "글쓰기",

    firstVariable: "사용자 입력",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: false,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "interviewQeustionsAnswers",
    templateKR: "면접 예상 질문과 답변",
    description: "성공적인 면접의 첫걸음",
    info: "성공적인 면접의 첫걸음",
    tag: "글쓰기",

    firstVariable: "회사 업종",
    secondVariable: "지원 분야",
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: false,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "copyWritingIdea",
    templateKR: "카피라이팅 아이디어",
    description: "기억에 남는 한 줄을 위해",
    info: "기억에 남는 한 줄을 위해",
    tag: "마케팅",

    firstVariable: "카피라이팅의 핵심 키워드",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "improvedAIDAModelAD",
    templateKR: "효과적인 광고 전략",
    description: "개선된 AIDA모델",
    info: "개선된 AIDA모델",
    tag: "마케팅",

    firstVariable: "광고의 핵심 키워드",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "productDetail",
    templateKR: "제품 상세페이지",
    description: "판매를 촉진하는 완벽한 설명",
    info: "판매를 촉진하는 완벽한 설명",
    tag: "마케팅",

    firstVariable: "상세 페이지의 핵심 키워드",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "googleAD",
    templateKR: "구글 광고",
    description: "클릭률을 높이는 캠페인",
    info: "클릭률을 높이는 캠페인",
    tag: "마케팅",

    firstVariable: "광고 목적",
    secondVariable: "광고 내용",
    thirdVariable: "셀링 포인트",
    fourthVariable: "광고 핵심 키워드",
    fifthVariable: "웹 사이트 주소",

    targetSeq: true,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "marketingPlanDraft",
    templateKR: "마케팅 기획서 초안",
    description: "목표 달성을 위한 완벽한 시작",
    info: "목표 달성을 위한 완벽한 시작",
    tag: "마케팅",

    firstVariable: "기획서 목표",
    secondVariable: "기획서 내용",
    thirdVariable: "기획서 핵심 키워드",
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "expressingMoodWithColor",
    templateKR: "감정 팔레트",
    description: "컬러카드를 나눠드려요",
    info: "컬러카드를 나눠드려요",
    tag: "무료",

    firstVariable: "오늘의 감정 혹은 기분",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: false,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "businessPlanDraft",
    templateKR: "사업 계획서 초안",
    description: "아이디어를 현실로",
    info: "아이디어를 현실로",
    tag: "마케팅",

    firstVariable: "계획서의 핵심 키워드",
    secondVariable: "계획서의 언어",
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "appPushMessage",
    templateKR: "앱 푸시 메시지",
    description: "넌 이미 클릭하고 있다",
    info: "넌 이미 클릭하고 있다",
    tag: "마케팅",

    firstVariable: "메시지의 목적",
    secondVariable: "메시지의 내용",
    thirdVariable: "메시지 핵심 키워드",
    fourthVariable: "CTA 추가",
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "pressRelease",
    templateKR: "보도 자료",
    description: "신뢰받는 보도자료 작성의 시작",
    info: "신뢰받는 보도자료 작성의 시작",
    tag: "마케팅",

    firstVariable: "보도자료 발행자",
    secondVariable: "발행 날짜 및 위치",
    thirdVariable: "보도자료의 핵심 키워드",
    fourthVariable: "CTA 추가",
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: true,
  },
  {
    templateEN: "rewriteArticle",
    templateKR: "기사 다시 쓰기",
    description: "이제 당신의 시선을 곁들인",
    info: "이제 당신의 시선을 곁들인",
    tag: "마케팅",

    firstVariable: "새롭게 쓸 기사",
    secondVariable: null,
    thirdVariable: null,
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: true,
    productCategory: false,
    toneCode: true,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "notice",
    templateKR: "공지사항",
    description: "저희도 씁니다..",
    info: "저희도 씁니다..",
    tag: "계획",

    firstVariable: "담당자 이름",
    secondVariable: "공지 유형",
    thirdVariable: "공지사항의 주요 내용",
    fourthVariable: "적용 날짜 및 시간",
    fifthVariable: "사용자에게 미치는 영향",

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
  {
    templateEN: "travelSchedule",
    templateKR: "여행일정",
    description: "계획에 지친 J를 위해",
    info: "계획에 지친 J를 위해",
    tag: "계획",

    firstVariable: "여행지",
    secondVariable: "여행 날짜 혹은 일정",
    thirdVariable: "여행 주제",
    fourthVariable: null,
    fifthVariable: null,

    targetSeq: false,
    productCategory: false,
    toneCode: false,
    languageCode: true,
    objectiveCode: false,
    brandSeq: false,
  },
];

/******************** 게시글 목표 코드 ******************/
export const objectiveCode = {
  blog: [
    {
      cp_objective_seq: 1,
      objective_code: "O001",
      objective_description_eng: "Information provision",
      objective_description_kor: "정보 제공",
      objective_type: "BLOG_POST",
    },
    {
      cp_objective_seq: 2,
      objective_code: "O002",
      objective_description_eng: "Opinion sharing",
      objective_description_kor: "의견 공유",
      objective_type: "BLOG_POST",
    },
    {
      cp_objective_seq: 3,
      objective_code: "O003",
      objective_description_eng:
        "Providing reviews and evaluations of products or places",
      objective_description_kor: "제품이나 장소에 대한 후기 및 평가 제공",
      objective_type: "BLOG_POST",
    },
    {
      cp_objective_seq: 4,
      objective_code: "O004",
      objective_description_eng: "Enhancing brand awareness",
      objective_description_kor: "브랜드 인지도 향상",
      objective_type: "BLOG_POST",
    },
    {
      cp_objective_seq: 5,
      objective_code: "O005",
      objective_description_eng: "Promoting products or services",
      objective_description_kor: "제품이나 서비스 홍보",
      objective_type: "BLOG_POST",
    },
  ],

  instagram: [
    {
      cp_objective_seq: 6,
      objective_code: "O101",
      objective_description_eng: "Increasing followers",
      objective_description_kor: "팔로워 증가",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 7,
      objective_code: "O102",
      objective_description_eng: "Encouraging customer engagement",
      objective_description_kor: "고객 참여 유도",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 8,
      objective_code: "O103",
      objective_description_eng: "Enhancing brand recognition",
      objective_description_kor: "브랜드 인지도 향상",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 9,
      objective_code: "O104",
      objective_description_eng: "Boosting sales of products or services",
      objective_description_kor: "정보 제공",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 10,
      objective_code: "O105",
      objective_description_eng: "Increasing website traffic",
      objective_description_kor: "웹사이트 트래픽 증가",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 11,
      objective_code: "O106",
      objective_description_eng: "Conveying information",
      objective_description_kor: "정보 전달",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 12,
      objective_code: "O107",
      objective_description_eng: "Collecting customer feedback",
      objective_description_kor: "고객 피드백 수집",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 13,
      objective_code: "O108",
      objective_description_eng: "Promoting events or campaigns",
      objective_description_kor: "이벤트나 캠페인 홍보",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 14,
      objective_code: "O109",
      objective_description_eng: "Improving brand image and perception",
      objective_description_kor: "브랜드 이미지 및 인식개선",
      objective_type: "INSTAGRAM_CAPTION",
    },
    {
      cp_objective_seq: 15,
      objective_code: "O110",
      objective_description_eng: "Emphasizing partnerships and collaborations",
      objective_description_kor: "파트너십 및 협업 강조",
      objective_type: "INSTAGRAM_CAPTION",
    },
  ],
};

/******************** 톤 코드 ******************/
export const toneCode = {
  all: [
    {
      cp_tone_seq: 1,
      tone_code: "T001",
      tone_name_eng: "Friendly",
      tone_name_kor: "친근한",
    },
    {
      cp_tone_seq: 2,
      tone_code: "T002",
      tone_name_eng: "Professional",
      tone_name_kor: "전문적인",
    },
    {
      cp_tone_seq: 3,
      tone_code: "T003",
      tone_name_eng: "Casual",
      tone_name_kor: "편안한",
    },
    {
      cp_tone_seq: 4,
      tone_code: "T004",
      tone_name_eng: "Polite",
      tone_name_kor: "정중한",
    },
    {
      cp_tone_seq: 5,
      tone_code: "T005",
      tone_name_eng: "Emotional",
      tone_name_kor: "감성적인",
    },
    {
      cp_tone_seq: 6,
      tone_code: "T006",
      tone_name_eng: "Persuasive",
      tone_name_kor: "설득적인",
    },
    {
      cp_tone_seq: 7,
      tone_code: "T007",
      tone_name_eng: "Informative",
      tone_name_kor: "정보적인",
    },
    {
      cp_tone_seq: 8,
      tone_code: "T008",
      tone_name_eng: "Humorous",
      tone_name_kor: "유머러스한",
    },
    {
      cp_tone_seq: 9,
      tone_code: "T009",
      tone_name_eng: "Witty",
      tone_name_kor: "재치있는",
    },
    {
      cp_tone_seq: 10,
      tone_code: "T010",
      tone_name_eng: "Serious",
      tone_name_kor: "진지한",
    },
    {
      cp_tone_seq: 11,
      tone_code: "T011",
      tone_name_eng: "Motivational",
      tone_name_kor: "동기부여가 되는",
    },
    {
      cp_tone_seq: 12,
      tone_code: "T012",
      tone_name_eng: "Authoritative",
      tone_name_kor: "권위적은",
    },
    {
      cp_tone_seq: 13,
      tone_code: "T013",
      tone_name_eng: "Inspirational",
      tone_name_kor: "영감을 주는",
    },
  ],
  article: [
    {
      cp_tone_seq: 14,
      tone_code: "T101",
      tone_name_eng: "Neutral",
      tone_name_kor: "중립적인",
    },
    {
      cp_tone_seq: 15,
      tone_code: "T102",
      tone_name_eng: "Critical",
      tone_name_kor: "비판적인",
    },
    {
      cp_tone_seq: 16,
      tone_code: "T103",
      tone_name_eng: "Investigative",
      tone_name_kor: "탐사적인",
    },
    {
      cp_tone_seq: 17,
      tone_code: "T104",
      tone_name_eng: "Reportorial",
      tone_name_kor: "보고적인",
    },
    {
      cp_tone_seq: 18,
      tone_code: "T105",
      tone_name_eng: "Explanatory",
      tone_name_kor: "설명적인",
    },
    {
      cp_tone_seq: 19,
      tone_code: "T106",
      tone_name_eng: "Advocacy",
      tone_name_kor: "옹호적인",
    },
    {
      cp_tone_seq: 20,
      tone_code: "T107",
      tone_name_eng: "Analytical",
      tone_name_kor: "분석적인",
    },
    {
      cp_tone_seq: 21,
      tone_code: "T108",
      tone_name_eng: "Predictive",
      tone_name_kor: "예측적인",
    },
    {
      cp_tone_seq: 22,
      tone_code: "T109",
      tone_name_eng: "Opinionated",
      tone_name_kor: "개인적인 관점을 제공하는",
    },
    {
      cp_tone_seq: 23,
      tone_code: "T110",
      tone_name_eng: "Fact-based",
      tone_name_kor: "사실 위주의",
    },
  ],
};

/******************** 타겟 관심사 코드 ******************/
export const targetInterestCode = [
  {
    cp_target_interests_seq: 1,
    target_interests_code: "I01",
    target_interests_name_eng: "Health & Wellness",
    target_interests_name_kor: "건강 및 웰빙",
  },
  {
    cp_target_interests_seq: 2,
    target_interests_code: "I02",
    target_interests_name_eng: "Online Education",
    target_interests_name_kor: "온라인 교육",
  },
  {
    cp_target_interests_seq: 3,
    target_interests_code: "I03",
    target_interests_name_eng: "Environment & Sustainability",
    target_interests_name_kor: "환경 및 지속 가능성",
  },
  {
    cp_target_interests_seq: 4,
    target_interests_code: "I04",
    target_interests_name_eng: "Technology & Gadgets",
    target_interests_name_kor: "기술 및 가젯",
  },
  {
    cp_target_interests_seq: 5,
    target_interests_code: "I05",
    target_interests_name_eng: "Travel & Exploration",
    target_interests_name_kor: "여행 및 탐험",
  },
  {
    cp_target_interests_seq: 6,
    target_interests_code: "I06",
    target_interests_name_eng: "Financial Management & Investment",
    target_interests_name_kor: "금융 관리 및 투자",
  },
  {
    cp_target_interests_seq: 7,
    target_interests_code: "I07",
    target_interests_name_eng: "Music & Entertainment",
    target_interests_name_kor: "음악 및 엔터테인먼트",
  },
  {
    cp_target_interests_seq: 8,
    target_interests_code: "I08",
    target_interests_name_eng: "Fashion & Beauty",
    target_interests_name_kor: "패션 및 뷰티",
  },
  {
    cp_target_interests_seq: 9,
    target_interests_code: "I09",
    target_interests_name_eng: "Cooking & Food and Beverage",
    target_interests_name_kor: "요리 및 식음료",
  },
  {
    cp_target_interests_seq: 10,
    target_interests_code: "I10",
    target_interests_name_eng: "Sports & Outdoor Activities",
    target_interests_name_kor: "스포츠 및 야외활동",
  },
];

/******************** 국가 언어 코드 ******************/
export const languageCode = [
  {
    cp_language_seq: 1,
    language_code: "KR",
    language_name_eng: "Korean",
    language_name_kor: "한국어",
  },
  {
    cp_language_seq: 2,
    language_code: "US",
    language_name_eng: "English",
    language_name_kor: "영어",
  },
  {
    cp_language_seq: 3,
    language_code: "JP",
    language_name_eng: "Japanese",
    language_name_kor: "일본어",
  },
  {
    cp_language_seq: 4,
    language_code: "CN",
    language_name_eng: "Mandarin",
    language_name_kor: "중국어",
  },
];

/******************** 템플릿 코드 ******************/ //길이가 1보다 클 경우 드롭다운으로 선택하게 하면 될듯?
export const templateCode = {
  landing: [
    {
      cp_template_seq: 1,
      template_code: "C01",
      instruction: "이미지생성",
      template_name: "랜딩페이지 이미지 프롬프트 생성",
      template_plum: 0,
    },
    {
      cp_template_seq: 2,
      template_code: "C02",
      instruction: "블로그 본문 생성",
      template_name: "랜딩페이지 프롬프트",
      template_plum: 0,
    },
  ],
  editor: [
    {
      cp_template_seq: 3,
      template_code: "C03",
      instruction: "이미지 프롬프트 생성",
      template_name: "이미지 프롬프트 생성",
      template_plum: 2,
    },
    {
      cp_template_seq: 4,
      template_code: "C04",
      instruction: "이미지 프롬프트 생성",
      template_name: "이미지 프롬프트 생성_게시글과 함께",
      template_plum: 1,
    },
    {
      cp_template_seq: 5,
      template_code: "C05",
      instruction: "글 다시쓰기",
      template_name: "글 다시쓰기",
      template_plum: 1,
    },
    {
      cp_template_seq: 6,
      template_code: "C06",
      instruction: "문단 사이 글 생성하기",
      template_name: "문단 사이 글 생성하기",
      template_plum: 1,
    },
  ],
  instagram: [
    {
      cp_template_seq: 7,
      template_code: "C101",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "패션",
      template_plum: 3,
    },
    {
      cp_template_seq: 8,
      template_code: "C102",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "뷰티",
      template_plum: 3,
    },
    {
      cp_template_seq: 9,
      template_code: "C103",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "여행",
      template_plum: 3,
    },
    {
      cp_template_seq: 10,
      template_code: "C104",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "음식",
      template_plum: 3,
    },
    {
      cp_template_seq: 11,
      template_code: "C105",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "예술",
      template_plum: 3,
    },
    {
      cp_template_seq: 12,
      template_code: "C106",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "반려동물",
      template_plum: 3,
    },
    {
      cp_template_seq: 13,
      template_code: "C107",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "자연",
      template_plum: 3,
    },
    {
      cp_template_seq: 14,
      template_code: "C108",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "건강",
      template_plum: 3,
    },
    {
      cp_template_seq: 15,
      template_code: "C109",
      instruction: "인스타그램 캡션 생성하기",
      template_name: "엔터테인먼트",
      template_plum: 3,
    },
  ],
  nBlogTitle: [
    {
      cp_template_seq: 16,
      template_code: "C201",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_문학·책",
      template_plum: 0,
    },
    {
      cp_template_seq: 17,
      template_code: "C202",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_영화",
      template_plum: 0,
    },
    {
      cp_template_seq: 18,
      template_code: "C203",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_미술·디자인",
      template_plum: 0,
    },
    {
      cp_template_seq: 19,
      template_code: "C204",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_공연·전시",
      template_plum: 0,
    },
    {
      cp_template_seq: 20,
      template_code: "C205",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_음악",
      template_plum: 0,
    },
    {
      cp_template_seq: 21,
      template_code: "C206",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_드라마",
      template_plum: 0,
    },
    {
      cp_template_seq: 22,
      template_code: "C207",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_스타·연예인",
      template_plum: 0,
    },
    {
      cp_template_seq: 23,
      template_code: "C208",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_만화·애니",
      template_plum: 0,
    },
    {
      cp_template_seq: 24,
      template_code: "C209",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_방송",
      template_plum: 0,
    },
    {
      cp_template_seq: 25,
      template_code: "C210",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_일상·생각",
      template_plum: 0,
    },
    {
      cp_template_seq: 26,
      template_code: "C211",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_육아·결혼",
      template_plum: 0,
    },
    {
      cp_template_seq: 27,
      template_code: "C212",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그_제목_반려동물",
      template_plum: 0,
    },
    {
      cp_template_seq: 28,
      template_code: "C213",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 네이버 블로그_제목_좋은글·이미지",
      template_plum: 0,
    },
    {
      cp_template_seq: 29,
      template_code: "C214",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_패션·미용",
      template_plum: 0,
    },
    {
      cp_template_seq: 30,
      template_code: "C215",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_인테리어·DIY",
      template_plum: 0,
    },
    {
      cp_template_seq: 31,
      template_code: "C216",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_요리·레시피",
      template_plum: 0,
    },
    {
      cp_template_seq: 32,
      template_code: "C217",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_상품리뷰",
      template_plum: 0,
    },
    {
      cp_template_seq: 33,
      template_code: "C218",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_원예·재배",
      template_plum: 0,
    },
    {
      cp_template_seq: 34,
      template_code: "C219",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_게임",
      template_plum: 0,
    },
    {
      cp_template_seq: 35,
      template_code: "C220",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_스포츠",
      template_plum: 0,
    },
    {
      cp_template_seq: 36,
      template_code: "C221",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_사진",
      template_plum: 0,
    },
    {
      cp_template_seq: 37,
      template_code: "C222",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_자동차",
      template_plum: 0,
    },
    {
      cp_template_seq: 38,
      template_code: "C223",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_취미",
      template_plum: 0,
    },
    {
      cp_template_seq: 39,
      template_code: "C224",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_국내여행",
      template_plum: 0,
    },
    {
      cp_template_seq: 40,
      template_code: "C225",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_세계여행",
      template_plum: 0,
    },
    {
      cp_template_seq: 41,
      template_code: "C226",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_맛집",
      template_plum: 0,
    },
    {
      cp_template_seq: 42,
      template_code: "C227",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_IT·컴퓨터",
      template_plum: 0,
    },
    {
      cp_template_seq: 43,
      template_code: "C228",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_건강·의학",
      template_plum: 0,
    },
    {
      cp_template_seq: 44,
      template_code: "C229",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_비즈니스·경제",
      template_plum: 0,
    },
    {
      cp_template_seq: 45,
      template_code: "C230",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_어학·외국어",
      template_plum: 0,
    },
    {
      cp_template_seq: 46,
      template_code: "C231",
      instruction: "네이버 블로그 제목",
      template_name: "네이버 블로그 제목_교육·학문",
      template_plum: 0,
    },
  ],
  nBlogBody: [
    {
      cp_template_seq: 47,
      template_code: "C301",
      instruction: "네이버 블로그 본문",
      template_name: "문학·책",
      template_plum: 3,
    },
    {
      cp_template_seq: 48,
      template_code: "C302",
      instruction: "네이버 블로그 본문",
      template_name: "영화",
      template_plum: 3,
    },
    {
      cp_template_seq: 49,
      template_code: "C303",
      instruction: "네이버 블로그 본문",
      template_name: "미술·디자인",
      template_plum: 3,
    },
    {
      cp_template_seq: 50,
      template_code: "C304",
      instruction: "네이버 블로그 본문",
      template_name: "공연·전시",
      template_plum: 3,
    },
    {
      cp_template_seq: 51,
      template_code: "C305",
      instruction: "네이버 블로그 본문",
      template_name: "음악",
      template_plum: 3,
    },
    {
      cp_template_seq: 52,
      template_code: "C306",
      instruction: "네이버 블로그 본문",
      template_name: "드라마",
      template_plum: 3,
    },
    {
      cp_template_seq: 53,
      template_code: "C307",
      instruction: "네이버 블로그 본문",
      template_name: "스타·연예인",
      template_plum: 3,
    },
    {
      cp_template_seq: 54,
      template_code: "C308",
      instruction: "네이버 블로그 본문",
      template_name: "만화·애니",
      template_plum: 3,
    },
    {
      cp_template_seq: 55,
      template_code: "C309",
      instruction: "네이버 블로그 본문",
      template_name: "방송",
      template_plum: 3,
    },
    {
      cp_template_seq: 56,
      template_code: "C310",
      instruction: "네이버 블로그 본문",
      template_name: "일상·생각",
      template_plum: 3,
    },
    {
      cp_template_seq: 57,
      template_code: "C311",
      instruction: "네이버 블로그 본문",
      template_name: "육아·결혼",
      template_plum: 3,
    },
    {
      cp_template_seq: 58,
      template_code: "C312",
      instruction: "네이버 블로그 본문",
      template_name: "반려동물",
      template_plum: 3,
    },
    {
      cp_template_seq: 59,
      template_code: "C313",
      instruction: "네이버 블로그 본문",
      template_name: "좋은글·이미지",
      template_plum: 3,
    },
    {
      cp_template_seq: 60,
      template_code: "C314",
      instruction: "네이버 블로그 본문",
      template_name: "패션·미용",
      template_plum: 3,
    },
    {
      cp_template_seq: 61,
      template_code: "C315",
      instruction: "네이버 블로그 본문",
      template_name: "인테리어·DIY",
      template_plum: 3,
    },
    {
      cp_template_seq: 62,
      template_code: "C316",
      instruction: "네이버 블로그 본문",
      template_name: "요리·레시피",
      template_plum: 3,
    },
    {
      cp_template_seq: 63,
      template_code: "C317",
      instruction: "네이버 블로그 본문",
      template_name: "상품리뷰",
      template_plum: 3,
    },
    {
      cp_template_seq: 64,
      template_code: "C318",
      instruction: "네이버 블로그 본문",
      template_name: "원예·재배",
      template_plum: 3,
    },
    {
      cp_template_seq: 65,
      template_code: "C319",
      instruction: "네이버 블로그 본문",
      template_name: "게임",
      template_plum: 3,
    },
    {
      cp_template_seq: 66,
      template_code: "C320",
      instruction: "네이버 블로그 본문",
      template_name: "스포츠",
      template_plum: 3,
    },
    {
      cp_template_seq: 67,
      template_code: "C321",
      instruction: "네이버 블로그 본문",
      template_name: "사진",
      template_plum: 3,
    },
    {
      cp_template_seq: 68,
      template_code: "C322",
      instruction: "네이버 블로그 본문",
      template_name: "자동차",
      template_plum: 3,
    },
    {
      cp_template_seq: 69,
      template_code: "C323",
      instruction: "네이버 블로그 본문",
      template_name: "취미",
      template_plum: 3,
    },
    {
      cp_template_seq: 70,
      template_code: "C324",
      instruction: "네이버 블로그 본문",
      template_name: "국내여행",
      template_plum: 3,
    },
    {
      cp_template_seq: 71,
      template_code: "C325",
      instruction: "네이버 블로그 본문",
      template_name: "세계여행",
      template_plum: 3,
    },
    {
      cp_template_seq: 72,
      template_code: "C326",
      instruction: "네이버 블로그 본문",
      template_name: "맛집",
      template_plum: 3,
    },
    {
      cp_template_seq: 73,
      template_code: "C327",
      instruction: "네이버 블로그 본문",
      template_name: "IT·컴퓨터",
      template_plum: 3,
    },
    {
      cp_template_seq: 74,
      template_code: "C328",
      instruction: "네이버 블로그 본문",
      template_name: "건강·의학",
      template_plum: 3,
    },
    {
      cp_template_seq: 75,
      template_code: "C329",
      instruction: "네이버 블로그 본문",
      template_name: "비즈니스·경제",
      template_plum: 3,
    },
    {
      cp_template_seq: 76,
      template_code: "C330",
      instruction: "네이버 블로그 본문",
      template_name: "어학·외국어",
      template_plum: 3,
    },
    {
      cp_template_seq: 77,
      template_code: "C331",
      instruction: "네이버 블로그 본문",
      template_name: "교육·학문",
      template_plum: 3,
    },
  ],
  tBlogIndex: [
    {
      cp_template_seq: 78,
      template_code: "C400",
      instruction: "티스토리 목차 생성",
      template_name: "티스토리 목차 생성",
      template_plum: 0,
    },
  ],
  tBlogTitle: [
    {
      cp_template_seq: 79,
      template_code: "C401",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_일상다반사",
      template_plum: 0,
    },
    {
      cp_template_seq: 80,
      template_code: "C402",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_해외생활",
      template_plum: 0,
    },
    {
      cp_template_seq: 81,
      template_code: "C403",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_연애·결혼",
      template_plum: 0,
    },
    {
      cp_template_seq: 82,
      template_code: "C404",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_육아",
      template_plum: 0,
    },
    {
      cp_template_seq: 83,
      template_code: "C405",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_생활정보",
      template_plum: 0,
    },
    {
      cp_template_seq: 84,
      template_code: "C406",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_반려동물",
      template_plum: 0,
    },
    {
      cp_template_seq: 85,
      template_code: "C407",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_취미",
      template_plum: 0,
    },
    {
      cp_template_seq: 86,
      template_code: "C408",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_사진",
      template_plum: 0,
    },
    {
      cp_template_seq: 87,
      template_code: "C409",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_요리",
      template_plum: 0,
    },
    {
      cp_template_seq: 88,
      template_code: "C410",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_자동차",
      template_plum: 0,
    },
    {
      cp_template_seq: 89,
      template_code: "C411",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_인테리어",
      template_plum: 0,
    },
    {
      cp_template_seq: 90,
      template_code: "C412",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_패션뷰티",
      template_plum: 0,
    },
    {
      cp_template_seq: 91,
      template_code: "C413",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_건강",
      template_plum: 0,
    },
    {
      cp_template_seq: 92,
      template_code: "C414",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_국내여행",
      template_plum: 0,
    },
    {
      cp_template_seq: 93,
      template_code: "C415",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_해외여행",
      template_plum: 0,
    },
    {
      cp_template_seq: 94,
      template_code: "C416",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_캠핑·등산",
      template_plum: 0,
    },
    {
      cp_template_seq: 95,
      template_code: "C417",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_맛집",
      template_plum: 0,
    },
    {
      cp_template_seq: 96,
      template_code: "C418",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_카페·디저트",
      template_plum: 0,
    },
    {
      cp_template_seq: 97,
      template_code: "C419",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_TV",
      template_plum: 0,
    },
    {
      cp_template_seq: 98,
      template_code: "C420",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_스타",
      template_plum: 0,
    },
    {
      cp_template_seq: 99,
      template_code: "C421",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_영화",
      template_plum: 0,
    },
    {
      cp_template_seq: 100,
      template_code: "C422",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_음악",
      template_plum: 0,
    },
    {
      cp_template_seq: 101,
      template_code: "C423",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_책",
      template_plum: 0,
    },
    {
      cp_template_seq: 102,
      template_code: "C424",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_만화·애니",
      template_plum: 0,
    },
    {
      cp_template_seq: 103,
      template_code: "C425",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_공연·전시·축제",
      template_plum: 0,
    },
    {
      cp_template_seq: 104,
      template_code: "C426",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_창작",
      template_plum: 0,
    },
    {
      cp_template_seq: 105,
      template_code: "C427",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_IT 인터넷",
      template_plum: 0,
    },
    {
      cp_template_seq: 106,
      template_code: "C428",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_모바일",
      template_plum: 0,
    },
    {
      cp_template_seq: 107,
      template_code: "C429",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_게임",
      template_plum: 0,
    },
    {
      cp_template_seq: 108,
      template_code: "C430",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_과학",
      template_plum: 0,
    },
    {
      cp_template_seq: 109,
      template_code: "C431",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_IT 제품리뷰",
      template_plum: 0,
    },
    {
      cp_template_seq: 110,
      template_code: "C432",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_스포츠 일반",
      template_plum: 0,
    },
    {
      cp_template_seq: 111,
      template_code: "C433",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_축구",
      template_plum: 0,
    },
    {
      cp_template_seq: 112,
      template_code: "C434",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_야구",
      template_plum: 0,
    },
    {
      cp_template_seq: 113,
      template_code: "C435",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_농구",
      template_plum: 0,
    },
    {
      cp_template_seq: 114,
      template_code: "C436",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_배구",
      template_plum: 0,
    },
    {
      cp_template_seq: 115,
      template_code: "C437",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_골프",
      template_plum: 0,
    },
    {
      cp_template_seq: 116,
      template_code: "C438",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_사회",
      template_plum: 0,
    },
    {
      cp_template_seq: 117,
      template_code: "C439",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_교육",
      template_plum: 0,
    },
    {
      cp_template_seq: 118,
      template_code: "C440",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_국제",
      template_plum: 0,
    },
    {
      cp_template_seq: 119,
      template_code: "C441",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_경제",
      template_plum: 0,
    },
    {
      cp_template_seq: 120,
      template_code: "C442",
      instruction: "티스토리 제목",
      template_name: "티스토리 제목_경영·직장",
      template_plum: 0,
    },
  ],
  tBlogBody: [
    {
      cp_template_seq: 121,
      template_code: "C501",
      instruction: "티스토리 본문",
      template_name: "일상다반사",
      template_plum: 3,
    },
    {
      cp_template_seq: 122,
      template_code: "C502",
      instruction: "티스토리 본문",
      template_name: "해외생활",
      template_plum: 3,
    },
    {
      cp_template_seq: 123,
      template_code: "C503",
      instruction: "티스토리 본문",
      template_name: "연애·결혼",
      template_plum: 3,
    },
    {
      cp_template_seq: 124,
      template_code: "C504",
      instruction: "티스토리 본문",
      template_name: "육아",
      template_plum: 3,
    },
    {
      cp_template_seq: 125,
      template_code: "C505",
      instruction: "티스토리 본문",
      template_name: "생활정보",
      template_plum: 3,
    },
    {
      cp_template_seq: 126,
      template_code: "C506",
      instruction: "티스토리 본문",
      template_name: "반려동물",
      template_plum: 3,
    },
    {
      cp_template_seq: 127,
      template_code: "C507",
      instruction: "티스토리 본문",
      template_name: "취미",
      template_plum: 3,
    },
    {
      cp_template_seq: 128,
      template_code: "C508",
      instruction: "티스토리 본문",
      template_name: "사진",
      template_plum: 3,
    },
    {
      cp_template_seq: 129,
      template_code: "C509",
      instruction: "티스토리 본문",
      template_name: "요리",
      template_plum: 3,
    },
    {
      cp_template_seq: 130,
      template_code: "C510",
      instruction: "티스토리 본문",
      template_name: "자동차",
      template_plum: 3,
    },
    {
      cp_template_seq: 131,
      template_code: "C511",
      instruction: "티스토리 본문",
      template_name: "인테리어",
      template_plum: 3,
    },
    {
      cp_template_seq: 132,
      template_code: "C512",
      instruction: "티스토리 본문",
      template_name: "패션뷰티",
      template_plum: 3,
    },
    {
      cp_template_seq: 133,
      template_code: "C513",
      instruction: "티스토리 본문",
      template_name: "건강",
      template_plum: 3,
    },
    {
      cp_template_seq: 134,
      template_code: "C514",
      instruction: "티스토리 본문",
      template_name: "국내여행",
      template_plum: 3,
    },
    {
      cp_template_seq: 135,
      template_code: "C515",
      instruction: "티스토리 본문",
      template_name: "해외여행",
      template_plum: 3,
    },
    {
      cp_template_seq: 136,
      template_code: "C516",
      instruction: "티스토리 본문",
      template_name: "캠핑·등산",
      template_plum: 3,
    },
    {
      cp_template_seq: 137,
      template_code: "C517",
      instruction: "티스토리 본문",
      template_name: "맛집",
      template_plum: 3,
    },
    {
      cp_template_seq: 138,
      template_code: "C518",
      instruction: "티스토리 본문",
      template_name: "카페·디저트",
      template_plum: 3,
    },
    {
      cp_template_seq: 139,
      template_code: "C519",
      instruction: "티스토리 본문",
      template_name: "TV",
      template_plum: 3,
    },
    {
      cp_template_seq: 140,
      template_code: "C520",
      instruction: "티스토리 본문",
      template_name: "스타",
      template_plum: 3,
    },
    {
      cp_template_seq: 141,
      template_code: "C521",
      instruction: "티스토리 본문",
      template_name: "영화",
      template_plum: 3,
    },
    {
      cp_template_seq: 142,
      template_code: "C522",
      instruction: "티스토리 본문",
      template_name: "음악",
      template_plum: 3,
    },
    {
      cp_template_seq: 143,
      template_code: "C523",
      instruction: "티스토리 본문",
      template_name: "책",
      template_plum: 3,
    },
    {
      cp_template_seq: 144,
      template_code: "C524",
      instruction: "티스토리 본문",
      template_name: "만화·애니",
      template_plum: 3,
    },
    {
      cp_template_seq: 145,
      template_code: "C525",
      instruction: "티스토리 본문",
      template_name: "공연·전시·축제",
      template_plum: 3,
    },
    {
      cp_template_seq: 146,
      template_code: "C526",
      instruction: "티스토리 본문",
      template_name: "창작",
      template_plum: 3,
    },
    {
      cp_template_seq: 147,
      template_code: "C527",
      instruction: "티스토리 본문",
      template_name: "IT 인터넷",
      template_plum: 3,
    },
    {
      cp_template_seq: 148,
      template_code: "C528",
      instruction: "티스토리 본문",
      template_name: "모바일",
      template_plum: 3,
    },
    {
      cp_template_seq: 149,
      template_code: "C529",
      instruction: "티스토리 본문",
      template_name: "게임",
      template_plum: 3,
    },
    {
      cp_template_seq: 150,
      template_code: "C530",
      instruction: "티스토리 본문",
      template_name: "과학",
      template_plum: 3,
    },
    {
      cp_template_seq: 151,
      template_code: "C531",
      instruction: "티스토리 본문",
      template_name: "IT 제품리뷰",
      template_plum: 3,
    },
    {
      cp_template_seq: 152,
      template_code: "C532",
      instruction: "티스토리 본문",
      template_name: "스포츠 일반",
      template_plum: 3,
    },
    {
      cp_template_seq: 153,
      template_code: "C533",
      instruction: "티스토리 본문",
      template_name: "축구",
      template_plum: 3,
    },
    {
      cp_template_seq: 154,
      template_code: "C534",
      instruction: "티스토리 본문",
      template_name: "야구",
      template_plum: 3,
    },
    {
      cp_template_seq: 155,
      template_code: "C535",
      instruction: "티스토리 본문",
      template_name: "농구",
      template_plum: 3,
    },
    {
      cp_template_seq: 156,
      template_code: "C536",
      instruction: "티스토리 본문",
      template_name: "배구",
      template_plum: 3,
    },
    {
      cp_template_seq: 157,
      template_code: "C537",
      instruction: "티스토리 본문",
      template_name: "골프",
      template_plum: 3,
    },
    {
      cp_template_seq: 158,
      template_code: "C538",
      instruction: "티스토리 본문",
      template_name: "사회",
      template_plum: 3,
    },
    {
      cp_template_seq: 159,
      template_code: "C539",
      instruction: "티스토리 본문",
      template_name: "교육",
      template_plum: 3,
    },
    {
      cp_template_seq: 160,
      template_code: "C540",
      instruction: "티스토리 본문",
      template_name: "국제",
      template_plum: 3,
    },
    {
      cp_template_seq: 161,
      template_code: "C541",
      instruction: "티스토리 본문",
      template_name: "경제",
      template_plum: 3,
    },
    {
      cp_template_seq: 162,
      template_code: "C542",
      instruction: "티스토리 본문",
      template_name: "경영·직장",
      template_plum: 3,
    },
  ],
  diary: [
    {
      cp_template_seq: 163,
      template_code: "C601",
      instruction: "일기",
      template_name: "일기",
      template_plum: 3,
    },
  ],
  dreamDiary: [
    {
      cp_template_seq: 178,
      template_code: "C2101",
      instruction: "꿈일기",
      template_name: "꿈일기",
      template_plum: 0,
    },
  ],
  reflectionLetter: [
    {
      cp_template_seq: 164,
      template_code: "C701",
      instruction: "반성문",
      template_name: "반성문",
      template_plum: 3,
    },
  ],
  toast: [
    {
      cp_template_seq: 165,
      template_code: "C801",
      instruction: "건배사",
      template_name: "건배사",
      template_plum: 3,
    },
  ],
  improvedAIDAModelAD: [
    {
      cp_template_seq: 166,
      template_code: "C901",
      instruction: "개선된 AIDA 모델 광고",
      template_name: "개선된 AIDA 모델 광고",
      template_plum: 3,
    },
  ],
  productDetail: [
    {
      cp_template_seq: 167,
      template_code: "C1001",
      instruction: "제품 상세페이지",
      template_name: "제품 상세페이지",
      template_plum: 3,
    },
  ],
  interviewQeustionsAnswers: [
    {
      cp_template_seq: 168,
      template_code: "C1101",
      instruction: "면접 예상질문과 답변",
      template_name: "면접 예상질문과 답변",
      template_plum: 3,
    },
  ],
  googleAD: [
    {
      cp_template_seq: 169,
      template_code: "C1201",
      instruction: "구글광고",
      template_name: "구글광고",
      template_plum: 3,
    },
  ],
  copyWritingIdea: [
    {
      cp_template_seq: 170,
      template_code: "C1301",
      instruction: "카피라이팅 아이디어",
      template_name: "카피라이팅 아이디어",
      template_plum: 3,
    },
  ],
  notice: [
    {
      cp_template_seq: 171,
      template_code: "C1401",
      instruction: "공지사항",
      template_name: "공지사항",
      template_plum: 3,
    },
  ],
  travelSchedule: [
    {
      cp_template_seq: 172,
      template_code: "C1501",
      instruction: "여행일정",
      template_name: "여행일정",
      template_plum: 3,
    },
  ],
  appPushMessage: [
    {
      cp_template_seq: 173,
      template_code: "C1601",
      instruction: "앱 푸시 메시지",
      template_name: "앱 푸시 메시지",
      template_plum: 3,
    },
  ],
  marketingPlanDraft: [
    {
      cp_template_seq: 174,
      template_code: "C1701",
      instruction: "마케팅 기획서 초안",
      template_name: "마케팅 기획서 초안",
      template_plum: 3,
    },
  ],
  pressRelease: [
    {
      cp_template_seq: 175,
      template_code: "C1801",
      instruction: "보도자료",
      template_name: "보도자료",
      template_plum: 3,
    },
  ],
  linkedinProfile: [
    {
      cp_template_seq: 176,
      template_code: "C1901",
      instruction: "링크드인 프로필",
      template_name: "링크드인 프로필",
      template_plum: 2,
    },
  ],
  businessPlanDraft: [
    {
      cp_template_seq: 177,
      template_code: "C2001",
      instruction: "사업계획서 초안",
      template_name: "사업계획서 초안",
      template_plum: 3,
    },
  ],
  dreamInterpretation: [
    {
      cp_template_seq: 178,
      template_code: "C2101",
      instruction: "어제 본 꿈 이야기",
      template_name: "해몽",
      template_plum: 0,
    },
  ],
  expressingMoodWithColor: [
    {
      cp_template_seq: 179,
      template_code: "C2201",
      instruction: "감정 팔레트",
      template_name: "기분을 색으로 표현하기",
      template_plum: 0,
    },
  ],
  rewriteArticle: [
    {
      cp_template_seq: 180,
      template_code: "C2301",
      instruction: "기사 다시쓰기",
      template_name: "기사 다시쓰기",
      template_plum: 3,
    },
  ],
};

/******************** 상품 카테고리 코드 ******************/
export const productCategoryCode = [
  {
    cp_product_category_seq: 1,
    product_category_code: "P001",
    product_category_name_ko: "식품 및 음료",
    product_category_name_en: "Food & Beverages",
  },
  {
    cp_product_category_seq: 2,
    product_category_code: "P002",
    product_category_name_ko: "개인 위생 및 뷰티 제품",
    product_category_name_en: "Personal Hygiene & Beauty Products",
  },
  {
    cp_product_category_seq: 3,
    product_category_code: "P003",
    product_category_name_ko: "가정용품, 청소 제품 및 인테리어 장식",
    product_category_name_en: "Home Goods, Cleaning Supplies & Interior Decor",
  },
  {
    cp_product_category_seq: 4,
    product_category_code: "P004",
    product_category_name_ko: "의류 및 패션 액세서리",
    product_category_name_en: "Clothing & Fashion Accessories",
  },
  {
    cp_product_category_seq: 5,
    product_category_code: "P005",
    product_category_name_ko: "가전제품 및 디지털 기기",
    product_category_name_en: "Electronics & Digital Devices",
  },
  {
    cp_product_category_seq: 6,
    product_category_code: "P006",
    product_category_name_ko: "스포츠, 레저 및 아웃도어 용품",
    product_category_name_en: "Sports, Leisure & Outdoor Goods",
  },
  {
    cp_product_category_seq: 7,
    product_category_code: "P007",
    product_category_name_ko: "건강, 웰니스 및 약품",
    product_category_name_en: "Health, Wellness & Pharmaceuticals",
  },
  {
    cp_product_category_seq: 8,
    product_category_code: "P008",
    product_category_name_ko: "유아용품 및 교육용 제품",
    product_category_name_en: "Baby Products & Educational Materials",
  },
  {
    cp_product_category_seq: 9,
    product_category_code: "P009",
    product_category_name_ko: "애완동물 용품",
    product_category_name_en: "Pet Supplies",
  },
  {
    cp_product_category_seq: 10,
    product_category_code: "P010",
    product_category_name_ko: "엔터테인먼트 및 디지털 콘텐츠",
    product_category_name_en: "Entertainment & Digital Content",
  },
  {
    cp_product_category_seq: 11,
    product_category_code: "P011",
    product_category_name_ko: "취미, 게임 및 DIY 용품",
    product_category_name_en: "Hobbies, Games & DIY Supplies",
  },
  {
    cp_product_category_seq: 12,
    product_category_code: "P012",
    product_category_name_ko: "차량, 운송 장비 및 부품",
    product_category_name_en: "Vehicles, Transportation Equipment & Parts",
  },
  {
    cp_product_category_seq: 13,
    product_category_code: "P013",
    product_category_name_ko: "사무용품 및 비즈니스 서비스",
    product_category_name_en: "Office Supplies & Business Services",
  },
  {
    cp_product_category_seq: 14,
    product_category_code: "P014",
    product_category_name_ko: "여행 및 숙박 서비스",
    product_category_name_en: "Travel & Accommodation Services",
  },
  {
    cp_product_category_seq: 15,
    product_category_code: "P015",
    product_category_name_ko: "음식 서비스, 케이터링 및 레스토랑",
    product_category_name_en: "Food Services, Catering & Restaurants",
  },
  {
    cp_product_category_seq: 16,
    product_category_code: "P016",
    product_category_name_ko: "금융 서비스 및 보험",
    product_category_name_en: "Financial Services & Insurance",
  },
  {
    cp_product_category_seq: 17,
    product_category_code: "P017",
    product_category_name_ko: "교육 및 트레이닝 서비스",
    product_category_name_en: "Education & Training Services",
  },
  {
    cp_product_category_seq: 18,
    product_category_code: "P018",
    product_category_name_ko: "통신 및 IT 서비스",
    product_category_name_en: "Telecommunication & IT Services",
  },
  {
    cp_product_category_seq: 19,
    product_category_code: "P019",
    product_category_name_ko: "제조, 공급 체인 및 물류 서비스",
    product_category_name_en:
      "Manufacturing, Supply Chain & Logistics Services",
  },
  {
    cp_product_category_seq: 20,
    product_category_code: "P020",
    product_category_name_ko: "건축재료, 건설 및 부동산 서비스",
    product_category_name_en:
      "Building Materials, Construction & Real Estate Services",
  },
  {
    cp_product_category_seq: 21,
    product_category_code: "P021",
    product_category_name_ko: "에너지, 환경 관리 및 지속 가능한 솔루션",
    product_category_name_en:
      "Energy, Environmental Management & Sustainable Solutions",
  },

  {
    cp_product_category_seq: 22,
    product_category_code: "P022",
    product_category_name_ko: "의료기기, 헬스케어 및 생명 과학",
    product_category_name_en: "Medical Devices, Healthcare & Life Sciences",
  },
  {
    cp_product_category_seq: 23,
    product_category_code: "P023",
    product_category_name_ko: "산업용 기계, 장비 및 공구",
    product_category_name_en: "Industrial Machinery, Equipment & Tools",
  },
  {
    cp_product_category_seq: 24,
    product_category_code: "P024",
    product_category_name_ko: "전문 서비스 (법률, 회계, 컨설팅)",
    product_category_name_en:
      "Professional Services (Legal, Accounting, Consulting)",
  },
  {
    cp_product_category_seq: 25,
    product_category_code: "P025",
    product_category_name_ko: "예술, 공예, 디자인 및 이벤트 서비스",
    product_category_name_en: "Art, Crafts, Design & Event Services",
  },
];
