export default function corporateFunc(
  companyName,
  industry,
  email,
  contact,
  inquiry
) {
  // 기업문의 폼 URL
  const formUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdOIHmtraRjCG92Sow59aaK-AobdcB9-lFlGQLdvqeHXhqcFw/formResponse";

  const formData = {
    "entry.399095347": companyName, // 회사명
    "entry.1047673956": industry, // 산업군
    "entry.1574999407": email, // 이메일
    "entry.433121635": contact, // 연락처
    "entry.1399041521": inquiry, // 문의 내용
  };

  const queryString = new URLSearchParams(formData).toString();
  const fullUrl = `${formUrl}?${queryString}`; // URL 생성

  return fetch(fullUrl)
    .then((response) => {
      if (response.ok) {
        console.log("폼이 성공적으로 제출되었습니다!");
        return {
          success: true,
          message: "기업 문의가 성공적으로 제출되었습니다!",
        };
      } else {
        console.error("폼 제출에 실패했습니다.");
        return { success: false, message: "기업 문의 제출에 실패했습니다." };
      }
    })
    .catch((error) => {
      console.error("오류 발생:", error);
      return { success: false, message: `오류: ${error}` };
    });
}
