import React, { useState } from 'react';
import BasicRadio from 'components/Radio/BasicRadio';

const DeletionModalContent = ({ onSubmit }) => {
    const [customReason, setCustomReason] = useState('');
    const [selectedReason, setSelectedReason] = useState('');

    const reasons = [
        {
            label: '서비스 품질에 만족하지 않음',
            value: '서비스 품질에 만족하지 않음',
        },
        { label: '사용 빈도가 낮음', value: '사용 빈도가 낮음' },
        { label: '개인정보 보호 우려', value: '개인정보 보호 우려' },
        { label: '더 나은 대체 서비스 발견', value: '더 나은 대체 서비스 발견' },
        { label: '기술적인 문제로 인한 불편', value: '기술적인 문제로 인한 불편' },
        { label: '직접입력', value: '직접입력' },
    ];

    const handleRadioChange = (value) => {
        setSelectedReason(value);
    };

    const handleCustomReasonChange = (e) => {
        setCustomReason(e.target.value);
    };

    const handleSubmit = () => {
        const reason = selectedReason === '직접입력' ? customReason : selectedReason;
        onSubmit(reason);
    };

    return (
        <div className="mt-2 ">
            <div className="space-y-2 text-sm w-[440px]">
                <BasicRadio
                    name="deletionReason"
                    options={reasons}
                    defaultCheckOption={selectedReason}
                    onChange={handleRadioChange}
                />
                {selectedReason === '직접입력' && (
                    <textarea
                        className="bg-white focus:outline-1  focus:outline-main-pink w-full py-2.5 px-4 rounded-lg resize-none placeholder:text-text-dGray"
                        placeholder="탈퇴 이유를 입력하세요"
                        value={customReason}
                        onChange={handleCustomReasonChange}
                        rows={3}
                    />
                )}
            </div>
            <div className="flex justify-center mt-4">
                <button
                    className="text-white bg-main-red p-1 rounded-md text-sm text-center w-[250px]"
                    onClick={handleSubmit}
                >
                    탈퇴 문의
                </button>
            </div>
        </div>
    );
};

export default DeletionModalContent;
