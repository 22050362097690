// hooks
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// Landing Components
import LandingHeader from 'pages/Landing/components/LandingHeader';
import CreatedCard from 'pages/Landing/components/CreatedCard';
import LandingRecommand from 'pages/Landing/components/LandingRecommand';
// components
import CustomButton from 'components/Buttons/CustomButton';
import LandingFooter from 'pages/Landing/components/LandingFooter';
import Divider from 'components/Divider';
// images
import landing_background from 'assets/landing/landing_background.webp';
// icons
import { IoIosArrowDown } from 'react-icons/io';

const CreatedLanding = () => {
    //useLocation 으로 데이터를 받습니다.
    const navigate = useNavigate();
    const { state } = useLocation();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const updateScrollData = () => {
            setScrollPosition(window.scrollY);
            setScrollHeight(document.documentElement.scrollHeight);
            // setViewportHeight(window.innerHeight);
        };

        window.addEventListener('scroll', updateScrollData);
        window.addEventListener('resize', updateScrollData); // Resize event to handle viewport height change

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', updateScrollData);
            window.removeEventListener('resize', updateScrollData);
        };
    }, [state]);
    // console.log(scrollHeight, scrollPosition);
    return (
        <div className="relative h-full">
            <img
                alt="landing_background"
                src={landing_background}
                className="absolute z-[-1] bottom-[-240px]"
            />
            <LandingHeader />
            <div className="flex justify-between cherry-landing-wrapper">
                <CreatedCard contents={state} />
                <div className="w-[368px] ">
                    <div
                        className={
                            scrollHeight && scrollPosition && scrollHeight - scrollPosition < 1495
                                ? 'pt-5 w-[368px] mr-10 p-6 h-fit text-gray-dGray text-sm absolute bottom-[400px]'
                                : 'pt-5 w-[368px] mr-10 p-6 h-fit text-gray-dGray text-sm fixed'
                        }
                    >
                        <div className="bg-[#ffffff50] border rounded-[15px] w-full h-full top-0 left-0 z-10 absolute flex justify-center items-center text-center">
                            <div>
                                {/* <div className="mb-4 text-bold text-text-white">
                로그인 후 더 많은 기능을 이용하세요
              </div> */}
                                <button
                                    onClick={() => navigate('/login')}
                                    className="mx-auto mt-[7rem] bg-gradient-to-r from-[#9218D4] to-[#D22585] rounded-[10px] py-2 px-4 f text-text-white"
                                >
                                    회원가입시 이용가능
                                </button>
                            </div>
                        </div>
                        <div className="mb-3">브랜드</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>브랜드 명</div>
                            <IoIosArrowDown />
                        </div>

                        <div className="mt-6 mb-3">타겟 설정</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>2030 남성</div>
                            <IoIosArrowDown />
                        </div>

                        <div className="mt-6 mb-3">포스팅 목적</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>팔로워 증가</div>
                            <IoIosArrowDown />
                        </div>

                        <div className="mt-6 mb-3 text-gray-gray">캡션 핵심 키워드</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>남성패션</div>
                        </div>
                        <div className="mt-3">
                            {['남성 패션', '스트릿 패션', '여성 패션'].map((el, idx) => (
                                <span
                                    key={idx}
                                    className="rounded-md bg-[#F3395620] mr-1 py-1 px-2 text-[#F3395690] text-xs font-semibold"
                                >
                                    {el}
                                </span>
                            ))}
                        </div>
                        <div className="mt-6 mb-3 text-gray-gray">필수 내용</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div className="h-20">가볍게 즐길 수 있는 힙합 패션 브랜드 훌라노이즈</div>
                        </div>

                        <div className="mt-6 mb-3 font-bold text-gray-gray">톤앤매너</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>전문적인</div>
                            <IoIosArrowDown />
                        </div>

                        <div className="mt-6 mb-3 font-bold text-gray-gray">언어</div>
                        <div className="flex items-center justify-between p-3 rounded-lg bg-gray-sGray">
                            <div>한국어</div>
                            <IoIosArrowDown />
                        </div>
                    </div>
                </div>
                {/* <div className="w-full my-10 text-center">
          <CustomButton
            content="생성하기"
            customStyle={
              "bg-back-black text-text-white p-4 rounded-md"
            }
          />
        </div> */}
            </div>

            <div className="mb-2 font-semibold text-center">다양한 콘텐츠를 생성해드려요</div>
            <div className="text-xs font-semibold text-center mb-7 text-main-red">
                지금 회원가입시, 첫 달동안 무료로 사용하실 수 있어요
            </div>

            {/* <Divider /> */}
            <LandingRecommand />
            <Divider />

            <div className="fixed w-full text-center bottom-10 ">
                <Link to="/login">
                    <CustomButton
                        content="회원가입시 첫 달 무료 사용"
                        customStyle={
                            'bg-main-pink text-text-white px-4 py-2 rounded-[10px] bg-gradient-to-r from-[#9218D4] to-[#D22585] rounded-[10px]'
                        }
                    />
                </Link>
            </div>

            <Divider />
            {/* <Divider /> */}

            <LandingFooter isFordAbsolute={false} />
        </div>
    );
};

export default CreatedLanding;
