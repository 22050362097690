const PrimaryBtn = ({ content, size = 'sm', btnClickFunc, disabled = false }) => {
    const smStyle =
        'bg-text-white hover:bg-gray-sGray hover:text-main-red   border border-status-gray hover:border-gray-sGray py-1 px-2 rounded-[10px] text-sm';
    const mdStyle =
        'bg-text-white hover:bg-gray-sGray hover:text-main-red  border border-status-gray hover:border-gray-sGray py-2 px-2 rounded-[10px] text-sm';
    const lgStyle =
        'bg-text-white hover:bg-gray-sGray hover:text-main-red  border border-status-gray hover:border-gray-sGray py-2 px-3 rounded-[10px] text-base';
    const xlStyle =
        'bg-text-white hover:bg-gray-sGray hover:text-main-red  border border-status-gray hover:border-gray-sGray py-3 px-3 rounded-[10px] text-base';

    return (
        <button
            type="button"
            onClick={btnClickFunc}
            className={size === 'sm' ? smStyle : size === 'md' ? mdStyle : size === 'lg' ? lgStyle : xlStyle}
        >
            {content}
        </button>
    );
};

export default PrimaryBtn;
