import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMobile: false, // 초기 상태 설정
};

const isMobileSlice = createSlice({
    name: 'mobile',
    initialState,
    reducers: {
        setIsMobile: (state, action) => {
            // 상태 업데이트
            state.isMobile = action.payload;
        },
        toggleIsMobile: (state) => {
            // 상태 토글
            state.isMobile = !state.isMobile;
        },
    },
});

export const { setIsMobile, toggleIsMobile } = isMobileSlice.actions;

export default isMobileSlice.reducer;
