// hooks
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// common components
import { PlanMatcherToKor } from "utils/PlanMatcher";
// icons
import { FaCheck } from "react-icons/fa6";

const MyInfoModal = ({ myInfo, myInfoModalShow, setMyInfoModalShow }) => {
  const navigate = useNavigate();
  const planColor = {
    FREE: "bg-[#51CF66]",
    NANO: "bg-[#EB2283]",
    BASIC: "bg-[#906AFF]",
    CLASSIC: "bg-[#9C1AD4]",
    PRIME: "bg-[#2E0053]",
  };
  const { authType, expirationDate, name, planName, remainingPlum, userId } =
    myInfo;
  return (
    <div className="text-sm absolute z-40  bottom-[-12.5rem] border w-[15rem] shadow-lg bg-text-white rounded-lg p-2">
      <div>
        <div className="p-1 mb-2">
          <span className="font-bold">{name}</span>님
          <div className="text-text-gray">{userId}</div>
        </div>
      </div>
      <Link to="/mypage?tab=information" className="group">
        <div className="p-2 rounded-md flex items-center justify-between group-hover:bg-gray-sGray text-gray-gray group-hover:text-black group-hover:font-semibold">
          <div> 내 정보</div>
          <FaCheck className="opacity-0 group-hover:opacity-100 text-main-red w-3 h-3 mr-2" />
        </div>
      </Link>
      <Link to="/plan">
        <div className="flex items-center justify-between p-2 rounded-md group hover:bg-gray-sGray">
          <span className="text-gray-gray group-hover:text-black group-hover:font-semibold">
            요금제
          </span>

          <span
            className={`${planColor[planName]} text-xs py-1 px-2 rounded-md font-bold text-white`}
          >
            {PlanMatcherToKor(planName)}
          </span>
        </div>
      </Link>
      <Link to="/mypage?tab=payment" className="group">
        <div className="p-2  flex items-center justify-between rounded-md group-hover:bg-gray-sGray text-gray-gray group-hover:text-black group-hover:font-semibold">
          <div> 결제정보</div>
          <FaCheck className="opacity-0 group-hover:opacity-100 text-main-red w-3 h-3 mr-2" />
        </div>
      </Link>
      <button
        onClick={() => {
          localStorage.removeItem("TOKEN");
          navigate("/login");
          window.location.reload();
        }}
        className="group w-full"
      >
        <div className="p-2 flex items-center justify-between text-xs rounded-md group-hover:bg-gray-sGray text-main-pink w-full">
          <div className=":font-bold">로그아웃</div>
          <FaCheck className="opacity-0 group-hover:opacity-100 text-main-red w-3 h-3 mr-2" />
        </div>
      </button>
    </div>
  );
};

export default MyInfoModal;
