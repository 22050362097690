// cookie.js

import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name, value, options) => {
    return cookies.set(name, value, { ...options });
};

export const getCookie = (name) => {
    return cookies.get(name);
};

export const removeCookie = (name) => {
    return cookies.remove(name);
};

// https://iridescent-zeal.tistory.com/234

// 사용 예시
// import {
//   setCookie, getCookie, removeCookie
// } from './config/cookie';

// export const CookieTest = () => {
//   const onSet = () => {
//     setCookie('cookieKey', 'cookieValue', {
//       path: '/',
//       secure: true,
//       maxAge: 3000
//     })
//   }
//   const onGet = () => {
//     const getVal = getCookie('cookieKey');
//   }
//   const onRemove = () => {
//     removeCookie('cookieKey')
//   }

//   return (
//     <>
//       <button onClick={onSet} type="button">set cookie</button>
//       <button onClick={onGet} type="button">get cookie</button>
//       <button onClick={onRemove} type="button">remove cookie</button>
//     </>
//   );
// };
