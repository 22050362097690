import { useState, useEffect } from "react";
import Header from "./Header/index";
import Navbar from "./Navbar/index";
import BandBanner from "./BandBanner/BandBanner";
import { useLocation } from "react-router-dom"; // useLocation 훅 가져오기

const Layout = ({ children }) => {
  const location = useLocation();
  const [isBannerVisible, setIsBannerVisible] = useState(false); // 띠배너 상태 관리
  const [bannerHeight, setBannerHeight] = useState(0); // 배너 높이 저장

  const noHeaderNavbarPaths = [
    "/login",
    "/auth",
    "/landing",
    "/Landing",
    "/created-landing",
    "/login/oauth",
    "/login/authentication-failure",
  ];

  // 띠배너 닫기 함수
  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    localStorage.setItem("bannerClosed", new Date().toISOString());
    setBannerHeight(0); // 띠배너 닫았을 때 헤더를 다시 위로
  };

  useEffect(() => {
    const bannerClosedTime = localStorage.getItem("bannerClosed");

    // 하루가 지났으면 배너를 다시 보여주고, 배너 높이를 설정
    if (
      !bannerClosedTime ||
      new Date() - new Date(bannerClosedTime) > 24 * 60 * 60 * 1000
    ) {
      setIsBannerVisible(true);
      setBannerHeight(55); // 띠배너가 있을 때 배너 높이를 50px로 설정
    }
  }, []);

  return (
    <div>
      {!noHeaderNavbarPaths.includes(location.pathname) && (
        <>
          {isBannerVisible && <BandBanner onClose={handleCloseBanner} />}
          <div
            style={{
              paddingTop: isBannerVisible ? "65px" : "0", // 띠배너 높이만큼 헤더를 내림
              transition: "padding-top 0.3s ease", // 부드러운 이동 효과
            }}
          >
            <Header bannerHeight={bannerHeight} />
            <Navbar />
          </div>
        </>
      )}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
