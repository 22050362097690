import { FiTrash2 } from 'react-icons/fi';
const KeywordBadge = ({ keyword, onClick }) => {
    return (
        <div>
            <p className="bg-gray-sGray text-sm rounded-[10px] px-3 py-1 flex text-back-gray items-center">
                {keyword}
                <FiTrash2
                    onClick={onClick}
                    className="w-4 h-4 ml-2 cursor-pointer text-back-gray hover:text-main-pink "
                />
            </p>
        </div>
    );
};

export default KeywordBadge;
