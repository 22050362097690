// hooks
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRequestPost } from "hooks/useAuth";
import { useRequestGet } from "hooks/useRequest";
// common
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
const Signup = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [verifyEmailSend, setVerifyEmailSend] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifiedId, setVerfiedId] = useState("");

  // input handler
  const handleChange = (e) => {
    const { id, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  /*********************************************/

  // POST Signup 요청
  const postSignup = useRequestPost();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    let passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/;
    if (!emailRegex.test(values.id)) {
      toast.error("유효하지 않은 이메일주소입니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (!passwordRegex.test(values.password)) {
      toast.error("영문 숫자 조합 8자 이상 비밀번호를 입력하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (!values.password === values.passwordRepeat) {
      toast.error("비밀번호가 일치하지 않습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (!isVerified) {
      toast.error("이메일 인증이 필요합니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (values.id !== verifiedId) {
      toast.error("인증된 이메일이 아닙니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      postSignup.mutate({
        requestUrl: "/api/user/register",
        postData: {
          name: values.name,
          userId: values.id,
          password: values.password,
          passwordRepeat: values.passwordRepeat,
          isVerified: isVerified,
        },
      });
    }
  };
  // POST Signup handler
  useEffect(() => {
    if (postSignup.status === "success") {
      toast.success("회원가입되었습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate("/login");
    } else if (postSignup.status === "error") {
      toast.error("이미 있는 이메일이거나, 이메일, 비밀번호를 확인해주세요.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSignup.status]);

  /*********************************************/

  // POST VerifyEmailSend 요청
  const postVerifyEmailSend = useRequestPost();
  const handleVerfyEmailSend = async (e) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    e.preventDefault();
    if (emailRegex.test(values.id)) {
      toast.success("메일을 발송했습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      postVerifyEmailSend.mutate({
        requestUrl: "/api/email/send",
        postData: {
          email: values.id,
        },
      });
    } else {
      toast.error("유효하지 않은 이메일주소입니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  // POST VerifyEmailSend handler
  useEffect(() => {
    if (postVerifyEmailSend.status === "success") {
      let verifiedId = postVerifyEmailSend.data.data.email;
      setVerfiedId(verifiedId);
      setVerifyEmailSend(true);
    } else if (postVerifyEmailSend.status === "error") {
      toast.error("인증번호 전송에 실패했습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setVerifyEmailSend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postVerifyEmailSend.status]);

  /*********************************************/

  // POST Verify 요청
  const postVerify = useRequestPost();
  const handleVerify = (e) => {
    e.preventDefault();
    postVerify.mutate({
      requestUrl: "/api/email/verify",
      postData: {
        email: values.id,
        verificationCode: values.verify,
      },
    });
  };
  useEffect(() => {
    if (postVerify && postVerify.data) {
      if (postVerify.data.data) {
        setIsVerified(true);
        toast.success("인증되었습니다.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("인증번호가 일치하지 않습니다", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setIsVerified(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postVerify.status, postVerify.data]);

  return (
    <div className="mt-4">
      <form onChange={handleChange} onSubmit={handleSubmit}>
        {/* NAME */}
        <input
          id="name"
          type="text"
          maxLength={40}
          placeholder="닉네임"
          className="w-full p-2 mb-4 placeholder-black rounded-lg inputTextMinSize bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red"
        />

        {/* ID */}
        <div className="relative">
          <input
            id="id"
            type="id"
            maxLength={100}
            placeholder="이메일"
            className="w-full p-2 pr-20 mb-4 placeholder-black rounded-lg inputTextMinSize bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red"
          />
          <button
            onClick={(e) => handleVerfyEmailSend(e)}
            type="button"
            className={
              "bg-back-dGray text-text-white p-2 top-[2px] rounded-md absolute right-[2px] text-sm"
            }
          >
            메일발송
          </button>
        </div>

        {/* verify */}
        <div className="relative">
          <input
            id="verify"
            type="text"
            maxLength={40}
            placeholder="인증번호"
            className="w-full p-2 mb-2 placeholder-black rounded-lg bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red inputTextMinSize"
          />

          <button
            onClick={(e) => handleVerify(e)}
            type="button"
            className={
              isVerified
                ? "bg-main-pink text-text-white p-2 top-[2px] rounded-md absolute right-[2px] text-sm"
                : "bg-back-dGray text-text-white p-2 top-[2px] rounded-md absolute right-[2px] text-sm"
            }
          >
            {isVerified ? "인증완료" : "인증하기"}
          </button>
        </div>
        {/* 재발송 */}
        <div className="flex justify-end mb-2 text-xs text-gray-dGray">
          <div>인증번호가 안왔나요?</div>
          <button
            onClick={(e) => handleVerfyEmailSend(e)}
            className="font-semibold"
          >
            재발송
          </button>
        </div>

        {/* PW */}
        <input
          id="password"
          type="password"
          maxLength={40}
          placeholder="비밀번호"
          className="w-full p-2 mb-4 placeholder-black rounded-lg bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red inputTextMinSize"
        />

        {/* PW Repeat */}
        <input
          id="passwordRepeat"
          type="password"
          maxLength={40}
          placeholder="비밀번호 확인"
          className="w-full p-2 mb-2 placeholder-black rounded-lg bg-gray-sGray focus:bg-text-white focus:font-semibold focus:outline-main-red inputTextMinSize"
        />

        {values.passwordRepeat && values.password === values.passwordRepeat ? (
          <div className="text-xs font-normal text-status-green">
            비밀번호가 일치합니다
          </div>
        ) : values.passwordRepeat &&
          !(values.password === values.passwordRepeat) ? (
          <div className="text-xs font-normal text-main-red">
            비밀번호가 일치하지 않습니다
          </div>
        ) : (
          <></>
        )}

        {/* submit */}
        <button
          onClick={handleSubmit}
          type="submit"
          className="w-full p-2 mt-4 rounded-lg bg-back-dGray hover:bg-main-pink text-text-white"
        >
          가입완료
        </button>
      </form>
    </div>
  );
};
export default Signup;
