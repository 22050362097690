// hooks
import { useState } from "react";
// icons
import { FiFilePlus } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
// ReCreate Navs
import Instagram from "pages/BehanceEditor/components/ReCreateNav/components/Instagram";
import NaverBlog from "pages/BehanceEditor/components/ReCreateNav/components/NaverBlog";
import TStory from "pages/BehanceEditor/components/ReCreateNav/components/TStory";
import LinkedinProfile from "pages/BehanceEditor/components/ReCreateNav/components/LinkedinProfile";
import ImageStudio from "pages/BehanceEditor/components/ReCreateNav/components/ImageStudio";
import Diary from "pages/BehanceEditor/components/ReCreateNav/components/Diary";
import DreamDiary from "pages/BehanceEditor/components/ReCreateNav/components/DreamDiary";
import ReflectionLetter from "pages/BehanceEditor/components/ReCreateNav/components/ReflectionLetter";
import Toast from "pages/BehanceEditor/components/ReCreateNav/components/Toast";
import InterviewQeustionsAnswers from "pages/BehanceEditor/components/ReCreateNav/components/InterviewQeustionsAnswers";
import CopyWritingIdea from "pages/BehanceEditor/components/ReCreateNav/components/CopyWritingIdea";
import ImprovedAIDAModelAD from "pages/BehanceEditor/components/ReCreateNav/components/ImprovedAIDAModelAD";
import ProductDetail from "pages/BehanceEditor/components/ReCreateNav/components/ProductDetail";
import GoogleAD from "pages/BehanceEditor/components/ReCreateNav/components/GoogleAD";
import MarketingPlanDraft from "pages/BehanceEditor/components/ReCreateNav/components/MarketingPlanDraft";
import BusinessPlanDraft from "pages/BehanceEditor/components/ReCreateNav/components/BusinessPlanDraft";
import AppPushMessage from "pages/BehanceEditor/components/ReCreateNav/components/AppPushMessage";
import PressRelease from "pages/BehanceEditor/components/ReCreateNav/components/PressRelease";
import RewriteArticle from "pages/BehanceEditor/components/ReCreateNav/components/RewriteArticle";
import Notice from "pages/BehanceEditor/components/ReCreateNav/components/Notice";
import TravelSchedule from "pages/BehanceEditor/components/ReCreateNav/components/TravelSchedule";

const ReCreateNav = ({
  setData,
  refetchFunc,
  templateCode,
  firstVariable,
  secondVariable,
  thirdVariable,
  fourthVariable,
  fifthVariable,
  brand,
  target,
  tone,
  language,
  objective,
  model,
}) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const handleButton = (e) => {
    e.stopPropagation();
    if (!open) {
      setOpen(true);
    }
  };
  const renderComponent = () => {
    switch (true) {
      case 101 <= checkTemplateCode && checkTemplateCode <= 109:
        return (
          <Instagram
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case 201 <= checkTemplateCode && checkTemplateCode <= 331:
        return (
          <NaverBlog
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case 400 <= checkTemplateCode && checkTemplateCode <= 542:
        return (
          <TStory
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 601:
        return (
          <Diary
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 2101:
        return (
          <DreamDiary
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 701:
        return (
          <ReflectionLetter
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 801:
        return (
          <Toast
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 901:
        return (
          <ImprovedAIDAModelAD
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1001:
        return (
          <ProductDetail
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1101:
        return (
          <InterviewQeustionsAnswers
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1201:
        return (
          <GoogleAD
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1301:
        return (
          <CopyWritingIdea
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1401:
        return (
          <Notice
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1501:
        return (
          <TravelSchedule
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1601:
        return (
          <AppPushMessage
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1701:
        return (
          <MarketingPlanDraft
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1801:
        return (
          <PressRelease
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 1901:
        return (
          <LinkedinProfile
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 2001:
        return (
          <BusinessPlanDraft
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      //   case (checkTemplateCode === 2101):
      //     return <DreamDiary
      //       refetchFunc={refetchFunc}
      //       initTemplateCode={templateCode}
      //       initFirstVariable={firstVariable}
      //       initSecondVariable={secondVariable}
      //       initThirdVariable={thirdVariable}
      //       initFourthVariable={fourthVariable}
      //       initFifthVariable={fifthVariable}
      //       initBrand={brand}
      //       initTarget={target}
      //       initTone={tone}
      //       initLanguage={language}
      //       initObjective={objective}
      //       initModel={model}
      //     />;
      //   case (checkTemplateCode === 2201):
      //     return <ExpressingMoodWithColor
      //       refetchFunc={refetchFunc}
      //       initTemplateCode={templateCode}
      //       initFirstVariable={firstVariable}
      //       initSecondVariable={secondVariable}
      //       initThirdVariable={thirdVariable}
      //       initFourthVariable={fourthVariable}
      //       initFifthVariable={fifthVariable}
      //       initBrand={brand}
      //       initTarget={target}
      //       initTone={tone}
      //       initLanguage={language}
      //       initObjective={objective}
      //       initModel={model}
      //     />;
      case checkTemplateCode === 2301:
        return (
          <RewriteArticle
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      case checkTemplateCode === 3:
        return (
          <ImageStudio
            refetchFunc={refetchFunc}
            initTemplateCode={templateCode}
            initFirstVariable={firstVariable}
            initSecondVariable={secondVariable}
            initThirdVariable={thirdVariable}
            initFourthVariable={fourthVariable}
            initFifthVariable={fifthVariable}
            initBrand={brand}
            initTarget={target}
            initTone={tone}
            initLanguage={language}
            initObjective={objective}
            initModel={model}
          />
        );
      default:
        return;
    }
  };
  const checkTemplateCode = Number(templateCode?.slice(1));
  // console.log(checkTemplateCode);
  return (
    <div>
      <div className="fixed z-40 right-2 bottom-5">
        {open ? (
          <div className="bg-gradient-to-tr from-[#c861ff] to-[#ff3ea8] p-[1.2px] rounded-2xl">
            <div
              className="bg-white border rounded-xl"
              style={{
                borderRadius: "inherit",
              }}
            >
              <button
                className="flex items-center justify-center py-2 m-auto rounded-xl text-gray-dGray"
                onClick={() => setOpen(false)}
              >
                <span className="mr-1">접기</span>
                <span>
                  <IoIosArrowDown />
                </span>
              </button>
              <div className="mb-2 ">
                {/* 여기를 템플릿코드에 맞게 수정해줘 */}
                {renderComponent()}
              </div>
            </div>
          </div>
        ) : (
          !open && (
            <button
              onClick={handleButton}
              className={`${"bg-gray-gray"} pl-1 pr-10 justify-between rounded-lg text-white flex items-center m-3`}
            >
              <span className={`${"bg-gray-dGray"} my-1 mr-6 p-2 rounded-lg`}>
                <FiFilePlus className="w-6 h-6" />
              </span>
              <span>{"재생성하기"}</span>
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ReCreateNav;
