export function MarkdownToHtml(text) {
  if (!text) return "";
  if (text === "---") return " ";

  // Convert headers
  text = text.replace(
    /^#### (.*$)/gim,
    `<h4 class="text-base font-bold">$1</h4>`
  );
  text = text.replace(/^### (.*$)/gim, `<h3 class='text-lg font-bold'>$1</h3>`);
  text = text.replace(/^## (.*$)/gim, `<h2 class='text-xl font-bold'>$1</h2>`);
  text = text.replace(/^# (.*$)/gim, `<h1 class='text-2xl font-bold'>$1</h1>`);

  // Convert bold and italic text
  text = text.replace(/\*\*(.*?)\*\*/gim, `<strong>$1</strong>`);
  text = text.replace(/\*(.*?)\*/gim, `<em>$1</em>`);

  // Convert lists
  // Handle ordered lists (e.g., 1. item)
  text = text.replace(
    /^\d+\.\s+(.*$)/gim,
    `<li class='list-disc pl-1'>$1</li>`
  );
  // Handle unordered lists (e.g., - item or * item)
  text = text.replace(/^\*\s+(.*$)/gim, `<li class='list-disc pl-1'>$1</li>`);
  text = text.replace(/^\-\s+(.*$)/gim, `<li class='list-disc pl-1'>$1</li>`);

  // Wrap list items in <ul> or <ol>
  text = text.replace(/(?:<li class='[^']*'>.*<\/li>\s*)+/gim, (match) => {
    // if (match.startsWith("<li class='list-decimal")) {
    //   return `<ol>${match}</ol>`;
    // } else {
    return `<ul class='ml-4'>${match}</ul>`;
    // }
  });

  // Convert line breaks
  text = text.replace(/\n/g, `<br />`);

  return text.trim();
}
