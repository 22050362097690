import React, { useState, useEffect, useCallback } from "react";
import { useRequestGet } from "hooks/useRequest";
import ContentsList from "./ContentsList";
import Grid from "@mui/material/Unstable_Grid2";
import Pagination from "components/Pagination/Pagination";
import { IoWarning } from "react-icons/io5";
import Loading from "components/Loading/Loading";
function ContentsBox({ searchTerm }) {
  const [noticeata, setNoticeata] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const getNotice = useRequestGet(
    `/api/notice?searchKeyword=${searchTerm}&page=${page}&size=20`,
    ["getNotice", searchTerm, page]
  );

  const fetchData = useCallback(() => {
    if (getNotice.status === "success") {
      setNoticeata(getNotice.data.data.data);
      setTotalPages(getNotice.data.data.pageInfo.totalPage);
      setIsLoading(false);
    } else if (getNotice.status === "error") {
      setIsLoading(false);
    }
  }, [getNotice]);
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [getNotice.status, fetchData]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {noticeata.length === 0 ? (
            <div className="text-center pt-56">
              <div className=" m-auto w-14 h-14 rounded-md bg-main-red mb-5 p-1">
                <IoWarning className="w-12 h-12 text-text-white " />
              </div>
              <p className="text-main-red text-lg">
                {searchTerm === ""
                  ? "게시글이 없습니다."
                  : "검색 결과가 존재하지 않습니다"}
              </p>
            </div>
          ) : (
            <div>
              <Grid container spacing={1} className="text-center text-xs">
                <Grid xs={0.8}>
                  <div className="bg-gray-sGray rounded-md p-2">NO</div>
                </Grid>
                <Grid xs={7.6}>
                  <div className="bg-gray-sGray rounded-md p-2">제목</div>
                </Grid>
                <Grid xs={1.8}>
                  <div className="bg-gray-sGray rounded-md p-2">작성일</div>
                </Grid>
                <Grid xs={1.8}>
                  <div className="bg-gray-sGray rounded-md p-2">조회수</div>
                </Grid>
              </Grid>
              {noticeata.map((item, index) => {
                return (
                  <ContentsList
                    key={index}
                    seq={item.seq}
                    title={item.noticeTitle}
                    createdAt={item.createdAt}
                    count={item.noticeViewCount}
                    isPinned={item.isPinned}
                  />
                );
              })}
              <Pagination
                totalPage={totalPages}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ContentsBox;
