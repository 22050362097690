export const categoryConfig = [
  {
    cp_product_category_seq: 1,
    product_category_code: "P001",
    product_category_name_ko: "식품 및 음료",
    product_category_name_en: "Food & Beverages",
  },
  {
    cp_product_category_seq: 2,
    product_category_code: "P002",
    product_category_name_ko: "개인 위생 및 뷰티 제품",
    product_category_name_en: "Personal Hygiene & Beauty Products",
  },
  {
    cp_product_category_seq: 3,
    product_category_code: "P003",
    product_category_name_ko: "가정용품, 청소 제품 및 인테리어 장식",
    product_category_name_en: "Home Goods, Cleaning Supplies & Interior Decor",
  },
  {
    cp_product_category_seq: 4,
    product_category_code: "P004",
    product_category_name_ko: "의류 및 패션 액세서리",
    product_category_name_en: "Clothing & Fashion Accessories",
  },
  {
    cp_product_category_seq: 5,
    product_category_code: "P005",
    product_category_name_ko: "가전제품 및 디지털 기기",
    product_category_name_en: "Electronics & Digital Devices",
  },
  {
    cp_product_category_seq: 6,
    product_category_code: "P006",
    product_category_name_ko: "스포츠, 레저 및 아웃도어 용품",
    product_category_name_en: "Sports, Leisure & Outdoor Goods",
  },
  {
    cp_product_category_seq: 7,
    product_category_code: "P007",
    product_category_name_ko: "건강, 웰니스 및 약품",
    product_category_name_en: "Health, Wellness & Pharmaceuticals",
  },
  {
    cp_product_category_seq: 8,
    product_category_code: "P008",
    product_category_name_ko: "유아용품 및 교육용 제품",
    product_category_name_en: "Baby Products & Educational Materials",
  },
  {
    cp_product_category_seq: 9,
    product_category_code: "P009",
    product_category_name_ko: "애완동물 용품",
    product_category_name_en: "Pet Supplies",
  },
  {
    cp_product_category_seq: 10,
    product_category_code: "P010",
    product_category_name_ko: "엔터테인먼트 및 디지털 콘텐츠",
    product_category_name_en: "Entertainment & Digital Content",
  },
  {
    cp_product_category_seq: 11,
    product_category_code: "P011",
    product_category_name_ko: "취미, 게임 및 DIY 용품",
    product_category_name_en: "Hobbies, Games & DIY Supplies",
  },
  {
    cp_product_category_seq: 12,
    product_category_code: "P012",
    product_category_name_ko: "차량, 운송 장비 및 부품",
    product_category_name_en: "Vehicles, Transportation Equipment & Parts",
  },
  {
    cp_product_category_seq: 13,
    product_category_code: "P013",
    product_category_name_ko: "사무용품 및 비즈니스 서비스",
    product_category_name_en: "Office Supplies & Business Services",
  },
  {
    cp_product_category_seq: 14,
    product_category_code: "P014",
    product_category_name_ko: "여행 및 숙박 서비스",
    product_category_name_en: "Travel & Accommodation Services",
  },
  {
    cp_product_category_seq: 15,
    product_category_code: "P015",
    product_category_name_ko: "음식 서비스, 케이터링 및 레스토랑",
    product_category_name_en: "Food Services, Catering & Restaurants",
  },
  {
    cp_product_category_seq: 16,
    product_category_code: "P016",
    product_category_name_ko: "금융 서비스 및 보험",
    product_category_name_en: "Financial Services & Insurance",
  },
  {
    cp_product_category_seq: 17,
    product_category_code: "P017",
    product_category_name_ko: "교육 및 트레이닝 서비스",
    product_category_name_en: "Education & Training Services",
  },
  {
    cp_product_category_seq: 18,
    product_category_code: "P018",
    product_category_name_ko: "통신 및 IT 서비스",
    product_category_name_en: "Telecommunication & IT Services",
  },
  {
    cp_product_category_seq: 19,
    product_category_code: "P019",
    product_category_name_ko: "제조, 공급 체인 및 물류 서비스",
    product_category_name_en:
      "Manufacturing, Supply Chain & Logistics Services",
  },
  {
    cp_product_category_seq: 20,
    product_category_code: "P020",
    product_category_name_ko: "건축재료, 건설 및 부동산 서비스",
    product_category_name_en:
      "Building Materials, Construction & Real Estate Services",
  },
  {
    cp_product_category_seq: 21,
    product_category_code: "P021",
    product_category_name_ko: "에너지, 환경 관리 및 지속 가능한 솔루션",
    product_category_name_en:
      "Energy, Environmental Management & Sustainable Solutions",
  },
  {
    cp_product_category_seq: 22,
    product_category_code: "P022",
    product_category_name_ko: "의료기기, 헬스케어 및 생명 과학",
    product_category_name_en: "Medical Devices, Healthcare & Life Sciences",
  },
  {
    cp_product_category_seq: 23,
    product_category_code: "P023",
    product_category_name_ko: "산업용 기계, 장비 및 공구",
    product_category_name_en: "Industrial Machinery, Equipment & Tools",
  },
  {
    cp_product_category_seq: 24,
    product_category_code: "P024",
    product_category_name_ko: "전문 서비스 (법률, 회계, 컨설팅)",
    product_category_name_en:
      "Professional Services (Legal, Accounting, Consulting)",
  },
  {
    cp_product_category_seq: 25,
    product_category_code: "P025",
    product_category_name_ko: "예술, 공예, 디자인 및 이벤트 서비스",
    product_category_name_en: "Art, Crafts, Design & Event Services",
  },
];
export const getCategoryCodeByLabel = (label) => {
  const match = categoryConfig.find(
    (item) => item.product_category_name_ko === label
  );
  return match ? match.product_category_code : "";
};
export const targetInterestCode = [
  {
    cp_target_interests_seq: 1,
    target_interests_code: "I01",
    target_interests_name_eng: "Health & Wellness",
    target_interests_name_kor: "건강 및 웰빙",
  },
  {
    cp_target_interests_seq: 2,
    target_interests_code: "I02",
    target_interests_name_eng: "Online Education",
    target_interests_name_kor: "온라인 교육",
  },
  {
    cp_target_interests_seq: 3,
    target_interests_code: "I03",
    target_interests_name_eng: "Environment & Sustainability",
    target_interests_name_kor: "환경 및 지속 가능성",
  },
  {
    cp_target_interests_seq: 4,
    target_interests_code: "I04",
    target_interests_name_eng: "Technology & Gadgets",
    target_interests_name_kor: "기술 및 가젯",
  },
  {
    cp_target_interests_seq: 5,
    target_interests_code: "I05",
    target_interests_name_eng: "Travel & Exploration",
    target_interests_name_kor: "여행 및 탐험",
  },
  {
    cp_target_interests_seq: 6,
    target_interests_code: "I06",
    target_interests_name_eng: "Financial Management & Investment",
    target_interests_name_kor: "금융 관리 및 투자",
  },
  {
    cp_target_interests_seq: 7,
    target_interests_code: "I07",
    target_interests_name_eng: "Music & Entertainment",
    target_interests_name_kor: "음악 및 엔터테인먼트",
  },
  {
    cp_target_interests_seq: 8,
    target_interests_code: "I08",
    target_interests_name_eng: "Fashion & Beauty",
    target_interests_name_kor: "패션 및 뷰티",
  },
  {
    cp_target_interests_seq: 9,
    target_interests_code: "I09",
    target_interests_name_eng: "Cooking & Food and Beverage",
    target_interests_name_kor: "요리 및 식음료",
  },
  {
    cp_target_interests_seq: 10,
    target_interests_code: "I10",
    target_interests_name_eng: "Sports & Outdoor Activities",
    target_interests_name_kor: "스포츠 및 야외활동",
  },
];
export const getInterestCodeByLabel = (label) => {
  const match = targetInterestCode.find(
    (item) => item.target_interests_name_kor === label
  );
  return match ? match.target_interests_code : "";
};
