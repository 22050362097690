const CustomButton = ({ content, customStyle, func, disabled }) => {
  return (
    <button
      onClick={!disabled ? func : undefined} // Disable click when `disabled` is true
      className={customStyle}
      disabled={disabled} // Pass disabled prop to HTML button
    >
      {content}
    </button>
  );
};

export default CustomButton;
